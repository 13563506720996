<!--打印虾皮宅配模板-->
<template>
    <div
        class="print-box"
        v-if="ctrls && ctrls.length>0"
        :style="blockStyle"
    >   
        <div class="print-wrap" v-for="(data, index) in datas" :key="index">
            <!--1-打印货单-->
            <template>
                <!--打印面单-->
                <div v-for="(da,index1) in data" :key="index1"  class="vw400 exp-page">
                    <span v-if="da.pageDto">
                        <img v-if="(index1+1)%3==0" :style="getStyle(da.pageDto)" style="margin-top:15px;" :src="da.pageDto.pageAsBase64"/>
                        <img v-else :style="getStyle(da.pageDto)" :src="da.pageDto.pageAsBase64"/>
                    </span>
                </div>
                <!-- <div v-if="data.pageDto && data.pageDto.pageAsBase64" class="vw400 exp-page">
                    <span v-if="data.pageDto">
                        <img :style="getStyle(data.pageDto)" :src="data.pageDto.pageAsBase64"/>
                    </span>
                </div>
                <div v-if="data.pageDto && data.pageDto.pageAsBase64" class="vw400 exp-page">
                    <span v-if="data.pageDto">
                        <img :style="getStyle(data.pageDto)" :src="data.pageDto.pageAsBase64"/>
                    </span>
                </div> -->

                <div class="next-page a5" v-if="datas.length>1 && index < datas.length-1">
                    <span>{{index+1}}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

// import JsBarcode from 'jsbarcode';
export default {
    name: "Ticket",
    props: {
        ctrls: Array, // 0-打印面单，1-打印货单，2-打印入仓标签, 3-打印找货图, 5-打印上架单，6-打印报关单
        datas: Array,
        ticketSetting: Object,
    },
    components:{
    },
    computed: {
        blockStyle: { 
            get() {
                var inline = false;
                if(this.datas.length == 1){
                    var data = this.datas[0];
                    var total = 0;
                    total += data.ticketDto? 1 : 0;
                    total += data.tagDto? 1 : 0;
                    // total += (data.pageDto || this.ctrls.indexOf(0)>-1)? 1 : 0;
                    total += data.pageDto? 1 : 0;
                    total += (data.shelfCodeDto && this.ctrls.indexOf(5)>-1)? 1 : 0;
                    total += (data.declareDto && this.ctrls.indexOf(6)>-1)? 1 : 0;

                    inline = total == 1
                }else{
                    let total =0;
                    this.datas.forEach(data =>{
                        if(data.pageDto || data.tagDto || data.thumbs || data.ticketDto 
                            || (data.shelfCodeDto && this.ctrls.indexOf(5)>-1)
                            || (data.declareDto && this.ctrls.indexOf(6)>-1)
                        ){
                            total +=1;
                        }
                    });

                    inline = total<=1;

                }
                return {
                    display: inline?"inline-block":"block",
                }
            }
        },
    },
    methods:{
        getStyle(dto){
            let style = {width: "100%"}
            return style;
        },
    }
};
</script>

<style lang="scss" scoped>



.print-box {
    width: 100%;
    margin-top: 10px;
}

.print-wrap {
    margin: 0px auto;
    // height:1000px;overflow-y:auto;
}

.vw400{width:400px; margin:0px auto;}
.vw320{width:320px; margin:0px auto;}
.vw200{width:200px; margin:0px auto;}

.print-wrap h3 {
    margin: 6px 0px;
    text-align: center;
    font-size: 16px;
}

.next-page {
  page-break-before: always;
}
.exp-page {
  text-align: left;
  max-height:612px; 
  overflow: hidden;
  margin-left: -10px;
  width: 100%;
}
</style>
