<!-- 待贴单转配送模板-->
<template>
<div class="content-panel" v-if="datas && datas.length>0"
    style="display:inline-block"
    >
  <div class="print-body" style="margin:15px 0px;">
    <p style="font-size:16px; font-weight: bold; margin-bottom:20px;">待贴单转配送包裹清单</p>
    <div class="tb2-wrap">
      <table class="tb2" >
        <thead>
            <tr class="th2">
                <th class="th21">订单编号</th>
                <th class="th22">入仓码</th>
                <th class="th23">架位号</th>
                <th class="th24">袋号</th>
                <th class="th25">收件人</th>
                <th class="th26">配送方式</th>
                <th class="th27">货物状态</th>
                <th class="th28">客户名</th>
            </tr>
        </thead>
        <tbody>
            <tr class="td2" v-for="(data, idx) in datas" :key="idx">
                <td class="th21">{{data.code}}</td>
                <td class="th22">{{data.inStoreCode}}</td>
                <td class="th23">{{data.shelfCode}}</td>
                <td class="th24">{{data.expPkgCode}}</td>
                <td class="th25">{{data.receiver}}</td>
                <td class="th26">{{data.expCompany}}</td>
                <td class="th27">{{data.statusName}}</td>
                <td class="th28">{{data.fullname}}</td>
            </tr>
        </tbody>
      </table>
    </div>
  </div><!--end print-->
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'ExpPrintFindTicketTpl',
  components: {

  },
  mounted:function(){
  },
  data(){
    return{
      code: null,
    } 
  }, 
  props:{
      datas:Array,
  },
  methods: {
    
  }
}
</script>

<style scoped>
.content-panel{
  width:100%;
}
.print-body{text-align:center;}
.exp-type{font-size: 18px; font-weight:bold;}
.area1 img{width:200px;}
.area1 div{font-size:16px; margin-top:5px; margin-bottom: 15px;}
.area1 {margin-bottom:15px; border-bottom:2px dashed #000;}
.print-body table{width:100%;}
.print-body table th{font-size:12px; text-align:center;   
}
.print-body table thead th{border-right: 1px solid #8a8a8a; text-align:center; font-size:16px; font-weight:bold;}
.print-body table{border-top: 1px solid #8a8a8a; border-left: 1px solid #8a8a8a; 
  border-collapse:collapse; border-spacing: 0px; 
} 
.print-body table tbody td{
  border-top: 1px solid #8a8a8a; 
  border-bottom: 1px solid #8a8a8a;
  border-right: 1px solid #8a8a8a;
  font-size:14px;
}

.tb1-wrap, .tb2-wrap{margin:0px auto;  }
</style>
