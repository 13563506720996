<!-- 批量上传面单 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('导入PDF资料')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="12">
        <el-upload class="upload-demo" :with-credentials="true" multiple
            :file-list="fileList"
            :before-upload="handleBeforeUpload"
            :data="pdfdata"
            :limit="30"
            :on-exceed="handleOnExceed"
            :action="pageImport" :on-success="handleSuccess">
              <el-button size="small" icon="el-icon-upload2" type="primary">{{msg('导入PDF资料')}}</el-button>
        </el-upload>
    </el-col>
  </el-row>
 
  <div class="table-wrap">
    <el-table :data="results" style="width: 100%" height="400px" size="mini" ref="dataTable" stripe>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="filename" :label="msg('文件名')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="ordercode" :label="msg('订单编号')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="msg" :label="msg('上传结果')" min-width="200"></el-table-column>
    </el-table>
  </div>
  <el-row :gutter="10" type="flex" justify="start" align="center" class="ctrl-wrap">
    <el-col :span="24">
        <el-alert :title="msg('stock-only-page-warning1')"  type="warning"></el-alert>
        <el-alert :title="msg('stock-only-page-warning2')"  type="warning"></el-alert>
        <el-alert :title="msg('stock-only-page-warning3')"  type="warning"></el-alert>
    </el-col>
  </el-row>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'StockOnlyPageImport',
  components: {},
  mounted:function(){
    var _this = this;
  },
  data(){
    return{
      pageImport: this.$kit.api.stock.onlyPageImport,
      results: [],   //表格数据列表
      fileList: [],
      pdfdata:{
        code:null
      },
      pdfdata1:{
        code:null
      },
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    },
  },
  methods: {
    initForm(){
        this.results.splice(0);
        this.fileList.splice(0);
    },
    handleSuccess(res, file, fileList) {
        if(res.status){
            var data = res.data;
            this.results.push(data);
        }else{
          this.$message(res.msg || this.msg("上传失败"));
        }
    },
    // handleBeforeUpload(file){
    //   var filename = file.name;
    //   var name = filename.substring(0, filename.lastIndexOf("."));
    //   name = name.replace(/\s+/g, "");

    //   if(/^\w+$/.test(name)){
    //     this.pdfdata.code= name;
    //     return true;
    //   }else{
    //     this.$message(this.msg("文件名的单号不合法"));
    //     return false;
    //   }

    // },
    handleOnExceed(files, fileList){
        this.$message(this.msg("单次上传数量不能大于30个文件"));
        return false;
    },
    handleBeforeUpload(file){
      var filename = file.name;
      var name = filename.substring(0, filename.lastIndexOf("."));
      
      var temps = name.split("+");

      name = temps[0].replace(/\s+/g, "");

      if(/^\w+$/.test(name)){
        this.pdfdata.code= name;
        if(temps.length>1){
          this.pdfdata.goodName = temps[1];
        }
        return true;
      }else{
        this.$message(this.msg("文件名的单号不合法"));
        return false;
      }
      // this.pdfdata.code= name;
      //   if(temps.length>1){
      //     this.pdfdata.goodName = temps[1];
      //   }
      // return true;
 
    },
  }
}
</script>

<style scoped lang="scss">


</style>
