import { render, staticRenderFns } from "./StockPageView.vue?vue&type=template&id=5a8454e1&scoped=true"
import script from "./StockPageView.vue?vue&type=script&lang=js"
export * from "./StockPageView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a8454e1",
  null
  
)

export default component.exports