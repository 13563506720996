<!-- 包号录入 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('已选择')">
                <el-tag effect="dark">{{total}}</el-tag>{{msg('单货物')}}
            </el-form-item>
            <el-form-item :label="msg('包号')">
                <el-input v-model="pkgCode" placeholder="请输入包号" size="small">
                    <el-button slot="append" icon="el-icon-printer" @click="onSubmit(1)">{{msg('打印包号单')}}</el-button>
                </el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-table :data="stockDetails" style="width: 100%;padding: 0 0px;" size="mini" ref="dataTable" stripe>
            <el-table-column :label="msg('已录入的可供选择包号')">
                <el-table-column prop="index" type="index" :label="msg('序号')" width="80"></el-table-column>
                <el-table-column prop="orderBagCode" :label="msg('包号')" min-width="100"></el-table-column>
                <el-table-column :label="msg('操作')" min-width="80">
                    <template slot-scope="scope">
                        <el-button type="primary" class="nopad" size="mini" plain
                            @click="useBagCode(scope.row.orderBagCode)">
                            {{msg('使用本包号')}}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </el-row>

    <span v-if="printDto && printDto.barcodeAsBase64">
        <vue-easy-print tableShow ref="easyPrint">
            <template>
                <print-tpl :data="printDto"></print-tpl>
            </template>
        </vue-easy-print>
    </span>

    <div style="text-align:center;margin-top:10px;">
        <el-button type="primary" size="mini"  @click="onSubmit(0)"><i class="el-icon-check"></i><span>{{msg('确定提交')}}</span></el-button>
        <el-button type="danger"  size="mini" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>
</el-form> 
</template>

<script>
import vueEasyPrint from "vue-easy-print";
import PrintTpl from "./InsertPkgCodeTpl";
export default {
  name: 'ExpPkgCode',
  components:{
        PrintTpl : PrintTpl,
        vueEasyPrint: vueEasyPrint,
    },
  data(){
    return{
        pkgCode:null,
        stockDetails:[],
        total:0,
        ids:null,
        selectStocks:[],
        printDto:{
            pkgCode:'',
            barcodeAsBase64:'',
            total:0,
            name:'',
            created:'',
        },
    }
    
  }, 
  props: {
    },
  computed:{
  },
  mounted(){
  },
  methods:{
      init(ids,total){
        //系统生成包号
        var _this = this;
        this.pkgCode = null;
        this.total = total;
        this.ids = ids;
        this.$axios.post(this.$kit.api.stock.getPkgCodes+'?ids='+ids)//返回列表数据
            .then((result) =>{
                _this.pkgCode = result.data['code'];
                _this.stockDetails = result.data['stocks'];
                _this.selectStocks = result.data['allstocks'];
            })
      },
      useBagCode(bagCode){
          this.pkgCode = bagCode;
      },
      onSubmit(type){
          if(!this.pkgCode){
              this.$message(this.msg('请输入包号！'));
               return;
          }
           var reg = /^[a-zA-Z0-9]{0,20}$/;
           let str = reg.test(this.pkgCode);
           if(!str){
               this.$message(this.msg('袋号只能是字母和数字，或者字母加数字组合，不能有空格'));
               return;
           }
           //执行提交
            this.updatePkgCode(type);

        //    //判断是不是所有的订单状态都是待打包，如果不是需要提醒用户货物未到货，是否要继续打包出货
        //    let err = [];
        //    this.selectStocks.forEach(e => {
        //        if(e.status != 20){
        //            err.push(e.code);
        //        }
        //    });
        //    if(err && err.length > 0){
        //        let msg = err.map(e => e).join(',');
        //        this.$confirm(this.msg(msg+'货物未到货，是否要继续打包出货?'), this.msg('提示'), {
        //           confirmButtonText: this.msg('继续'),
        //           cancelButtonText: this.msg('取消'),
        //           type: 'warning'
        //         }).then(() => {
        //             //执行提交
        //             this.updatePkgCode(type);
        //         }).catch(() => {
        //           this.$message({
        //             type: 'info',
        //             message: '取消操作'
        //           }); 
        //         });
        //    }else{
        //        this.updatePkgCode();
        //    }
      },
      updatePkgCode(type){
          //参数：pkgCode-包号，ids-选中的库存id
          var _this = this;
          let type1 = 0;
          if(type){
              type1 = type;
          }
          this.$axios.post(this.$kit.api.stock.updatePkgCode+'?ids='+this.ids+'&pkgCode='+this.pkgCode+'&type='+type1)//返回列表数据
            .then((result) =>{
                if(result.status){
                    _this.$message(_this.msg(result.msg));
                    _this.printDto = result.data;
                    if(type == 1){
                        _this.printPkg();
                    }
                    // _this.$emit("close-dg",true);
                }else{
                    _this.$message(_this.msg(result.msg));
                }
            })
      },
      printPkg(){//执行打印
            let _this = this;
            _this.$nextTick(()=>{
                _this.$refs.easyPrint.$nextTick(() => {
                    setTimeout(()=>{
                        _this.$refs.easyPrint.print();
                    }, 1000);
                    
                });
            });
            // setTimeout(()=>{
            //     this.$refs.easyPrint.print();
            // }, 500);
      },
  }
}
</script>

<style scoped lang="scss">
</style>
