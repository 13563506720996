<template>
    <transition name="xmdfade" > 
        <div class="xm-drawer" v-if="visible" :style="{'width': width, height: '100%'}">
            <div class='xmd-top'>
                <div class="xmd-title">{{title}}</div>
                <div @click="closeXmd" class="el-icon-close xmd-close" :underline="false"></div>
            </div>
            <el-scrollbar class="hidden-x" :style="{height: docHeight+'px'}">
                <div class="xmd-wrap" :style="{width: contentWidth}">
                    <slot ></slot> 
                </div>
            </el-scrollbar>
        </div>
    </transition>
</template>

<script>
import 'animate.css';
export default {
    name: 'XmDrawer',
    props:{
        visible: {
            type: Boolean, 
            default: false
        },
        title: String, 
        width: {
            type: String, 
            default: '500px'
        },
        contentWidth: {
            type: String,
            default: '500px'
        },
    },
    computed: {
        style: {
            get(){
                return {
                    width: this.width,
                    right: this.width,
                }
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 50;
            }
        }
    },
    methods:{
        closeXmd(){
            this.$emit('update:visible', false);
        }
    }
}
</script>

<style lang="scss" scoped>
.xm-drawer{
    box-shadow: -10px 0 10px rgba(110,110,110,0.2);
    position: fixed;
    box-sizing: border-box;
    top: 0px;
    right:0px;
    z-index:1000;
    background:white;
}
.xmd-top{
    padding:10px 10px 10px 15px;
    position:relative;
    box-shadow: 0px 5px 5px rgba(110,110,110,0.2);
}
.xmd-wrap{padding:10px; box-sizing: border-box;}
.xmd-close{
    cursor: pointer;
    position:absolute;
    top:18px; 
    right:15px;
}
.xmdfade-enter-active{
    animation: xmdLeftIn .3s
}
.xmdfade-leave-active{
    animation: xmdLeftIn .3s reverse
}

@keyframes xmdLeftIn{
    0%{
        transform:translateX(100%)
    }
    25%{
        transform:translateX(75%)
    }
    50%{
        transform:translateX(50%)
    }
    75%{
        transform:translateX(25%)
    }
    100%{
        transform:translateX(0%)
    }
}

</style>