import { render, staticRenderFns } from "./StockReceive.vue?vue&type=template&id=6b5a08a9&scoped=true"
import script from "./StockReceive.vue?vue&type=script&lang=js"
export * from "./StockReceive.vue?vue&type=script&lang=js"
import style0 from "./StockReceive.vue?vue&type=style&index=0&id=6b5a08a9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5a08a9",
  null
  
)

export default component.exports