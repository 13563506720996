<!-- 快递分拣 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('快递单号')">
                <el-input v-model="code" ref="codeRef" placeholder="请输入快递单号" size="small" @keyup.native="autoSelectCode">
                </el-input>
            </el-form-item>
            <input style="display:none"/>
        </el-col>
        <el-col :xs="24" :sm="20">
            <el-form-item>
                <el-checkbox v-model="checkboxExp">{{msg('快递签收')}}</el-checkbox>
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center;margin-top:10px;">
        <el-button  size="mini" type="danger" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>

    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" @ended="endPlay">
        </audio>
    </div>

</el-form> 
</template>

<script>
import daiDabao from "../../assets/media/daidabao.mp3";//待打包
import shangjia from "../../assets/media/shangjia.mp3";//上架
import noCode from "../../assets/media/noInCode.mp3";//单号不存在
import Cancel from "../../assets/media/Cancel.mp3";//已取消
import pinbao from "../../assets/media/pinbao.mp3";//件拼包
import PlayWeight from "../../components/PlayWeight";
export default {
  name: 'ExpressSorting',
  data(){
    return{
        code:null,
        checkboxExp:true,
        playWeight: null,
        playList: [],  //播放列表
        playStatus: false,
        playPosition: null,//当前播放位置
    }
    
  }, 
  props: {
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
            this.code = null;
            this.checkboxExp = true;
            // 初始化语音播报功能
            this.playWeight = new PlayWeight();
            this.playList = [];
            this.playStatus = false;
            this.playPosition = null;
      },
      autoSelectCode(e){

        if(e.keyCode == 13){
            this.expSort();
        }
    },
    expSort(){
        if(!this.code){
            this.$message(this.msg('请录入国内快递单号'));
            return;
        }
        var _this = this;
        this.playList = [];
        this.$axios.get(this.$kit.api.stock.expressSorting+"?code="+this.code+"&b="+this.checkboxExp,)
            .then((result) =>{
                if(result.status){
                    let data = result.data;
                    let status = data.status;
                    let num = data.num;
                    if(status == 1){
                        this.playList.push(daiDabao)
                    }else if(status == 2){
                        this.playList.push(shangjia)
                    }else if(status == 4){
                        this.playList.push(Cancel)
                    }else if(status == 5){
                        let twavs = this.playWeight.getWavMedia(num+"");
                        twavs.forEach(w => this.playList.push(w))
                        this.playList.push(pinbao)
                    }else if(status == 6){
                        let twavs = this.playWeight.getWavMedia(num+"");
                        twavs.forEach(w => this.playList.push(w))
                        this.playList.push(shangjia)
                    }else{
                         //保存当前单号到t_exp_code_hist表
                        this.playList.push(noCode)
                        _this.$axios.get(_this.$kit.api.stock.sortExpOrderAdd+"?code="+_this.code)
                            .then((result) =>{
                                _this.$message(result.msg);
                            });
                    }
                    this.playAudio();
                    this.code = null;
                    this.$refs['codeRef'].focus();
                }else{
                    this.$message(result.msg);
                }
               
            });
    },
    endPlay(){
        if(this.playPosition >= this.playList.length){
            this.playPosition = 0;
            this.playList = [];
            this.playStatus = false;
        }else{
            this.playAudio();
        }
    },
    playAudio(){
        this.playStatus = true;
        let audio = this.$refs.audioRef;
        let media = this.playList[this.playPosition++];
        if(media){
            audio.src = media;
            audio.play();
        }else{
            this.endPlay();
        }
    },
  }
}
</script>

<style scoped lang="scss">


</style>
