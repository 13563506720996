<template>
    <div class="row-wrap">
        
        <div style="text-align:center; margin-top:10px; margin-bottom:10px;" v-if="printDtos">
            <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                @click="doPrint()" 
            >{{msg('打印')}}</el-button>
        </div>
        
        <template v-if="printDtos && printDtos.length>0" >
            <vue-easy-print tableShow ref="easyPrint">
                <template>
                    <print-tpl :datas="printDtos" ></print-tpl>
                </template>
            </vue-easy-print>
        </template>
       
        <div style="text-align:center; margin-top:10px;" >
            <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                @click="doPrint()" 
            >{{msg('打印')}}</el-button>
        </div>
    </div>
</template>

<script>
import vueEasyPrint from "vue-easy-print";
import PrintTpl from "./PrintTpl";
export default {
    
    name: "DeclarePrint",
    components:{
        PrintTpl : PrintTpl,
        vueEasyPrint: vueEasyPrint,
    },
    data(){
        return{
            printDtos:[],
        }
    },
    mounted(){
        this.getPrintData();
    },
    props:{
        ids:String,
    },
    methods:{
        getPrintData(){
            var _this = this;
            this.printDtos = [];
            this.$axios.get(this.$kit.api.declare.print+"?&ids="+this.ids)
                .then((result) => {
                    if(result.status){
                        this.printDtos = result.data;
                        this.doPrint();
                    }else{
                        this.$message(result.msg || this.msg('get-err'));
                        this.$emit("close-dg");
                    }
                });
        },
        doPrint(){
            let _this = this;
            let len = this.printDtos.length || 0;

            _this.$nextTick(()=>{
                _this.$refs.easyPrint.$nextTick(() => {
                    setTimeout(()=>{
                        _this.$refs.easyPrint.print();
                    }, (2+(len>100? (len - 100)/100 : 0))*1000);
                });
            });
           
        }
    }
}
</script>