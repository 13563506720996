<!-- 找货单模板-->
<template>
<div class="content-panel" v-if="datas && datas.length>0"
    style="display:inline-block"
    >
  <div class="print-body" style="margin:15px 0px;">
    <p style="font-size:16px; font-weight: bold; margin-bottom:20px;">{{headName}}</p>
    <div class="tb2-wrap">
      <table class="tb2" >
        <tbody>
          <tr class="firstHead">
            <th colspan="5">待找货物信息</th>
            <th colspan="8">对应新订单信息</th>
          </tr>
            <tr class="th2">
                <th class="th21"><span>架位号</span></th>
                <th class="th22"><span>入仓码</span></th>
                <th class="th23"><span>余数</span></th>
                <th class="th24"><span>收件人</span></th>
                <th class="th25"><span>配送方式</span></th>
                <th class="th26"><span>订单编号</span></th>
                <th class="th27"><span>品名</span></th>
                <th class="th28"><span>规格</span></th>
                <th class="th29"><span>件数</span></th>
                <th class="th30"><span>收件人</span></th>
                <th class="th31"><span>客户名</span></th>
                <th class="th32"><span>打包类型</span></th>
                <th class="th33"><span>验货图</span></th>
            </tr>
            <tr class="td2" v-for="(data, idx) in datas" :key="idx">
                <td class="th21">{{data.shelfCode}}</td>
                <td class="th22">{{data.inStoreCode}}</td>
                <td class="th23">{{data.productQty}}</td>
                <td class="th24">{{data.receiver}}</td>
                <td class="th25">{{data.expCompany}}</td>
                <td class="th26">{{data.code}}</td>
                <td class="th27">{{data.productName}}</td>
                <td class="th28">{{data.productSpec}}</td>
                <td class="th29">{{data.newProductQty}}</td>
                <td class="th30">{{data.newReceiver}}</td>
                <td class="th31">{{data.fullname}}</td>
                <td class="th32">{{data.expPkgType}}</td>
                <td class="th33">
                  <el-image  style="width: 80px; height: 88px" 
                    :src=imgUrl+data.productThumb
                  ></el-image>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div><!--end print-->
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'ExpPrintFindTicketTpl',
  components: {

  },
  mounted:function(){
  },
  data(){
    return{
      code: null,
      imgUrl:this.$kit.api.imgView
    } 
  }, 
  props:{
      datas:Array,
      headName:String
  },
  methods: {
    
  }
}
</script>

<style scoped>
.print-body{text-align:center;}
.exp-type{font-size: 18px; font-weight:bold;}
.area1 img{width:200px;}
.area1 div{font-size:16px; margin-top:5px; margin-bottom: 15px;}
.area1 {margin-bottom:15px; border-bottom:2px dashed #000;}
.firstHead{
  border-bottom: 1px solid #8a8a8a;
  font-size:14px;
}
.print-body table{width:100%;}
.print-body table th{font-size:12px; text-align:center;   
}
.print-body table th span{
  display: inline-block;
  white-space: nowrap;
}
.print-body table th{ text-align:center; font-size:16px; font-weight:bold;}
.print-body table{
  border-collapse:collapse; border-spacing: 0px; 
} 
.print-body table td{
  font-size:13px;
}

.tb1-wrap, .tb2-wrap{margin:0px auto;  }
.th22{
  max-width:80px;
}
.th26{
  max-width:60px;
}

table{
  border-bottom: 1px solid #8a8a8a;
  border-right: 1px solid #8a8a8a;
}

table td, table th{
  border-left: 1px solid #8a8a8a;
  border-top: 1px solid #8a8a8a;
}

@media print{
  table{
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  table td, table th{
    border-left: 1px solid #000;
    border-top: 1px solid #000;
  }
}
</style>
