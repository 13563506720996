<!-- 转运收费 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('报单备注')">
                <el-input v-model="remark1" ref="codeRef" type="textarea" :rows="2" show-word-limit :maxlength="50">
                </el-input>
            </el-form-item>
                <el-input type="hidden">
                </el-input>
        </el-col>
    </el-row>
  
    <div style="text-align:center;margin-top:10px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

</el-form> 
</template>

<script>
export default {
  name: 'ExpCodeInput',
  data(){
    return{
        remark1:null,
    }
    
  }, 
  props: {
      id:Number
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
          this.remark1 = null;
          this.$refs['codeRef'].focus();
      },
        onSubmit(){
            if(!this.remark1){
                this.$message(this.msg('请输入报单备注'));
                return;
            }
            let stock = {
                id: this.id,
                remark1: this.remark1,
            }
            this.$axios.post(this.$kit.api.stock.zhuanyunCharge,stock)
                .then((result) =>{
                    if(result.status){
                        this.$message(result.msg || this.msg('更新成功'));
                        this.$emit("close-dg",true);
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                
                });
        },
  }
}
</script>

<style class="scss" scoped>


</style>
