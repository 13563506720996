<template>
   <div>
       <div style="margin-bottom: 15px; ">
           <template>
                <el-checkbox-group v-model="statusList">
                    <el-checkbox label="10">已入仓</el-checkbox>
                    <el-checkbox label="70">已提货</el-checkbox>
                </el-checkbox-group>
            </template>
        </div>
        <div style="margin-bottom: 15px; ">
           <el-radio-group v-model="days" size="medium" @change=change()>
                <el-radio-button :label="3" >{{msg('近3天')}}</el-radio-button>
                <el-radio-button :label="7" >{{msg('近7天')}}</el-radio-button>
                <el-radio-button :label="14" >{{msg('近14天')}}</el-radio-button>
            </el-radio-group>
        </div>
        <div style="margin-bottom: 15px; ">
           <el-date-picker size="medium"
                v-model="startDate"
                type="date"
                placeholder="选择开始日期">
            </el-date-picker>
        </div>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="enter"><i class="el-icon-check"></i><span>{{msg('打印')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
        <div class="row-wrap">
            <span v-if="printDtos && printDtos.length>0">
                <vue-easy-print tableShow ref="easyPrint">
                    <template>
                        <print-tpl :datas="printDtos" ></print-tpl>
                    </template>
                </vue-easy-print>
            </span>
        </div>
    </div>
</template>
<script>
import vueEasyPrint from "vue-easy-print";
import PrintTpl from "./StockPrintFindPagTpl";
export default {
    name: "StockSyncOrder",
    components:{
        PrintTpl : PrintTpl,
        vueEasyPrint: vueEasyPrint,
    },
    data(){
        return{
            days: 0,
            startDate: null,
            statusList:['10','70'],
            printDtos:[],
        }
    },
    methods: {
        init(){
            this.days = 0
            this.startDate = null
            this.printDtos = []
        },
        change(){
            let dayL = this.days * 24 * 3600 * 1000;
            dayL = new Date().getTime() - dayL;
            this.startDate = new Date(dayL);
        },
        enter(){
            var _this = this;
            this.printDtos.splice(0,this.printDtos.length)
            this.$axios.post(this.$kit.api.stock.findPkgPage+"?st="+this.startDate.getTime()+"&status="+this.statusList)
                .then((result) => {
                    if(result && result.data.length > 0){
                        _this.printDtos = result.data;
                        _this.doPrint();
                    }else{
                        _this.$message(_this.msg('没有数据'));
                    }
                });
        },
        doPrint(){
            // setTimeout(()=>{
            //     this.$refs.easyPrint.print();
            // }, 500);

            let _this = this;
            _this.$nextTick(()=>{
            _this.$refs.easyPrint.$nextTick(() => {
                    setTimeout(()=>{
                        _this.$refs.easyPrint.print();
                    }, 1500);
                });
            });
        }
    }
}
</script>
<style lang="scss" scoped>

</style>