<!-- 库存管理-->
<template>
    <div class="page-box" ref="pageBox">
        <!-- style="position:fixed; top:0px;" -->
        <ctrl-box class="ctrl-box" :data="{}" v-reheight="ctrlBoxHeightChanged">
            <template>
                <div class="xgroup" >
                    <el-link :underline="false" v-if="viewSetting"  v-popover:popover @click="viewsVisiable = !viewsVisiable"
                        style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);border-radius: 4px;background-color: rgb(43 146 246); padding: 7px 15px;"
                    >
                        <div class="wrap" v-show="true">
                            <span style="color:white">
                                <span>{{msg("当前视图")+'：'}}</span>
                                <span style="font-size:15px;font-weight:600;">{{viewSetting.name}}</span>
                                <i class="el-icon-caret-bottom" ></i>
                            </span>
                        </div>
                    </el-link>
                </div>
                
                <div class="xgroup" v-if="viewSetting && viewSetting.btns && viewSetting.btns.length>0">
                   <template v-for="(item, idx) in dicts.stockBtns">
                       <template v-if="viewSetting.btns.indexOf(item.name)>-1 && hasPermissions(item.permission,'or')">
                            <el-dropdown v-if="item.children && item.children.length>0" :key="idx"
                                @command="(cmd)=> callMethod(item.action, cmd)" 
                            >
                                <el-link :underline="false">
                                    <div class="wrap" >
                                        <!-- <i :class="item.icon"></i> -->
                                        <icon-svg :icon-class="item.icon" font-size="27px;"/>
                                        <span class="txt" :style="item.labelStyle">{{msg(item.name)}}</span>      
                                    </div>
                                </el-link>
                                <el-dropdown-menu slot="dropdown">
                                    <template v-for="(sub, idx1) in item.children" >
                                        <el-dropdown-item :key="idx1" v-if="viewSetting.btns.indexOf(sub.name)>-1 && hasPermissions(sub.permission,'or')" 
                                            :command="sub.command" >{{msg(sub.name)}}</el-dropdown-item>
                                    </template>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-link v-else :key="idx"
                                    :underline="false" @click="callMethod(item.action, item.para)">
                                <div class="wrap" >
                                    <!-- <i :style="item.iconStyle" :class="item.icon" ></i> -->
                                    <icon-svg :icon-class="item.icon" font-size="27px;"/>
                                    <span class="txt"  :style="item.labelStyle">{{msg(item.name)}}</span>      
                                </div>
                            </el-link>
                       </template>
                    </template>
                </div> 
            </template>
        </ctrl-box>
        <div class="empty-fixed-box" v-bind:style="{height:emptyFixedBoxHeight}"></div>
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <template v-for="q in scope.query.queries" >
                        <el-form-item :key="q.id" v-if="q.disabled == 0"
                            :label="q.nlabel? q.nlabel : q.label" >
                                <span v-if="q.type == 0">
                                    <el-input v-if="q.op != 6" v-model="q.dvalue" :placeholder="q.placeholder"  clearable>
                                    </el-input>
                                    <el-input v-else type="textarea" v-model="q.dvalue" :rows="2" 
                                    :placeholder="q.placeholder"  clearable></el-input>
                                </span>
                                <el-input v-else-if="q.type == 1" v-model="q.dvalue" :placeholder="q.placeholder"  clearable></el-input>
                                <span v-else-if="q.type == 2">
                                    <el-select v-if="q.op === 0" v-model="q.dvalue" size="mini" clearable :placeholder="q.placeholder">
                                        <span v-if="q.filterOptionKey=='expCompany'">
                                            <el-option v-for="d in expCompanys" 
                                                :key="d.value" :label="d.label" :value="d.value">
                                            </el-option>
                                        </span>
                                        <span v-else>
                                            <el-option v-for="d in getDicts(q.filterOptionKey)" 
                                                :key="d.value" :label="d.label" :value="d.value">
                                            </el-option>
                                        </span>
                                    </el-select>
                                    <el-select v-else v-model="q.dvalues" multiple size="mini" clearable :placeholder="q.placeholder">
                                        <span v-if="q.filterOptionKey=='expCompany'">
                                                <el-option v-for="d in expCompanys" 
                                                :key="d.value" :label="d.label" :value="d.value">
                                            </el-option>
                                        </span>
                                        <span v-else>
                                            <el-option v-for="d in getDicts(q.filterOptionKey)" 
                                                :key="d.value" :label="d.label" :value="d.value">
                                            </el-option>
                                        </span>
                                    </el-select> 

                                </span>
                                <el-date-picker v-if="q.type == 3" clearable
                                    v-model="q.dvalues" value-format="yyyy-MM-dd"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                                <el-date-picker v-if="q.type == 4" clearable
                                    v-model="q.dvalues" value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetimerange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                        </el-form-item>
                    </template>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>
        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight"  
                :export-config="exportConfig" stripe
                resizable border="full"
                highlight-current-row highlight-hover-column highlight-current-column
                show-overflow show-header-overflow 
                :data="datas"
                size="mini"
                :columns="columns"
                @cell-click="clickRow"
                :scroll-x="{gt: 20}"
                :scroll-y="{gt: 20}"
                :checkbox-config="{highlight:true, trigger: 'cell'}"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

        <drawer :title="dgTitle" :width="isMinScreen?'100%':'30%'"
            :content-width="'100%'"
            :visible.sync="dgVisible">
                <edit :stock="curr" :view-setting="viewSetting"  :detail-editable="detailEditable" :exp-code-edit="expCodeEdit" :ant-order-edit="antOrderEdit"
                    @close-dg="closeEditDialog" ref="editDg"/>
        </drawer>

        <drawer :title="msg('批量更新指定内容')" :width="isMinScreen?'100%':'50%'"
            :content-width="'100%'"
            :visible.sync="batchUpdateVisible">
                <batch-update :datas="batchDatas" @close-dg="closeBatchUpdateDialog" ref="batchUpdateRef"/>
        </drawer>

        <el-dialog :title="msg('查看面单')" :close-on-click-modal="false" 
            :visible.sync="imgVisiable" width="800px"
            :fullscreen="isMinScreen">
                <page-view :imgId="pageId" @close-dg="imgVisiable = false" />
        </el-dialog>

        <el-dialog :title="msg('物流查询')" :close-on-click-modal="false" 
            :visible.sync="traceVisible" width="45%"
            @opened="openTrace"
            :fullscreen="isMinScreen">
                <trace ref="traceRef"></trace>
        </el-dialog>

        <el-popover
            ref="popover"
            width="300"
            placement="bottom"
            v-model="viewsVisiable"
            trigger="hover">
            <div class="views">
                <el-input :placeholder="msg('搜索视图')" v-model="viewFilterText" size="mini" clearable>    
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <el-scrollbar style="height:300px;">
                    <div class="view-group" v-for="(value, key) in views" :key="key">
                        <h5>{{key}}</h5>
                        <span v-for="view in value" :key="view.id" >
                            <el-link v-show="view.show"
                                style="display:block; padding: 5px 15px;"
                                :underline="false" 
                                :type="viewSetting.id === view.id?'primary':'default'"
                                @click="changeViews(view)">{{view.name}}
                            </el-link>
                        </span>
                    </div>
                    <el-button style="margin-top:10px; margin-bottom:10px; text-align:center;" 
                        v-show="loadMored" plain size="mini" 
                        @click="getAllViews(1)">{{msg('加载更多')}}</el-button>
                </el-scrollbar>
               
            </div>
        </el-popover>
        <el-popover
                ref="colsPopover"
                width="200"
                placement="bottom"
                v-model="colsPopVisible"
                trigger="click">
            <el-scrollbar style="height:300px;">
                <div v-for="(c, idx) in genColumns" :key="idx">
                    <el-checkbox v-if="idx>1" v-model="c.visible"
                            @change="(val)=>refreshCols(val, c)"
                        >{{c.title}}</el-checkbox>
                </div>
            </el-scrollbar>
        </el-popover>

        <el-dialog :title="msg('批量上传面单')" :visible.sync="dialogImportVisible" :fullscreen="isMinScreen" 
            :close-on-click-modal="false" 
                width="600px" @opened="hendleOpenedImport()" >
            <stock-page-import ref="stockPageImport"/>
        </el-dialog>
        
        <el-dialog :title="msg('导入PDF资料')" :visible.sync="dialogOnlyImportVisible" :fullscreen="isMinScreen"  
            :close-on-click-modal="false" 
                width="600px" @opened="hendleOpenedOnlyImport()" >
            <stock-page-only-import ref="stockPageOnlyImport"/>
        </el-dialog>

        <el-dialog :title="msg('分包加单')" :visible.sync="addPkgVisible" :fullscreen="isMinScreen"  
            :close-on-click-modal="false" 
                width="600px">
            <stock-add-pkg ref="addPkgEdit" v-on:close-dg="closeAddPkgDialog"/>
        </el-dialog>

        <el-dialog :title="msg('重量分摊')" :visible.sync="weightShareVisible" :fullscreen="isMinScreen"  
            :close-on-click-modal="false" 
                width="800px">
            <stock-weight-share :stocks="weightShareStock" ref="weightShare" v-on:close-dg="closeWeightShareDialog"/>
        </el-dialog>

        <el-dialog :title="zhuanyunTitle" :visible.sync="zhuanyunVisible" :fullscreen="isMinScreen"  @opened="openedZhuanyun"
            :close-on-click-modal="false"
                width="900px">
            <zhuan-pkg ref="zhuanyun" v-on:close-dg="closeZhuanyunDialog"/>
        </el-dialog>

        <el-dialog :title="msg('弃件处理')" :visible.sync="qijianVisible" :fullscreen="isMinScreen"  @opened="openedQijian"
            :close-on-click-modal="false"
                width="900px">
            <qijian-handle ref="qijianRef" v-on:close-dg="closeQijianDialog"/>
        </el-dialog>

        <el-dialog :title="msg('打印')" :visible.sync="printDgVisible" :fullscreen="isMinScreen"  @opened="openedPrint"
            :close-on-click-modal="false" 
                width="600px">
            <stock-print :print-ctrls="printCtrls" ref="printDg" v-on:close-dg="closePrintDialog"/>
        </el-dialog>

        <el-dialog :title="msg('打印出货码')" :visible.sync="printDgVisible2" :fullscreen="isMinScreen"  @opened="openedPrint2"
            :close-on-click-modal="false" 
                width="600px">
            <stock-print2 :print-ctrls="printCtrls" ref="printDg2" v-on:close-dg="closePrintDialog2"/>
        </el-dialog>

        <el-dialog :title="msg('打印拣货单&出货码')" :visible.sync="printDgVisible3" :fullscreen="isMinScreen"  @opened="openedPrint3"
            :close-on-click-modal="false" 
                width="600px">
            <stock-print3 :print-ctrls="printCtrls" ref="printDg3" v-on:close-dg="closePrintDialog3"/>
        </el-dialog>

        <el-dialog :title="msg('打印拣货单&自定义快递单')" :visible.sync="printDgVisible4" :fullscreen="isMinScreen"  @opened="openedPrint4"
            :close-on-click-modal="false" 
                width="600px">
            <stock-print4 :print-ctrls="printCtrls" ref="printDg4" v-on:close-dg="closePrintDialog4"/>
        </el-dialog>

        <el-dialog :title="msg('打印自定义快递单')" :visible.sync="printDgVisible6" :fullscreen="isMinScreen"  @opened="openedPrint6"
            :close-on-click-modal="false" 
                width="600px">
            <stock-print6 :print-ctrls="printCtrls" ref="printDg6" v-on:close-dg="closePrintDialog6"/>
        </el-dialog>

        <el-dialog :title="msg('打印中通快递单')" :visible.sync="printDgVisible7" :fullscreen="isMinScreen"  @opened="openedPrint7"
            :close-on-click-modal="false" 
                width="600px">
            <stock-print7 :print-ctrls="printCtrls" ref="printDg7" v-on:close-dg="closePrintDialog7"/>
        </el-dialog>

        <el-dialog :title="msg('打印拣货单&中通快递单')" :visible.sync="printDgVisible8" :fullscreen="isMinScreen"  @opened="openedPrint8"
            :close-on-click-modal="false" 
                width="600px">
            <stock-print8 :print-ctrls="printCtrls" ref="printDg8" v-on:close-dg="closePrintDialog8"/>
        </el-dialog>

        <el-dialog :title="msg('打印拣货单&临时出货码')" :visible.sync="printDgVisible5" :fullscreen="isMinScreen"  @opened="openedPrint5"
            :close-on-click-modal="false" 
                width="600px">
            <stock-print5 :print-ctrls="printCtrls" ref="printDg5" v-on:close-dg="closePrintDialog5"/>
        </el-dialog>

        <el-dialog :title="msg('打印虾皮宅配')" :visible.sync="printXPZPDgVisible" :fullscreen="isMinScreen"  @opened="openedXPZPPrint"
            :close-on-click-modal="false" 
                width="900px">
            <stock-print-xpzp :print-ctrls="printCtrls" ref="printXPZPDg" v-on:close-dg="closePrintXPZPDialog"/>
        </el-dialog>

        <el-dialog :title="msg('同步店铺订单')" :visible.sync="syncOrderVisible" :fullscreen="isMinScreen" 
            :close-on-click-modal="false" 
                width="400px">
            <sync-order  v-on:close-dg="() => syncOrderVisible = false"/>
        </el-dialog>

        <el-dialog :title="msg('包号录入')" :visible.sync="pkgCodeVisible" :fullscreen="isMinScreen" @opened="openedPkgCode"
            :close-on-click-modal="false" 
                width="800px">
            <pkg-code ref="pkgCodeDg"  v-on:close-dg="pkgCodeClose"/>
        </el-dialog>

        <el-dialog :title="msg('扫码打包')" :visible.sync="packageVisible" :fullscreen="isMinScreen" @opened="openedPackage"
            :close-on-click-modal="false" 
                width="600px">
            <auto-pkg-code ref="packageDg"  v-on:close-dg="() => packageVisible = false" />
        </el-dialog>

        <el-dialog :title="msg('转寄找货单')" :visible.sync="printFindVisible" :fullscreen="isMinScreen"  @opened="openedFindPrint"
            :close-on-click-modal="false" 
                width="800px">
            <stock-print-find :print-ctrls="printCtrls" ref="printFindDg" v-on:close-dg="() => printFindVisible = false"/>
        </el-dialog>

        <el-dialog :title="msg('转运找货单')" :visible.sync="printPkgCodeVisible" :fullscreen="isMinScreen" @opened="openedPrintPkgCode"
            :close-on-click-modal="false" 
                width="800px">
            <stock-print-pkg-code ref="printPkgCodeDg"  v-on:close-dg="() => printPkgCodeVisible = false"/>
        </el-dialog>

        <el-dialog :title="msg('待贴单包裹')" :visible.sync="printPkgPageVisible" :fullscreen="isMinScreen" @opened="openedPrintPkgPage"
            :close-on-click-modal="false" 
                width="600px">
            <stock-print-pkg-page ref="printPkgPageDg" v-on:close-dg="() => printPkgPageVisible = false"/>
        </el-dialog>

        <el-dialog :title="msg('提货表')" :visible.sync="exportVisiable" :fullscreen="isMinScreen"  @opened="openExportDg"
            :close-on-click-modal="false" 
                width="400px" >
            <export-tihuo ref="exportDg" :tihuoPkgCodes="tihuoPkgCodes" @close-dg="closeTihuoDg"/>
        </el-dialog> 

        <el-dialog :title="msg('货运代打包')" :visible.sync="pkgTypeVisible" :fullscreen="isMinScreen" @opened="openedPkgType"
            :close-on-click-modal="false" 
                width="400px">
            <stock-pkg-type :ids="pkgTypeIds" ref="pkgTypeDg" @close-dg="closePkgTypeDg"/>
        </el-dialog>

        <el-dialog :title="msg('通知收件')" :visible.sync="receiveVisible" :fullscreen="isMinScreen" @opened="openedReceive"
            :close-on-click-modal="false" 
                width="400px">
            <stock-receive :ids="receiveIds" ref="receiveDg" @close-dg="closeReceiveDg" />
        </el-dialog>

        <el-dialog :title="msg('快递分拣')" :visible.sync="expSortVisible" :fullscreen="isMinScreen" @opened="openedExpSort"
            :close-on-click-modal="false" 
                width="600px">
            <express-sorting ref="expSortRef" @close-dg="expSortDg" />
        </el-dialog>

        <el-dialog :title="msg('录查询码')" :visible.sync="expCodeVisible" :fullscreen="isMinScreen" @opened="openedExpCode"
            :close-on-click-modal="false" 
                width="600px">
            <exp-code-input ref="expCodeRef" @close-dg="expCodeDg" />
        </el-dialog>

        <el-dialog :title="msg('扫码找货')" :visible.sync="actualCodeVisible" :fullscreen="isMinScreen" @opened="openedActualCode"
            :close-on-click-modal="false" 
                width="600px">
            <exp-code-check :ordercode="scanCode" ref="actualCodeRef" @close-dg="actualCodeDg" />
        </el-dialog>

        <el-dialog :title="msg('货物交接')" :visible.sync="jiaojieVisible" :fullscreen="isMinScreen" @opened="openedJiaojie"
            :close-on-click-modal="false" 
                width="600px">
            <handover ref="jiaojieRef" @close-dg="jiaojieDg" />
        </el-dialog>

        <el-dialog :title="msg('代打包登记')" :visible.sync="packVisible" :fullscreen="isMinScreen" @opened="openedPack"
            :close-on-click-modal="false" 
                width="600px">
            <pack-input ref="packRef" @close-dg="packDg" />
        </el-dialog>

        <el-dialog :title="msg('快递货物')" :visible.sync="kuaidiVisible" :fullscreen="isMinScreen" @opened="openedKuaidi"
            :close-on-click-modal="false" 
                width="600px">
            <inner-exp-code-dg ref="kuaidiRef" @close-dg="kuaidiDg" />
        </el-dialog>

        <el-dialog :title="msg('集运收费报单')" :visible.sync="zhuanyunChargeVisible" :fullscreen="isMinScreen" @opened="openedCharge"
            :close-on-click-modal="false" 
                width="600px">
            <zhuanyun-charge :id="chargeId" ref="chargeRef" @close-dg="chargeDg" />
        </el-dialog>

        <el-dialog :title="msg('库存分拣')" :visible.sync="expStockVisible" :fullscreen="isMinScreen" @opened="openedExpStockSort"
            :close-on-click-modal="false" 
                width="600px">
            <stock-sorting ref="expStockRef" @close-dg="expStockDg" />
        </el-dialog>

        <el-dialog :title="msg('导出选项')" :visible.sync="exportVisible" :fullscreen="isMinScreen" @opened="openedExportTypeSort"
            :close-on-click-modal="false" 
                width="600px">
            <export-type :expcom="expcomType" ref="exportRef" @close-dg="exportDg" />
        </el-dialog>

        <el-dialog :title="msg('出货统计')" :visible.sync="exportTjVisible" :fullscreen="isMinScreen" @opened="openedExpTjSort"
            :close-on-click-modal="false" 
                width="600px">
            <export-tongji ref="exportTjRef" @close-dg="exportTjDg" />
        </el-dialog>

        <el-dialog :title="msg('出货时间')" :visible.sync="weightTimeVisible" :fullscreen="isMinScreen" @opened="openedWeightTime"
            :close-on-click-modal="false" 
                width="600px">
            <stock-weight-time :ids="weightTimeIds" ref="weightTimeRef" @close-dg="weightTimeDg" />
        </el-dialog>

        <el-dialog :title="msg('打印实名单')" :visible.sync="printPkgVisiable" :fullscreen="isMinScreen"  @opened="openPrintPkgDg"
            :close-on-click-modal="false" 
                width="600px" >
            <print-pkg-ticket ref="printPkgDg" :ids="pkgTicketIds"  @close-dg="closePrintPkgDg"/>
        </el-dialog> 

        <el-dialog :title="msg('自动打印实名发票单')" :visible.sync="printPkgVisiableAuto" :fullscreen="isMinScreen"  @opened="openPrintPkgDgAuto"
            :close-on-click-modal="false" 
                width="600px" >
            <print-pkg-ticket-auto ref="printPkgDgAuto" @close-dg="closePrintPkgDgAuto"/>
        </el-dialog> 

        <el-dialog :title="msg('打印发票')" :visible.sync="printPkgVisiableAutoP" :fullscreen="isMinScreen"  @opened="openPrintPkgDgAutoP"
            :close-on-click-modal="false" 
                width="600px" >
            <print-pkg-ticket-auto-p ref="printPkgDgAutoP" @close-dg="closePrintPkgDgAutoP"/>
        </el-dialog>

        <el-dialog :title="msg('打印实名发票')" :visible.sync="printRealVisiable" :fullscreen="isMinScreen"  @opened="openPrintRealDg"
            :close-on-click-modal="false" 
                width="600px" >
            <print-real-name-ticket ref="printRealDg" :ids="ids"  @close-dg="closePrintRealDg"/>
        </el-dialog> 

        <el-dialog :title="msg('打印标签')" v-if="printTagVisible" :visible.sync="printTagVisible1" :fullscreen="isMinScreen"
            :close-on-click-modal="false" @closed="printTagVisible=false"
                width="600px" >
            <print-tag ref="printTagDg" :ids="ids" />
        </el-dialog>

        <el-dialog :title="msg('打印查询码')" :visible.sync="printTagVisible2" :fullscreen="isMinScreen" @opened="openPrintTagDg2"
            :close-on-click-modal="false" @closed="printTagVisible2=false"
                width="600px" >
            <print-tag-2 ref="printTagDg2" :ids="ids" />
        </el-dialog> 

        <el-dialog :title="msg('打印库签')" :visible.sync="printKuTagVisible" :fullscreen="isMinScreen" @opened="openPrintKuTagDg"
            :close-on-click-modal="false"
                width="600px" >
            <print-ku-tag ref="printKuTagDg" :ids="ids" @close-dg="closePrintKuTagDg"/>
        </el-dialog>


        <el-dialog :title="msg('打印包裹单')" v-if="printBagVisible" :visible.sync="printBagVisible1" :fullscreen="isMinScreen"
            :close-on-click-modal="false" @closed="printBagVisible=false"
                width="600px" >
            <print-bag ref="printBagDg" :ids="ids" />
        </el-dialog> 

        <el-dialog :title="msg('更新收费方式')" :visible.sync="transitTypeVisible" :fullscreen="isMinScreen" @opened="openedTransitType"
            :close-on-click-modal="false" 
                width="650px">
            <stock-transit ref="transitTypeRef" :ids="transitIds" @close-dg="transitTypeDg" />
        </el-dialog>
        

        <el-dialog :title="msg('打印报关单')" v-if="delcarePrintVisiable" :visible.sync="delcarePrintVisiable" :fullscreen="isMinScreen"
            :close-on-click-modal="false" @closed="delcarePrintVisiable=false"
                width="600px" >
            
            <declare-print ref="delcarePrintDg" :ids="ids" />
        </el-dialog>

        <el-dialog :title="msg('新建工单')" :visible.sync="createWorkOrderVisible" :fullscreen="isMinScreen" :close-on-click-modal="false">
          <work-order-add :workorder="workOrder" v-on:close-dg="closeWorkOrderAddDialog" ref="WorkOrderAdd"/>
        </el-dialog>

        <el-dialog :title="msg('验证个人安全密码')" :visible.sync="strongPwdVisiable" :close-on-click-modal="false">
          <StrongPwd v-on:close-dg="kit.strongAuthData.closeEvent"/>
        </el-dialog>

    </div>

</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import Edit from "./StockEdit.vue";
import StockBatchUpdate from "./StockBatchUpdate.vue";
import XmDrawer from "../../components/XmDrawer.vue"
import StockPageImport from "./StockPageImport.vue";
import StockPageOnlyImport from "./StockPageOnlyImport.vue";
import StockPageView from "./StockPageView.vue";
import StockAddPkg from "./StockAddPkg.vue";
import StockWeightShare from "./StockWeightShare.vue";
import StockPrint from "./StockPrint.vue";
import StockPrint2 from "./StockPrint2.vue";
import StockPrint3 from "./StockPrint3.vue";
import StockPrint4 from "./StockPrint4.vue";
import StockPrint5 from "./StockPrint5.vue";
import StockPrint6 from "./StockPrint6.vue";
import StockPrint7 from "./StockPrint7.vue";
import StockPrint8 from "./StockPrint8.vue";
import StockPrintXpzp from "./StockPrintXPZP.vue";
import ZhuanPkg from "./Zhuanyun.vue";
import QijianHandle from "./QijianHandle.vue";
import SyncOrder from "./StockSyncOrder.vue";
import Trace from "./StockTrace.vue";
import PkgCode from "./InsertPkgCode.vue";
import AutoPkgCode from "./AutoPkgCode.vue";
import StockPrintFind from "./StockPrintFind.vue";
import StockPrintPkgCode from "./StockPrintPkgCode.vue";
import StockPrintPkgPage from "./StockPrintPkgPage.vue";
import ExportTihuo from "./ExportTihuo.vue";
import StockPkgType from "./StockPkgType.vue";
import StockReceive from "./StockReceive.vue";
import ExpressSorting from "./ExpressSorting.vue";
import ExpCodeInput from "./ExpCodeInput.vue";
import PackInput from "./PackInput.vue";
import ZhuanyunCharge from "./ZhuanyunCharge.vue";
import ExpCodeCheck from "./ExpCodeCheck.vue";
import StockSorting from "./StockSorting.vue";
import ExportType from "./ExportType.vue";
import ExportTongji from "./ExportTongji.vue";
import PrintPkgTicket from "./PrintPkgTicket.vue";
import PrintPkgTicketAuto from "./PrintPkgTicketAuto.vue";
import PrintPkgTicketAutoP from "./PrintPkgTicketAutoP.vue";
import PrintRealNameTicket from "./PrintRealNameTicket.vue";
import PrintTag from './StockPrintTag.vue';
import PrintTag2 from './StockPrintTag2.vue';
import PrintKuTag from './StockPrintKuTag.vue';
import PrintBag from './StockPrintBag.vue';
import StockTransit from './StockTransitDialog.vue';
import StockWeightTime from './StockWeightTime.vue';
import InnerExpCodeDg from './InnerExpCodeDg.vue';
import DeclarePrint from '../declare/Print.vue';
import axios from "axios";
import Handover from './Handover.vue';
import WorkOrderAdd from "@/views/sys/WorkOrderAdd.vue";
import StrongPwd from "@/views/sys/StrongPwd.vue";

export default {
    name: 'Stock',
    components: {
      StrongPwd,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        Edit:Edit,
        Drawer : XmDrawer,
        StockPageImport : StockPageImport,
        StockPageOnlyImport:StockPageOnlyImport,
        BatchUpdate: StockBatchUpdate,
        PageView: StockPageView,
        StockAddPkg: StockAddPkg,
        StockWeightShare:StockWeightShare,
        ZhuanPkg:ZhuanPkg,
        StockPrint: StockPrint,
        StockPrint2:StockPrint2,
        StockPrint3:StockPrint3,
        StockPrint4:StockPrint4,
        StockPrint5:StockPrint5,
        StockPrint6:StockPrint6,
        StockPrint7:StockPrint7,
        StockPrint8:StockPrint8,
        StockPrintXpzp:StockPrintXpzp,
        SyncOrder: SyncOrder,
        Trace: Trace,
        PkgCode:PkgCode,
        AutoPkgCode:AutoPkgCode,
        StockPrintFind:StockPrintFind,
        StockPrintPkgCode:StockPrintPkgCode,
        StockPrintPkgPage:StockPrintPkgPage,
        ExportTihuo:ExportTihuo,
        StockPkgType:StockPkgType,
        StockReceive:StockReceive,
        ExpressSorting:ExpressSorting,
        ExportType:ExportType,
        ExportTongji:ExportTongji,
        PrintPkgTicket:PrintPkgTicket,
        PrintPkgTicketAuto:PrintPkgTicketAuto,
        PrintPkgTicketAutoP:PrintPkgTicketAutoP,
        PrintRealNameTicket:PrintRealNameTicket,
        StockSorting:StockSorting,
        QijianHandle:QijianHandle,
        PrintTag:PrintTag,
        PrintTag2:PrintTag2,
        PrintKuTag:PrintKuTag,
        PrintBag:PrintBag,
        StockTransit:StockTransit,
        ExpCodeInput:ExpCodeInput,
        PackInput:PackInput,
        ExpCodeCheck:ExpCodeCheck,
        ZhuanyunCharge:ZhuanyunCharge,
        DeclarePrint:DeclarePrint,
        StockWeightTime:StockWeightTime,
        InnerExpCodeDg:InnerExpCodeDg,
        Handover:Handover,
        WorkOrderAdd: WorkOrderAdd,
    },
    mounted(){
        this.user  = JSON.parse(localStorage.getItem("user"));
        this.initPage();

        this.getAllViews(0);
        this.$nextTick(()=>{
            window.stockTable = this.$refs.dataTable;
            
            // this.$refs.pageBox.addEventListener('scroll', ()=>{
            //     this.$refs.pageBox.scroll( ()=>{
            //         console.log("11");
            //     })
            // }, true)

            // 获取指定元素
            const scrollview = this.$refs['pageBox']
            scrollview.addEventListener('scroll', ()=>{
                // console.log("滚动中");
            }, true)

        });




    },
    data(){
        return {
            query:{
                history: 0, //是否查询历史纪录： 0-正常，1-查询
                length: 100, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
                queries: [],
                store: false, //是否将其作为仓储视图
            },
            showQuery: false,
            isStore:null,
           
            columns: [],  //列配置
            genColumns: [], //生成的列配置
            viewSetting: null,
            viewsVisiable:false,
            originCols: null,
            views: null, //用户的所有视图列表

            datas: [],      //展示列表
            printPkgVisiable:false,
            printPkgVisiableAuto:false,
            printPkgVisiableAutoP:false,
            printRealVisiable:false,
            printRealType:null,
            dgTitle: '',
            dgVisible: false,
            curr: null,
            detailEditable: false,
            expCodeEdit:false,
            antOrderEdit:false,
            dialogImportVisible:false,
            dialogOnlyImportVisible:false,
            batchUpdateVisible: false,
            zhuanyunChargeVisible:false,
            chargeId:null,
            batchDatas: [],

            imgVisiable: false,
            pageId: null,  //面单id

            pkgTypeIds:null,

            addPkgVisible:false,
            addPkgIds:null,

            weightShareStock:[],
            weightShareVisible:false,

            printCtrls: [],  //要打印的类型列表
            printDgVisible: false,
            printDgVisible2:false,
            printDgVisible3:false,
            printDgVisible4:false,
            printDgVisible5:false,
            printDgVisible6:false,
            printDgVisible7:false,
            printDgVisible8:false,
            printXPZPDgVisible:false,

            zhuanyunVisible:false, 
            qijianVisible:false,
            
            syncOrderVisible: false,

            traceVisible: false,

            pkgCodeVisible:false,//包号录入
            packageVisible:false,//扫码打包
            printFindVisible:false,//打印找货单
            printPkgCodeVisible:false,//按包号找货
            printPkgPageVisible:false,//待贴单包裹
            exportVisiable:false,//提货表

            pkgTypeVisible:false,//货运代打包选择仓库

            receiveVisible:false,//通知收件选择仓库
            receiveIds:null,

            expSortVisible:false,//快递分拣
            expStockVisible:false,//库存分拣
            expCodeVisible:false,//录查询码
            actualCodeVisible:false,//扫码找货
            jiaojieVisible:false,//货物交接
            packVisible:false,//代打包登记
            kuaidiVisible:false,
            exportVisible:false,
            exportType:null,//导出的类型
            exportParams:null,//导出的条件
            exportIds:null,//导出选择的id
            exportTjVisible:false,//出货统计
            weightTimeVisible:false,
            weightTimeIds:null,

            colsPopVisible: false, //字段选择弹窗
            expcomType:null,//导出黑猫派件资料选择配送方式参数
            tihuoPkgCodes:'',//导出提货表袋号

            ids:null,//选中的id, 实名单ids
            pkgTicketIds:null,//装袋单ids

            zhuanyunTitle:'转运',//转运标题
            zhuanyunType:0,//转运类别0-集运转运，1-包裹回寄
            loadMored: true,

            printTagVisible: false,
            printTagVisible1: false,
            printTagVisible2:false,
            printKuTagVisible:false,
            printBagVisible: false,
            printBagVisible1: false,
            transitTypeVisible:false,//更新收费方式
            transitIds:null,//收费方式参数
            scanCode:null,

            delcarePrintVisiable:false,
            delcarePrintVisiable1: false,

            viewFilterText: null,
            emptyFixedBoxHeight: '37px',

            createWorkOrderVisible:false, //提交工单
            workOrder: null,
            expCompanys:[],
            strongPwdVisiable: false, //个人安全密码验证窗
            exportTableType:false,//true为导出当前页，false为导出建单资料
            exportConfig: {  //导出配置
                remote: true,
                exportMethod: ({options})=>{

                    //options.columns[0].property  字段的key
                    //options.columns[0].property  字段的显示名字
                    //options.filename 导出的文件名
                    //options.mode  导出模式："current"/"selected"   
                    //options.type 导出类型："xlsx"          
                    
                    //获取要导出字段。
                    //query.queries[0].colName

                    let params = this.readyQueryParam(false);

                    // console.log('aa',params)
                    // console.log('bb',this.query)
                    // console.log('cc',options)

                    let cols = [];

                    //过滤字段，没选中的字段，就不导出
                    params.columns.forEach(col => {
                        let exist = options.columns.find(c => c.property == col.colName || c.property == col.fieldName);
                        if(exist){
                            cols.push(Object.assign({label: exist.title}, col))
                        }
                    })
                    // console.log('dd',params)
                    //如果模式selected，则将id传入到数据库
                    params["ids"] = options.data.map(d => d.id)
                    // if(options.mode == "selected"){
                    //     params["ids"] = options.data.map(d => d.id)
                    // }
                    
                    params.columns = cols;

                    console.log('dd',params)
                    //构建请求url
                    let exportUrl =  this.$kit.api.export.exportStockList;

                    var _this = this;

                    _this.kit.strongAuth(function () {
                      _this.openWindowWithPost(exportUrl, {
                        query: JSON.stringify(params),
                        mode: options.mode,
                        type: options.type,
                        isExportCurrPage: _this.exportTableType ? 1 : 0,
                        filename: encodeURIComponent(options.filename)
                      })
                    }, _this);

                    return new Promise(resolve => {
                        resolve([])
                    });
                }
            }
        }
    },
    directives: {
      reheight: {
        bind(el, binding) {
          let height = '';
          function isReheight() {
            const style = document.defaultView.getComputedStyle(el);
            if (height !== style.height) {
              binding.value(style.height);
            }
            height = style.height;
          }
          el.__vueSetInterval__ = setInterval(isReheight, 300);
        },
        unbind(el) {
          clearInterval(el.__vueSetInterval__);
        }
      }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 210;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    watch: {
      viewFilterText(val) {
        for(let key in this.views){
            let vs = this.views[key];
            vs.forEach(e => {
                if(e.name.indexOf(val)>-1){
                    e.show = true;
                }else{
                    e.show = false;
                }
            })
        }
      }
    },
    methods:{
        //初始化页面
        initPage(){
            // console.log(this.$route.query);

            let codes = this.$route.query['codes'];
            let type = this.$route.query['type'];
            if(!type){
                this.getDefaultView(null, ()=>{
                    this.initDefQuery(codes, type);
                    this.list(true);
                });
            }else{
                let id = 695; //财务专用视图
                if(this.viewSetting && this.viewSetting.id == id){
                    this.initDefQuery(codes, type);
                    this.list(true);
                }else{
                    this.getDefaultView(id, ()=>{
                        this.initDefQuery(codes, type);
                        this.list(true);
                    });
                }
            }
           this.getCompanys();
        },
        initDefQuery(code, type){
            let col = null;
            if(type==3){ //transit_code, //pay_in_code, //lipei_code
                col = this.query.queries.find(e => e.colName == 'transit_code');
            }else if(type == 4){
                col = this.query.queries.find(e => e.colName == 'pay_in_code');
            }else if(type == 5){
                col = this.query.queries.find(e => e.colName == 'lipei_code');
            }
            if(col)
                col['dvalue'] = code;
        },
        getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
        //获取默认视图
        getDefaultView(id, cb){
            let url = null;
            if(id){
                url = this.$kit.api.viewSetting.get+"?id="+id;
            }else{
                url = this.$kit.api.viewSetting.defaultView+"?t="+this.dicts.views.stockView;
            }

            this.$axios.get(url)
                .then((result) => {
                    if(result.status){
                        if(result.data && result.data.details){
                            this.buildColumns(result.data.details);
                        }
                        if(result.data && result.data.queries){
                            this.buildQueries(result.data.queries);
                        }
                        this.viewSetting = result.data;
                        this.query.store = result.data.specFlag ? true:false;
                        if(cb) cb();

                    }
                });
        },
        
        //初始化表格
        buildColumns(details){
            let _this = this;
            this.columns = [];
            this.columns.push({type: 'checkbox', minWidth: 50 ,fixed: 'left',});
            this.columns.push( 
                {field: "id", title: this.msg("ID"), minWidth: 100,sortable: true,
                    slots: {
                        default: ({ row }) => {
                            return [
                                <span class="id-cell"><i class="view-cell el-icon-edit-outline"></i>{row.id}&nbsp;<i class="remove-cell el-icon-remove-outline"></i></span>,
                            ];
                        },
                    }
                }
            );
            details.forEach(e => {
                var col = {
                    field: e.fieldName, 
                    title: e.nlabel ? e.nlabel : e.label,
                    minWidth: e.width || 100,
                    sortable: e.sortable == 1 ? true : false,
                    visible: true,
                };

                if(e.colName == 'exp_page_status'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let status = this.dicts.stockExpPageStatus.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function({ cellValue }){
                        var s = _this.dicts.stockExpPageStatus.find(d => d.value === cellValue);
                        return  s?s.label : cellValue;
                    }
                }else if(e.colName == 'product_title'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let title =  row[e.fieldName];
                            return [
                                <el-link href={_this.$kit.api.shopeeDetail+"?id="+row.id+"&title="+encodeURIComponent(title)} target="_blank">{title}</el-link>
                            ];
                        }
                    }
                }else if(e.colName == 'code'){
                    // col['formatter'] = function( { cellValue } ){
                    //     return cellValue?cellValue:"null";
                    // }
                    col['slots'] = {
                        default: ({ row }) => {
                            let title =  row[e.fieldName];
                            return [
                                // <el-tag click={openDetail(id)}>{title}</el-tag>
                                <span class="id-cell"><i class="detail-cell el-icon-view"></i>{title}</span>
                            ];
                        }
                    }
                }else if(e.colName == 'exp_code'){
                    col['formatter'] = function( { cellValue } ){
                        return cellValue?" "+cellValue:"null";
                    }
                }else if(e.colName == 'exp_page_code'){
                    col['formatter'] = function( { cellValue } ){
                        return cellValue?cellValue:"null";
                    }
                }else if(e.colName == 'receiver_shop_code'){
                    // col['formatter'] = function( { cellValue } ){
                    //     return cellValue?" "+cellValue:"null";
                    // }
                    col['slots'] = {
                        default: ({ row }) => {
                            return '********';
                        },
                    }
                }else if(e.colName == 'receiver_shop_name'){
                    col['slots'] = {
                        default: ({ row }) => {
                            return '********';
                        },
                    }
                }else if(e.colName == 'exp_cod'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let status = this.dicts.stockExpType.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.stockExpType.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'status'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let status = this.dicts.stockStatus.find(d => d.value === row[e.fieldName]);
                            return [
                                <span><i class="status-cell iconfont icon-trace font11"></i><el-tag type={status?status.type:''}>{status?status.label:row[e.fieldName]}</el-tag></span>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.stockStatus.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'order_origin'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let status = this.dicts.orderOrigin.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.orderOrigin.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'order_platform'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let status = this.dicts.shopTypes.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.shopTypes.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'err_status'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let status = this.dicts.stockErrStatus.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.stockErrStatus.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'exp_out_type'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let status = this.dicts.expoutType.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.expoutType.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'exp_good_type'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let admin = this.dicts.stockExpGoodType.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={admin?admin.type:''}>{admin?admin.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.stockExpGoodType.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'exp_pkg_type'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let vip = this.dicts.stockExpPkgType.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={vip?vip.type:''}>{vip?vip.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.stockExpPkgType.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'store_type'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let storeType = this.dicts.stockStoreType.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={storeType?storeType.type:''}>{storeType?storeType.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.stockStoreType.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'in_store_status'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let inStoreStatus = this.dicts.stockInStoreStatus.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={inStoreStatus?inStoreStatus.type:''}>{inStoreStatus?inStoreStatus.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.stockInStoreStatus.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'exp_allow_print'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let inStoreStatus = this.dicts.stockExpAllowPrint.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={inStoreStatus?inStoreStatus.type:''}>{inStoreStatus?inStoreStatus.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.stockExpAllowPrint.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'is_cancel'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let inStoreStatus = this.dicts.cances.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={inStoreStatus?inStoreStatus.type:''}>{inStoreStatus?inStoreStatus.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.cances.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'has_tax'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let inStoreStatus = this.dicts.stockHasTax.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={inStoreStatus?inStoreStatus.type:''}>{inStoreStatus?inStoreStatus.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        var s = _this.dicts.stockHasTax.find(d => d.value === cellValue);
                        return s?s.label : cellValue;
                    }
                }else if(e.colName == 'product_thumb'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let id = row[e.fieldName]
                            return id?[
                                <el-image  style="width: 60px; height: 60px" 
                                    src={this.$kit.api.imgView+id} 
                                    preview-src-list={[this.$kit.api.imgView+id]}
                                    ></el-image>
                            ]: [id];
                        },
                    }
                }
                else if(e.colName == 'page_id'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let id = row[e.fieldName]
                            return id?[
                                <el-image  style="width: 60px; height: 60px" 
                                    src={this.$kit.api.imgView+id} 
                                ></el-image>
                            ]: [id];
                        },
                    }
                }
                else if(e.colName == 'video_img'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let id = row[e.fieldName]
                            return id?[
                                <el-image  style="width: 60px; height: 60px" 
                                    src={this.$kit.api.imgView+id} 
                                    preview-src-list={[this.$kit.api.imgView+id]} 
                                ></el-image>
                            ]: [id];
                        },
                    }
                }
                else if(['transit_status', 'pay_in_status'].indexOf(e.colName)>-1){
                    col['slots'] = {
                        default: ({ row }) => {
                            let val = row[e.fieldName];
                            if(!val){
                                return '未计算';
                            }
                            return val == 1 ? _this.msg('待付'): val==2?_this.msg('已扣款'): val==3?_this.msg('已出账单'):'';
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        if(!cellValue){
                            return '';
                        }
                        return cellValue == 1 ? _this.msg('待付'): cellValue==2?_this.msg('已扣款'): cellValue==3?_this.msg('已出账单') :cellValue;
                        
                    }
                }else if('lipei_status' == e.colName){
                    col['slots'] = {
                        default: ({ row }) => {
                            let val = row[e.fieldName];
                            if(val === null){
                                return '无';
                            }
                            return val === 0 ? '无' : val === 1 ? _this.msg('未理赔') : val === 2 ? _this.msg('已理赔') : val === 3 ? _this.msg('待付') : val;
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        if(cellValue === null){
                            return '无';
                        }
                        return cellValue === 0 ? '无' : cellValue === 1 ? _this.msg('未理赔') : cellValue === 2 ? _this.msg('已理赔') : cellValue === 3 ? _this.msg('待付') : cellValue;
                    }
                }else if('kesu_status' == e.colName){
                    col['slots'] = {
                        default: ({ row }) => {
                            let val = row[e.fieldName];
                            if(val === null){
                                return '无';
                            }
                            return val === 0 ? '无' : val === 1 ? _this.msg('待结算') : val === 2 ? _this.msg('已结算') : val;
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        if(cellValue === null){
                            return '无';
                        }
                        return cellValue === 0 ? '无' : cellValue === 1 ? _this.msg('待结算') : cellValue === 2 ? _this.msg('已结算') : cellValue;
                    }
                }else if('level' == e.colName){
                    col['slots'] = {
                        default: ({ row }) => {
                            let val = row[e.fieldName];
                            if(val === null){
                                return '无';
                            }
                            return val === 0 ? '无' : val === 1 ? _this.msg('一级') : val === 2 ? _this.msg('二级') : val === 2 ? _this.msg('三级') : val;
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        if(cellValue === null){
                            return '无';
                        }
                        return cellValue === 0 ? '无' : cellValue === 1 ? _this.msg('一级') : cellValue === 2 ? _this.msg('二级') : cellValue === 2 ? _this.msg('三级') : cellValue;
                    }
                }else if('pack_type' == e.colName){
                    col['slots'] = {
                        default: ({ row }) => {
                            let val = row[e.fieldName];
                            if(val === null){
                                return '无';
                            }
                            return val === 0 ? '普通' : val === 1 ? '精细' : val;
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        if(cellValue === null){
                            return '无';
                        }
                        return cellValue === 0 ? '普通' : cellValue === 1 ? '精细' : cellValue;
                    }
                }else if('purchase_status' == e.colName){
                    col['slots'] = {
                        default: ({ row }) => {
                            let val = row[e.fieldName];
                            if(val === null){
                                return '无';
                            }
                            return val === 0 ? '待采购' : val === 1 ? '已采购' : val;
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        if(cellValue === null){
                            return '无';
                        }
                        return cellValue === 0 ? '待采购' : cellValue === 1 ? '已采购' : cellValue;
                    }
                }else if('transit_type' == e.colName){
                    col['slots'] = {
                        default: ({ row }) => {
                            let val = row[e.fieldName];
                            if(val === null){
                                return '';
                            }
                            let types =  [_this.msg("空运"), _this.msg("海运"), _this.msg("按票"), _this.msg("特殊1"), _this.msg("特殊2"),  _this.msg("特殊3")];
                            let label = types[val];
                            return label || val;
                        },
                    },
                    col['formatter'] = function( { cellValue } ){
                        if(cellValue === null){
                            return '';
                        }
                        let types =  [_this.msg("空运"), _this.msg("海运"), _this.msg("按票"), _this.msg("特殊1"), _this.msg("特殊2"),  _this.msg("特殊3")];
                        let label = types[cellValue];
                        return label || cellValue;
                    }
                }
                // else if('receiver' == e.colName){
                //     col['slots'] = {
                //         default: ({ row }) => {
                //             // let val = row[e.fieldName];
                //             // return this.replaceStr(val,1,'*');
                //             return '***';
                //         },
                //     }
                // }
                // else if(e.colName == 'receiver_tel'){
                //     col['formatter'] = function( { cellValue } ){
                //         return cellValue?" "+cellValue+" ":"null";
                //     }
                // }
                // else if('receiver_tel' == e.colName){
                //     col['slots'] = {
                //         default: ({ row }) => {
                //             return '********';
                //         },
                //     }
                // }else if('receiver_addr' == e.colName){
                //     col['slots'] = {
                //         default: ({ row }) => {
                //             return '****************';
                //         },
                //     }
                // }

                if(e.filterType == 'search'){
                    col['filters'] = [{data:''}];
                    col['filterRender'] = {name: 'FilterInput'};
                }else if(e.filterType == 'content'){
                    col['filters'] = [{data: {vals: [], sVal: '', my: "t_stock"}}];
                    col['filterRender'] = {name: 'StockFilterContent'};
                }else if(e.filterType == 'type'){
                    col['filters'] = this.getDicts(e.filterOptionKey);
                }else if(e.filterType == 'complex'){
                    col['filters'] = [{data: {type: 'has', isCase: false, name: ''}}];
                    col['filterRender'] = {name: 'FilterComplex'};
                }else if(e.filterType == 'date'){
                    col['filters'] = [{data: []}];
                    col['filterRender'] = {
                        name: 'ElDatePicker', 
                        props: {
                            type: 'daterange', 
                            rangeSeparator: '至', startPlaceholder: '开始日期', 
                            endPlaceholder: '结束日期'
                        }
                    };
                }

                this.columns.push(col);
            });

            this.originCols = details.map(e =>  {
                return {
                    tableName : e.tableName,
                    colName: e.colName,
                    fieldName: e.fieldName,
                    hasAlias: e.hasAlias,
                    tableAlias: e.tableAlias
                }
            });

            setTimeout(() => {
                this.$nextTick(() => {
                    this.genColumns = this.$refs.dataTable.getColumns()
                })
            }, 1000);
            

        },
        replaceStr(str,index,char){
            if(str && str.length >= 2){
                return str.substring(0,index)+char+str.substring(index+1);
            }else{
                return '**';
            }
        },
        //初始化查询条件
        buildQueries(qs){
            qs.push({
                colName: "allcode",
                disabled: 0,
                label: this.msg("快速批查"),
                op: 6,
                seq: 0,
                tableAlias: "t",
                tableName: "t_stock",
                type: 0,
            })
            qs.push({
                colName: "alllikecode",
                disabled: 0,
                label: this.msg("全局查询"),
                op: 7,
                seq: 0,
                tableAlias: "t",
                tableName: "t_stock",
                type: 0,
            })

            qs.forEach(q => {
                if(q.dvalue || q.dvalue === 0){

                    if(q.type == 3){ //3-表示是时间类型
                        let curr = new Date();
                        let prevTime =  curr.getTime() - (q.dvalue * 24 * 3600 * 1000);
                        let prev;
                        if(q.dvalue == 1){
                            prev = curr;
                        }else{
                            prev = new Date(prevTime);
                        }
                        q['dvalues'] = [this.$kit.fmtDate(prev), this.$kit.fmtDate(curr)]
                    }else if(q.type == 4){//日期时间类型
                        let curr = new Date();
                        let prevTime =  curr.getTime() - (q.dvalue * 24 * 3600 * 1000);
                        let prev = new Date(prevTime);
                        q['dvalues'] = [this.$kit.fmtDateTime(prev), this.$kit.fmtDateTime(curr)]
                    }else if(q.op == 6 || q.op == 8){ //表示可以有多个值
                        q['dvalues'] = q.dvalue.split(",").map(v => (v*1 || v));
                    }else{
                        let val = q.dvalue;
                        let val1 = val*1;
                        q.dvalue = val1 || val;
                        q['dvalues'] = []; 
                    }
                }else{
                    q['dvalues'] = [];
                }
            });
            
            this.query.queries = qs;
            return qs;
        },
        //获取所有的仓库视图
        getAllViews(type){
            
            //type==0, 加载常用视图
            //type==1, 加载系统视图，自定义视图
            if(!this.views) {
                this.views = {};
            }

            this.$axios.post(this.$kit.api.viewSetting.listByType+"?type="+type||0, {category: this.dicts.views.stockView})
                .then((result) => {
                    if(result.status){
                        
                        let views1 = this.views["常用视图"] || [];
                        let views2 = this.views["系统视图"] || [];
                        let views3 = this.views["自定义视图"] || [];

                        for(let i=0; i<result.data.length; i++){
                            let v = result.data[i];
                            v['show'] = true;
                            if(type==1){
                                if(v.creater == 0){
                                    views2.push(v);
                                }else{
                                    views3.push(v);
                                }
                            }else{
                                views1.push(v);
                            }

                        }

                        if(views1.length>0){
                            this.views["常用视图"] = views1;
                        }
                        if(views2.length>0){
                            this.views["系统视图"] = views2;
                        }
                        if(views3.length>0){
                            this.views["自定义视图"] = views3;
                        }

                        if(type == 1){
                            this.loadMored = false;
                        }

                    }
                });
        },
        //点击视图，切换到指定视图
        changeViews(view){
            this.viewSetting = view;
            this.query.store = view.specFlag ==1 ? true:false;
            this.isStore = view.specFlag ==1 ? true:false;
            if(!view.details || view.details.length == 0){
                this.$axios.get(this.$kit.api.viewSetting.get+"?id="+view.id)
                    .then((result) => {
                        if(result.status){
                            this.buildColumns(result.data.details);
                            var qs = this.buildQueries(result.data.queries);
                            view['details'] = result.data.details;
                            view['queries'] = qs; //缓存起来
                            view['btns'] = result.data.btns;
                            this.list(true);
                        }
                });
            }else{
                this.buildColumns(view.details);
                this.query.queries = view.queries;
                this.list(true);
            }
            this.viewsVisiable = false;
            
           
        },
        //获取列表数据
        list(refresh){
           let params = this.readyQueryParam(refresh);
            
            this.$axios.post(this.$kit.api.stock.list, params)
                .then((result) => {
                    if(result.status){
                        let list = result.data.list;
                        
                        list.forEach(row => {
                            
                            for(let colName in row){
                                //weightInputer
                                let lowerCol = colName.toLowerCase();
                                let val = row[colName];
                                if(val){  //这里格式化根据单位进行转换
                                    if(colName.indexOf("weight") == 0 && colName.indexOf("weightTime") == -1 && lowerCol.indexOf("inputer")==-1){
                                        row[colName] = this.formatWeight(val);
                                    }else if( colName.indexOf('price')>-1 || colName.indexOf("amt") == 0 || lowerCol.indexOf('rate')>-1){
                                        row[colName] = this.formatAmt(val)
                                    }else if(lowerCol=="product_volume" || lowerCol=="productvolume"){
                                        row[colName] = this.formatVolume(val)
                                    }
                                }
                            }
                            
                            row.queryHistory = this.query.history;

                        });

                        this.datas = list;
                    }
                });

                if(refresh){
                    // this.$axios.post(this.$kit.api.stock.listTotal, params)
                    // .then((result) => {
                    //     if(result.status){
                    //         let total = result.data;
                    //         console.log("总条数为："+total);
                    //         this.query.pageTotal = total;
                    //     }
                    // });

                    axios.request({
                        url: this.$kit.api.stock.listTotal,
                        method: 'post',
                        data: params,
                        headers: {"Content-Type":"application/json; charset=utf-8",
                            "X-Requested-With":"XMLHttpRequest",
                        },
                        withCredentials: true,
                    }).then((result) => {
                        let res = result.data;
                        if(res.status){
                            let total = res.data;
                            // console.log("总条数为："+total);
                            this.query.pageTotal = total;
                        }
                    });

                }
        },
        readyQueryParam(refresh){
            // console.log("get list: "+refresh);
            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
                this.query.pageTotal = 0;//this.query.length;
                // console.log("pageTotal", this.query.length+"-"+this.query.pageTotal);
                
            }

            this.query.store = this.isStore;
            var params = this.$kit.rebuildQuery(this.query);
            params['columns'] = this.originCols;

            //获取查询的数，并且过滤掉空数据，
            let queries = []; 
            this.query.queries.forEach(e => {
                    let values = e.dvalues;
                    let value = e.dvalue;
                    let q = {
                        dvalue: e.dvalue,
                        tableName: e.tableName,
                        colName: e.colName,
                        op: e.op,
                        type: e.type,
                        tableAlias: e.tableAlias
                    }

                    if(q.type == 0 && q.op == 6){
                        if(value){
                            value = value.split(/[\s,]+/);
                            q.dvalue = value.join(",");
                            queries.push(q);
                        }
                    }else{
                        if(q.type == 3 || q.op == 6 || q.op==8 || q.type == 4){
                            if(values && values.length>0){
                                q.dvalue = values.join(",");
                                queries.push(q);
                            }
                        }else if(q.dvalue || q.dvalue === 0){
                            queries.push(q);
                        }
                    }
                });
            params['queries'] = queries;

            return params;
        },

        //重置查询条件
        reset(){
            this.$kit.clearQuery(this.query);
            if(this.query.queries){ 
                this.query.queries.forEach(e => {
                        if(e.disabled == 0){
                            if(e instanceof Array){
                                e.dvalues = [];
                            }else{
                                e.dvalues = null;
                            }
                            if(e.hasOwnProperty('dvalue')){
                                e.dvalue = null; 
                            }
                        }
                    }
                );
            }
        },
        //点击行，选中行
        clickRow({row,rowIndex, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            let className = $event.target.className;
            if(columnIndex == 1 ){  //查看，编辑，详情
                if(className.indexOf('view-cell')>-1){
                    var _this = this;
                    _this.kit.strongAuth(function () {
                      //执行正常操作
                      _this.doEditRow(row);
                    }, _this);
                }else if(className.indexOf('remove-cell')>-1){
                    this.datas.splice(rowIndex, 1);
                }
            }else if(column.property == 'pageId'){  //执行查看面单
                if(row.pageId){
                    this.imgVisiable = true;
                    this.pageId = row.pageId;
                }
            }else if(column.property == 'code'){  //打开预览详情
                if(className.indexOf('detail-cell')>-1){
                    if(!this.hasPermission("stoce:openStockDetail")){
                        this.$message(this.msg('非超级会员，不能点击直接查看！'));
                        return;
                    }
                    this.openDetail(row.id);
                }
            }else if(column.property == 'status'){//打开物流记录
                if(!this.hasPermission("stoce:openStockTrack")){
                    this.$message(this.msg('非超级会员，不能点击直接查看！'));
                    return;
                }
                if(className.indexOf('status-cell')>-1){
                    var table =  this.$refs.dataTable;
                    table.clearCheckboxRow();
                    table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
                    this.traceVisible = true;
                }
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        formatAmt(value){
            try{
                return (value/100).toFixed(2);
            }catch(err){
                return value;
            }
        },
        formatWeight(value){
            try{
                return (value/1000).toFixed(3);
            }catch(err){
                return value;
            }
        },
        formatVolume(value){
            try{
                return (value/1000).toFixed(3);
            }catch(err){
                return value;
            }
        },
        //点击添加按钮
        doAdd(type){
            // this.$tabs.open ("/admin/stock/add");
            this.$router.push({ name: 'stockAdd', params: {type:type}});
        },
        doEditRow(row){
            this.curr  = row;
            // if(!row['details']){
                this.$set(row,'details',[]);
                this.$axios.get(this.$kit.api.stock.detail+"?id="+row.id)
                    .then((result) => {
                        if(result.status){
                            
                            var ds = result.data;

                            ds.forEach(d => {
                                d['thumbs'] = [];
                                d['visible'] = true;
                                if(d.productThumb){
                                    d['thumbs'].push({
                                        name: d.productThumb,
                                        url: this.$kit.api.imgView+d.productThumb
                                    });
                                }
                                if(d.productPrice){
                                    d.productPrice = this.formatAmt(d.productPrice);
                                }
                            });

                            this.$set(row,'details',ds);
                        }
                    });
            // }

            if(this.dgVisible){
                this.$nextTick(() => {
                    this.$refs.editDg.initForm();
                });
            }
            this.$axios.get(this.$kit.api.stock.getStockById+"?id="+row.id)
                .then((result) => {
                    var ds = result.data;
                    if(ds.status == 10 || ds.status >= 23){
                        this.detailEditable = false;
                    }else{
                        this.detailEditable = true;
                    }

                    if(ds.status >= 13 && ds.status < 50){
                        this.expCodeEdit = true;
                    }else{
                        this.expCodeEdit = false;
                    }

                    if(ds.status >= 13 && ds.status < 23){
                        this.antOrderEdit = true;
                    }else{
                        this.antOrderEdit = false;
                    }

                    this.dgTitle = this.msg("编辑库存");
                    this.dgVisible = true;

                })
            
        },
        //删除选中项
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = selectes.map(e => e.id).join(",");
            var _this = this;
            this.$confirm(this.fmt('确定要删除%s吗?', [ids]), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.stock.delete+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        _this.list();
                        let oks = result.data.oks;
                        let errs = result.data.errs;
                        _this.$message(_this.msg('删除成功')+"："+oks+"，"+_this.msg('删除失败')+"："+errs );
                    }else{
                        _this.$message(result.msg);
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        doDeleteOne(){//单个删除
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length > 1){
                this.$message(this.msg('一次只能删除一条数据'));
                return;
            }
            this.$confirm(this.msg('是否要删除选择的订单?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                    this.$axios.post(this.$kit.api.stock.deleteOne+"?id="+selectes.map(e => e.id).join(","))
                        .then((result) => {
                            if(result.status){
                                this.list();
                                let oks = result.data.oks;
                                let errs = result.data.errs;
                                this.$message(this.msg('删除成功')+"："+oks);
                            }else{
                                this.$message(result.msg);
                            }
                        });
                }).catch((err) => {
                // console.log(err);
            });
        },
        doUpdateCompany(){
            if(this.user.deptPath.indexOf('/1/6265/3441')<0 && this.user.deptPath.indexOf('/1/6274/5015')<0){
                this.$message(this.msg('定制功能，请联系客服开通!'));
                return;
            }
            this.$confirm(this.msg('是否将卖家宅配全部更改为新竹?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                    this.$axios.post(this.$kit.api.stock.doUpdateCompany)
                        .then((result) => {
                            if(result.status){
                                this.list();
                                this.$message(result.msg || this.msg('更新成功'));
                            }else{
                                this.$message(result.msg || this.msg('更新失败'));
                            }
                        });
                }).catch((err) => {
                // console.log(err);
            });
        },
        pageImport(){
            this.dialogImportVisible = true;//上传面单
        },
        showSelectTotal(){//总数
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            let total = 0;
            if(selectes.length > 0){
                total = selectes.length;
            }
            this.$message({message:'当前选中：'+total+"条",type:"success"});
        },
        //批量更新
        doBatchUpdate(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            if(selectes.length>5000){
                this.$message(this.msg('单次不能超过5000条数据'));
                return;
            }

            this.batchDatas = [];
            selectes.forEach(s =>  {
                this.batchDatas.push({
                    id: s.id,
                    key: s.code,
                    value: null,
                    label: null,
                    result: null
                });
            });
            this.batchUpdateVisible = true;
        },
        closeBatchUpdateDialog(type){
            if(type){
                this.list();
            }
            this.batchUpdateVisible = false;
        },
        closeEditDialog(type){
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
        hendleOpenedImport(){
            this.$nextTick(() => {
                this.$refs.stockPageImport.initForm();
            });
        },
        hendleOpenedOnlyImport(){
            this.$nextTick(() => {
                this.$refs.stockPageOnlyImport.initForm();
            });
        },
        doExport(){
            // let dataTables = this.$refs.dataTable.getTableData().visibleData;
            // let newDatas = dataTables.map(e => Object.assign({}, e));
            // newDatas.forEach(e => {
            //     e.receiver = this.replaceStr(e.receiver,1,'*');
            //     e.receiverTel = "********";
            //     e.receiverAddr = "****************";
            //     e.receiverShopCode = "******";
            //     e.receiverShopName = "******";
            // })
           
            // this.$refs.dataTable.exportData({
            //     type: 'xlsx',filename: this.msg('货运管理'),sheetName: 'Sheet1' 
            // })
            //添加导出日志记录
            // this.$axios.post(this.$kit.api.export.exportHistSave,this.datas.map(e => e.code).join(","))
            //     .then((result) => {
            //         if(result.status){
            //             console.log("导出成功");
            //         }else{
            //             console.log("无数据");
            //         }
            //     });
            // axios.request({
            //     url: this.$kit.api.export.exportHistSave+"?type=导出列表",
            //     method: 'post',
            //     data: this.datas.map(e => e.code).join(","),
            //     headers: {"Content-Type":"application/json; charset=utf-8",
            //             "X-Requested-With":"XMLHttpRequest",
            //         },
            //     withCredentials: true,
            // }).then((result) => {
            // })
            this.exportTableType = true;
            this.$refs.dataTable.openExport({
                isPrint: false
            });
        },
        doPagePicExport(){
          var selectes = this.$refs.dataTable.getCheckboxRecords();
          if (selectes.length == 0) {
            this.$message(this.msg('请选中一条数据进行操作'));
            return;
          }
          var ids = selectes.map(e => e.id).join(",");
          window.open(this.$kit.api.stock.pagePicExport + "?ids=" + ids, "_blank");
        },
        doInOut(){
            this.$tabs.open("/admin/stock/inout");
        },
        updateGoodType(item){//标记普货特货
        
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let msg = '';
            if(item == 0){
                msg = '确定将选中数据标记为普货吗?';
            }else{
                msg = '确定将选中数据标记为特货吗?';
            }
            var _this = this;
            this.$confirm(this.msg(msg), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.updateColumn.updateGoodType+'?ids='+selectes.map(e => e.id).join(",")+'&value='+item)
                .then((result) => {
                    if(result.status){
                        _this.$message(result.msg || _this.msg('更新成功'));
                        _this.list(false);
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });

        },
        selectStock(){//货物转寄
            var _this = this;
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            _this.$router.push({ name: 'stockMatch', params: {ids: selectes.map(e => e.id)}});
            //判断当前用户主账号的状态是不是预付费用户，如果是预付费用户，检查钱包状态，未欠费才可以使用转寄功能
            // this.$axios.post(_this.$kit.api.stock.checkWalletStatus)
            //     .then((result) => {
            //         if(result.status){
            //         }else{
            //             _this.$message(_this.msg('运费余额不足，已停止货运转寄服务，请及时充值！'));
            //         }
            //     });
        },
        updatePkgType(){//货运代打包
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var _this = this;
            this.$axios.post(_this.$kit.api.stock.getStockByIds,selectes.map(e => e.id).join(","))
                .then((result) => {
                    for(let i=0;i<result.data.length;i++){
                        let select = result.data[i];
                        // if(select.status > 20){
                        //     _this.$message(this.fmt('%s,已打包，不能进行操作', [select.code]));
                        //     return;
                        // }
                        if(select.isCancel == 1){
                            _this.$message(select.code + this.msg('已取消，不能进行操作'));
                            return;
                        }
                        if(select.expPkgType != 1 && select.expPkgType != 5){
                            _this.$message(select.code + this.msg('不是自行打包，不能进行待打包操作'));
                            return;
                        }
                    }

                    this.$confirm(this.msg('是否要通知货运代打包?'), this.msg('提示'), {
                        confirmButtonText: this.msg('是'),
                        cancelButtonText: this.msg('否'),
                        type: 'warning'
                    }).then(() => {

                        this.pkgTypeIds = selectes.map( o => o.id).join(",");
                        this.pkgTypeVisible = true;

                    }).catch((err) => {
                         _this.$message(_this.msg('取消操作'));
                    });
                })
        },
        updatePkgTypeNew(){//代打包登记new
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var _this = this;
            this.$axios.post(_this.$kit.api.updateColumn.updatePackType,selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        _this.$message(result.msg || _this.msg('更新成功'));
                        _this.list(false);
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                })

        },
        openedPkgType(){
            this.$nextTick(() => {
                this.$refs.pkgTypeDg.init();
            });
        },
        openedReceive(){
            this.$nextTick(() => {
                this.$refs.receiveDg.init();
            });
        },
        closeReceiveDg(){
            this.receiveVisible = false;
            this.list(false);
        },
        updateOutType(){//仓储贴单
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.msg('确定将选中数据更新为仓储贴单吗?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.updateColumn.updateOutType+'?ids='+selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        _this.$message(result.msg || _this.msg('更新成功'));
                        _this.list(false);
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                });
            }).catch((err) => {
                 _this.$message(_this.msg('取消操作'));
            });
        },
        finishPakage(){//打包完成
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var _this = this;
            this.$confirm(this.msg('确定将选中数据更新为打包完成吗?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.stock.finishPackage+'?ids='+selectes.map(e => e.id).join(","))
                .then((result) => {
                    _this.$message(result.msg || _this.msg('更新成功'));
                    _this.list(false);
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        addStockPkg(){//分包加单
            this.addPkgVisible = true;
        },
        closeAddPkgDialog(type){
            if(type){
                this.list();
            }
            this.addPkgVisible = false;
        },
        stockCheck(){//出货核对
            this.$tabs.open("/admin/stock/check");
        },
        checkCodeInStore(){//扫码入库
            this.$tabs.open("/admin/stock/checkCodeIn");
        },
        stockErrAdd(){//异常通报
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            var _this = this;
            if(selectes.length > 0){
                let ids = selectes.map(e => e.id).join(",");
                // this.$router.push({ name: 'stockError', params: {ids:ids}});
                window.localStorage.setItem("errAddIds", ids);
                this.$tabs.open("/admin/stock/error");
            }else{
                window.localStorage.setItem("errAddIds", null);
                this.$tabs.open("/admin/stock/error");
                // this.$router.push({ name: 'stockError', params: {ids:''}});
            }
        },
        workOrderAdd(){//提交工单
          var selectes = this.$refs.dataTable.getCheckboxRecords();

          if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

          var _this = this;

          this.workOrder = {
            id:null,
            typeName:null,
            level:0,
            title:null,
            description:null,
            handlerId:null,
          }
          if(selectes.length > 0){
            let orderNos = selectes.map(e => e.code).join(",");
            var orderList = [];
            for (var i = 0; i < selectes.length; i++) {
              var tmp = {
                stockId: selectes[i].id,
                stockCode: selectes[i].code,
                stockOwner: selectes[i].owner
              };
              orderList.push(tmp);
            }
            this.workOrder.orderList = orderList;
            //this.workOrder.description = orderNos + '订单编号';
          }

          this.createWorkOrderVisible = true;
        },
        scanCodeFun(){//扫码找货
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length > 1){
                this.$message(this.msg('只能选中一条数据进行操作'));
                return;
            }
            if(selectes.length > 0){
                let stock = selectes[0];
                this.scanCode = stock.code;
            }else{
                this.scanCode = null
            }
            this.actualCodeVisible = true;
        },
        handover(){//货物交接
            this.jiaojieVisible = true;
        },
        receiveStock(){//通知收件
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var _this = this;
            this.$axios.post(_this.$kit.api.stock.getStockByIds,selectes.map(e => e.id).join(","))
                .then((result) => {
                    selectes.splice(0,selectes.length);
                    selectes = result.data;
                    let msg = '';
                    for(let i=0;i<selectes.length;i++){
                        let e = selectes[i];
                        if(e.expPageStatus != 15){
                            msg += e.code+',';
                        }
                        if(e.status > 35){
                            _this.$message(e.code + _this.msg('已出货，不能通知收件'));
                            return;
                        }
                        if(e.isCancel == 1){
                            _this.$message(e.code + _this.msg('已取消，不能进行操作'));
                            return;
                        }
                    }
                    
                    if(msg){
                        _this.$confirm(_this.fmt('%s还没有打印面单进行贴单操作，是否继续执行发货让货运代为贴单?', [msg]), _this.msg('提示'), {
                            confirmButtonText: _this.msg('是'),
                            cancelButtonText: _this.msg('否'),
                            type: 'warning'
                        }).then(() => {

                            this.receiveIds = selectes.map(e => e.id).join(",");
                            this.receiveVisible = true;

                        }).catch((err) => {
                        // console.log(err);
                            _this.$message(_this.msg('取消操作'));
                        });
                    }else{

                        this.receiveIds = selectes.map(e => e.id).join(",");
                        this.receiveVisible = true;

                    }
                })
            
        },
        xuniReceiveStock(){//虚拟揽收
            var _this = this;
            let selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            _this.$confirm(_this.msg('是否将选中的订单虚拟揽收?'), _this.msg('提示'), {
                confirmButtonText: _this.msg('是'),
                cancelButtonText: _this.msg('否'),
                type: 'warning'
            }).then(() => {
                
                _this.$axios.post(_this.$kit.api.stock.xuniReceiveStock+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    _this.$message(result.msg);
                })

            }).catch((err) => {
                _this.$message(_this.msg('取消操作'));
            });
        },
        weightShare(){//重量分摊
            var _this = this;
            let selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.weightShareStock.splice(0,this.weightShareStock.length);
            this.$axios.post(_this.$kit.api.stock.getWeightByIds+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    this.weightShareStock = result.data;
                    this.$nextTick(() => {
                        this.$refs.weightShare.init();
                    });
                    this.weightShareVisible = true;
                })
        },
        closeWeightShareDialog(type){
            if(type){
                this.list(true);
            }
            this.weightShareVisible = false;
        },
        cancel(item){//取消订单
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var _this = this;

            this.$axios.post(_this.$kit.api.stock.getStockByIds,selectes.map(e => e.id).join(","))
                .then((result) => {
                    let data = result.data;
                    for(let i=0;i<data.length;i++){
                        let e = data[i];
                        if(e.expPageStatus >= 15){
                            this.$message(e.code+this.msg('面单已打印,不能取消订单'));
                            return;
                        }
                        if(e.isCancel == 1 && item == 1){
                            _this.$message(e.code+this.msg('已取消，不能进行操作'));
                            return;
                        }
                        if(e.isCancel == 0 && item == 0){
                            _this.$message(e.code+this.msg('未取消订单不能恢复'));
                            return;
                        }
                    }

                    let msg = '';
                    if(item == 1){
                        msg = this.msg('确定要取消所选订单吗?');
                    }else{
                        msg = this.msg('确定要恢复所选订单吗?');
                    }
                    this.$confirm(msg, this.msg('提示'), {
                        confirmButtonText: this.msg('确定'),
                        cancelButtonText: this.msg('取消'),
                        type: 'warning'
                    }).then(() => {
                        _this.$axios.post(_this.$kit.api.updateColumn.updateCancel+'?value='+item,selectes.map(e => e.id).join(","))
                        .then((result) => {
                            if(result.status){
                                _this.$message(result.msg || _this.msg('更新成功'));
                                _this.list(false);
                            }else{
                                _this.$message(result.msg || _this.msg('更新失败'));
                            }
                        });
                    }).catch((err) => {
                        _this.$message(_this.msg('取消操作'));
                    });
                })

        },
        allowUpload(type){//通知上传面单
            //type=0 通知上传；type=1 取消上传
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let msg = null;
            if(type == 0){
                msg = '确定要通知上传面单吗?';
            }else if(type == 1){
                msg = '确定要撤回通知吗？';
            }
            var _this = this;
            this.$confirm(this.msg(msg), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                let ids = selectes.map(e=>e.id).join(',');
                _this.$axios.post(_this.$kit.api.stock.allowUpload,
                    {
                        type:type,
                        id:ids
                    }
                    ).then((result) => {
                        _this.$message(result.msg || _this.msg('更新成功'));
                        _this.list(true);
                    });
            }).catch((err) => {
                // console.log(err);
            });
        },
        stockEditStatus(){//待备货
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var _this = this;

            this.$axios.post(_this.$kit.api.stock.getStockByIds,selectes.map(e => e.id).join(","))
                .then((result) => {
                    let data = result.data;
                    let ok = true;
                    for(let i=0;i<data.length;i++){
                        let e = data[i];
                        if(e.status != 15 && e.status != 20){
                            ok = false;
                        }
                    }
                    if(!ok){
                        this.$message(this.msg('含非备货中或待打包状态订单，恢复失败！'));
                        return;
                    }

                    this.$confirm(this.msg('确定要将选中订单改为待备货吗？'), this.msg('提示'), {
                        confirmButtonText: this.msg('确定'),
                        cancelButtonText: this.msg('取消'),
                        type: 'warning'
                    }).then(() => {
                        _this.$axios.post(_this.$kit.api.updateColumn.updateStatus,selectes.map(e => e.id).join(","))
                        .then((result) => {
                            if(result.status){
                                _this.$message(result.msg || _this.msg('更新成功'));
                                _this.list(false);
                            }else{
                                _this.$message(result.msg || _this.msg('更新失败'));
                            }
                        });
                    }).catch((err) => {
                        _this.$message(_this.msg('取消操作'));
                    });
                })
        },
        zhuanyunPkg(type){//集运转运&&包裹回寄
            var _this = this;
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$axios.post(_this.$kit.api.stock.getZhuanyunByIds+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    let data = result.data;
                    let sta = true;//不符合状态
                    let cancel = true;//取消订单
                    let store = true;//同仓库
                    let owne = true;//同归属人
                    for(let i=0;i<data.length;i++){
                        let e = data[i];
                        if(e.status != 10 && e.status != 85){
                            sta = false;
                            break;
                        }
                        if(e.isCancel == 1 && type == 1){
                            cancel = false;
                            break;
                        }
                        if(data[0].storeId != e.storeId){
                            store = false;
                        }
                        if(data[0].owner != e.owner){
                            owne = false;
                        }
                    }
                    let msg = "";
                    if(type == 0){
                        msg = "集运转运";
                    }else{
                        msg = "包裹回寄";
                    }
                    if(!sta){
                        _this.$message(_this.fmt('已入仓及已拆包状态才能进行%s',[msg]));
                        return;
                    }
                    if(!cancel){
                        _this.$message(_this.msg('已取消订单不能进行操作'));
                        return;
                    }
                    if(!store){
                        _this.$message(_this.msg('所选货物非同一仓库货物，请重新选择！'));
                        return;
                    }
                    if(!owne){
                        _this.$message(_this.msg('所选货物非同一归属人货物，请重新选择！'));
                        return;
                    }
                    if(type==0){
                        _this.zhuanyunTitle = '集运转运';
                        _this.zhuanyunType = 0;
                    }else{
                        _this.zhuanyunTitle = '包裹回寄';
                        _this.zhuanyunType = 1;
                    }
                    _this.zhuanyunVisible = true;
                })
        },
        qijianHandle(){//弃件处理
            var _this = this;
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$axios.post(_this.$kit.api.stock.getZhuanyunByIds+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    let data = result.data;
                    let sta = true;//不符合状态
                    let store = true;//同仓库
                    for(let i=0;i<data.length;i++){
                        let e = data[i];
                        if(e.status != 10 && e.status != 85){
                            sta = false;
                            break;
                        }
                        if(data[0].storeId != e.storeId){
                            store = false;
                        }
                    }
                    if(!sta){
                        _this.$message(_this.msg('已入仓及已拆包状态才能进行弃件操作'));
                        return;
                    }
                    if(!store){
                        _this.$message(_this.msg('所选货物非同一仓库货物，请重新选择！'));
                        return;
                    }
                    let total = selectes.length;
                    _this.$confirm(_this.fmt('请问是否要将这%s票货物作弃件处理？', [total]), '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        _this.qijianVisible = true;
                    }).catch(() => {
                        _this.$message({
                            type: 'info',
                            message: '取消操作'
                        });          
                    });
                })
        },
        closeZhuanyunDialog(type){
            this.zhuanyunVisible = false;
        },
        closeQijianDialog(){
            this.qijianVisible = false;
        },
        handlePrintOne(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能选中一条数据'));
                return;
            }
            this.printCtrls = [1];
            this.printDgVisible = true;
        },
        handlePrint(command){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0 && command != 'h' && command != 'm' && command != 'o' && command != 'j' && command != 'u'){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            if(command == 'a'){
                this.printCtrls = [1];
                this.printDgVisible = true;
            }else if(command == 'y'){
                this.printCtrls = [1];
                this.printDgVisible2 = true;
            }else if(command == 'z'){
                this.printCtrls = [1];
                this.printDgVisible3 = true;
            }else if(command == 'r'){
                //打印打印拣货单&自定义快递单
                // if(this.user.username != 'admin' && this.user.deptPath.indexOf('/1/8244/8245')<0 && this.user.deptPath.indexOf('/1/7276/7278')<0 
                //     && this.user.deptPath.indexOf('/1/6266/3297')<0 && this.user.deptPath.indexOf('/1/6262/2')<0 
                //     && this.user.deptPath.indexOf('/1/9053/9054')<0){
                //     this.$message(this.msg('定制功能，请联系客服开通!'));
                //     return;
                // }
                this.printCtrls = [1];
                this.printDgVisible4 = true;
            }else if(command == 'l'){
                //打印自定义快递单
                // if(this.user.username != 'admin' && this.user.deptPath.indexOf('/1/8244/8245')<0 && this.user.deptPath.indexOf('/1/7276/7278')<0 
                //     && this.user.deptPath.indexOf('/1/6266/3297')<0 && this.user.deptPath.indexOf('/1/6262/2')<0 
                //     && this.user.deptPath.indexOf('/1/9053/9054')<0){
                //     this.$message(this.msg('定制功能，请联系客服开通!'));
                //     return;
                // }
                this.printCtrls = [1];
                this.printDgVisible6 = true;
                
            }else if(command == 'v'){
                //打印中通快递单
                // if(this.user.username != 'admin' && this.user.deptPath.indexOf('/1/6273/5106')<0 && this.user.deptPath.indexOf('/1/9053/9054')<0 
                // && this.user.deptPath.indexOf('/1/6262/2')<0){
                //     this.$message(this.msg('定制功能，请联系客服开通!'));
                //     return;
                // }
                this.printCtrls = [1];
                this.printDgVisible7 = true;
            }else if(command == 'w'){
                //打印拣货单&中通快递单
                // if(this.user.username != 'admin' && this.user.deptPath.indexOf('/1/6273/5106')<0 && this.user.deptPath.indexOf('/1/9053/9054')<0
                // && this.user.deptPath.indexOf('/1/6262/2')<0){
                //     this.$message(this.msg('定制功能，请联系客服开通!'));
                //     return;
                // }
                this.printCtrls = [1];
                this.printDgVisible8 = true;
            }else if(command == 's'){
                this.printCtrls = [1];
                this.printDgVisible5 = true;
            }else if(command == 'b'){
                this.printCtrls = [0];
                // console.log(this.user.deptPath);
                if(this.user.deptPath.indexOf('/1/6262/')<0 && !this.hasPermission('stock:printMiandan')){
                    this.$message(this.msg('抱歉，会员权限不够，请升级会员!'));
                    return;
                }

                //非会员只能单张打印
                if(this.user.vip == 0 && selectes.length > 1){
                    this.$message(this.msg('非会员，只能单张打印！'));
                    return;
                }
                if(selectes.length > 100){
                    this.$message(this.msg('打印面单单次不能大于一百条'));
                    return;
                }
                this.printDgVisible = true;

            }else if(command == 'p'){
                
                if(this.user.deptPath.indexOf('/1/6262/')<0 && !this.hasPermission('stock:printMiandan')){
                    this.$message(this.msg('抱歉，会员权限不够，请升级会员!'));
                    return;
                }

                this.printCtrls = [0];
                this.printXPZPDgVisible = true;
            }else if(command == 'c'){
                this.printCtrls = [3];
                this.printDgVisible = true;
            }else if(command == 'd'){
                this.printCtrls = [1,0];

                if(this.user.deptPath.indexOf('/1/6262/')<0 && !this.hasPermission('stock:printMiandan')){
                    this.$message(this.msg('抱歉，会员权限不够，请升级会员!'));
                    return;
                }

                //非会员只能单张打印
                if(this.user.vip == 0 && selectes.length > 1){
                    this.$message(this.msg('非会员，只能单张打印！'));
                    return;
                }
                this.printDgVisible = true;
            }else if(command == 'e'){
                this.printCtrls = [1,3];
                this.printDgVisible = true;
            }else if(command == 'f'){

                if(this.user.deptPath.indexOf('/1/6262/')<0 && !this.hasPermission('stock:printMiandan')){
                    this.$message(this.msg('抱歉，会员权限不够，请升级会员!'));
                    return;
                }

                this.printCtrls = [0,3];
                this.printDgVisible = true;
            }else if(command == 'g'){
                
                if(this.user.deptPath.indexOf('/1/6262/')<0 && !this.hasPermission('stock:printMiandan')){
                    this.$message(this.msg('抱歉，会员权限不够，请升级会员!'));
                    return;
                }

                this.printCtrls = [0,1,3];
                //非会员只能单张打印
                if(this.user.vip == 0 && selectes.length > 1){
                    this.$message(this.msg('非会员，只能单张打印！'));
                    return;
                }
                this.printDgVisible = true;
            }else if(command == 'h'){
                if(selectes && selectes.length > 0){
                    this.pkgTicketIds = selectes.map(s=>s.id).join(",");
                    this.$axios.post(this.$kit.api.stock.getStockByIds,selectes.map(e => e.id).join(","))
                    .then((result) => {
                        let data = result.data;
                        for(let i=0;i<data.length;i++){
                            let e = data[i];
                            if(e.status != 0 && e.status != 23){
                                this.$message(e.code + this.msg('不是待入仓或已打单状态'));
                                return;
                            }
                        }
                        this.printPkgVisiable = true;
                    })
                }else{
                    this.pkgTicketIds = null;
                    this.printPkgVisiable = true;
                }
            }else if(command == 'j'){//自动打印实名发票单
                this.printPkgVisiableAuto = true;
            }else if(command == 'u'){//打印发票
                this.printPkgVisiableAutoP = true;
            }else if(command == 'm' || command == 'o'){
                this.printRealType = command;
                if(selectes && selectes.length > 0){
                    this.ids = selectes.map(s=>s.id).join(",");
                    this.$axios.post(this.$kit.api.stock.getStockByIds,selectes.map(e => e.id).join(","))
                    .then((result) => {
                        let data = result.data;
                        for(let i=0;i<data.length;i++){
                            let e = data[i];
                            if(e.status != 0 && e.status != 23){
                                this.$message(e.code+this.msg('不是待入仓或已打单状态'));
                                return;
                            }
                        }
                        this.printRealVisiable = true;
                    })
                }else{
                    this.ids = null;
                    this.printRealVisiable = true;
                }
            }else if(command ==  'n'){
                
                this.ids = selectes.map(s=>s.id).join(",");
                this.printTagVisible = true;
                this.printTagVisible1 = true;

            }else if(command ==  't'){
                
                this.ids = selectes.map(s=>s.id).join(",");
                this.printTagVisible2 = true;

            }else if(command ==  'q'){
                
                this.ids = selectes.map(s=>s.id).join(",");
                this.printKuTagVisible = true;

            }else if(command ==  'k'){
                this.ids = selectes.map(s=>s.id).join(",");
                this.printBagVisible = true;
                this.printBagVisible1 = true;
            }else if(command ==  'i'){
                this.ids = selectes.map(s=>s.id).join(",");
                this.delcarePrintVisiable = true;
                this.delcarePrintVisiable1 = true;
            }

        },
        closePrintDialog(type){
            this.printDgVisible = false;
            if(type){
                this.list(false);
            }
        },
        closePrintDialog2(type){
            this.printDgVisible2 = false;
            if(type){
                this.list(false);
            }
        },
        closePrintDialog3(type){
            this.printDgVisible3 = false;
            if(type){
                this.list(false);
            }
        },
        closePrintDialog4(type){
            this.printDgVisible4 = false;
            if(type){
                this.list(false);
            }
        },
        closePrintDialog6(type){
            this.printDgVisible6 = false;
            if(type){
                this.list(false);
            }
        },
        closePrintDialog7(type){
            this.printDgVisible7 = false;
            if(type){
                this.list(false);
            }
        },
        closePrintDialog8(type){
            this.printDgVisible8 = false;
            if(type){
                this.list(false);
            }
        },
        closePrintDialog5(type){
            this.printDgVisible5 = false;
            if(type){
                this.list(false);
            }
        },
        closePrintXPZPDialog(type){
            this.printXPZPDgVisible = false;
            if(type){
                this.list(false);
            }
        },
        openedPrint(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printDg.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedPrint1(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printDg1.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedPrint2(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printDg2.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedPrint3(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printDg3.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedPrint4(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printDg4.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedPrint6(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printDg6.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedPrint7(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printDg7.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedPrint8(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printDg8.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedPrint5(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printDg5.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedXPZPPrint(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.printXPZPDg.preparePrint(selectes.map(s=>s.id), this.printCtrls);
            });
        },
        openedZhuanyun(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.zhuanyun.init(selectes.map(s=>s.id),this.zhuanyunType);
            });
        },
        openedQijian(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.qijianRef.init(selectes.map(s=>s.id),this.zhuanyunType);
            });
        },
        openedPkgCode(){//打开包号录入界面
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.pkgCodeDg.init(selectes.map(s=>s.id),selectes.length);
            });
        },
        pkgCodeClose(){
            this.pkgCodeVisible = false;
            this.list(false);
        },
        openedPackage(){//打开扫码打包
            this.$nextTick(() => {
                this.$refs.packageDg.init();
            });
        },
        openedFindPrint(){//转寄找货单
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$nextTick(() => {
                this.$refs.printFindDg.init(selectes.map(s=>s.id));
            });
        },
        openedPrintPkgCode(){//转运找货单
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$nextTick(() => {
                this.$refs.printPkgCodeDg.init(selectes.map(s=>s.id));
            });
        },
        handleInsertPkgCode(command){//打包
            if(command == 'a'){
                this.openPkgCode();//包号录入
            }else if(command == 'b'){
                this.autoPkgCode();//扫码打包
            }
        },
        handleImport(command){//导入
            if(command == 'a'){
                this.$tabs.open ("/admin/stock/import");//导入EXCEL
            }else if(command == 'b'){
                this.dialogOnlyImportVisible = true;//上传PDF
            }
        },
        handleExport(command){//导出
            var params = this.$kit.rebuildQuery(this.query);
            //获取查询的数，并且过滤掉空数据，
            let queries = []; 
            this.query.queries.forEach(e => {
                    let values = e.dvalues;
                    let value = e.dvalue;
                    let q = {
                        dvalue: e.dvalue,
                        tableName: e.tableName,
                        colName: e.colName,
                        op: e.op,
                        type: e.type,
                        tableAlias: e.tableAlias
                    }

                    if(q.type == 0 && q.op == 6){
                        if(value){
                            value = value.split(/[\s,]+/);
                            q.dvalue = value.join(",");
                            queries.push(q);
                        }
                    }else{
                        if(q.type == 3 || q.op == 6){
                            if(values && values.length>0){
                                q.dvalue = values.join(",");
                                queries.push(q);
                            }
                        }else if(q.dvalue || q.dvalue === 0){
                            queries.push(q);
                        }
                    }
                });
            params['queries'] = queries;
            
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            let ids = '';
            if(selectes.length > 0){
                ids = selectes.map(s=>s.id).join(",");
            }

            var _this = this;
            if (command == 'a') {
              _this.doExport();
            }else if(command == 'b' || command == 'c' || command == 'd'){//导出清关资料
              //执行正常操作
              _this.exportVisible = true;
              _this.exportType = command;
              _this.exportParams = params;
              _this.exportIds = ids;
              _this.expcomType = command;
            }else if(command == 'e'){//提货表
                //获取选中数据所对应的袋号
                if(ids){
                    this.$axios.post(this.$kit.api.stock.getPkgCodeByIds, ids)
                    .then((result) => {
                        let codes = result.data;
                        if(codes && codes.length > 0){
                            codes.forEach(code => {
                                this.tihuoPkgCodes += code+"\n";
                            })
                        }
                        this.exportVisiable = true;
                    })
                }else{
                    this.exportVisiable = true;
                }
            }else if(command == 'f'){//建单资料
              //判断是否设置过个人密码
              //执行正常操作
              _this.exportTableType = false;
              _this.$refs.dataTable.openExport({isPrint: false})
              // axios.request({
              //   url: _this.$kit.api.export.exportHistSave + "?type=导出建单资料",
              //   method: 'post',
              //   data: _this.datas.map(e => e.code).join(","),
              //   headers: {
              //     "Content-Type": "application/json; charset=utf-8",
              //     "X-Requested-With": "XMLHttpRequest",
              //   },
              //   withCredentials: true,
              // }).then((result) => {
              // })
              // this.openWindowWithPost(
              //     this.$kit.api.export.exportJiandan,
              //     {
              //         ids:ids,
              //     }
              // );
            }else if(command == 'g'){//出货统计
                this.exportTjVisible = true;
            }else if(command == 'h'){//导出详情
                this.openWindowWithPost(
                    this.$kit.api.export.exportDetail,
                    {
                        ids:ids,
                        history:this.query.history
                    }
                );
                // axios.request({
                //   url: _this.$kit.api.export.exportHistSave + "?type=导出详情",
                //   method: 'post',
                //   data: _this.datas.map(e => e.code).join(","),
                //   headers: {
                //     "Content-Type": "application/json; charset=utf-8",
                //     "X-Requested-With": "XMLHttpRequest",
                //   },
                //   withCredentials: true,
                // }).then((result) => {
                // })
            }else if(command == 'j'){//导出物流记录
                this.openWindowWithPost(
                    this.$kit.api.export.exportStatus,
                    {
                        ids:ids,
                        history:this.query.history
                    }
                );
            }else if(command == 'k'){//导出采购详情
                this.openWindowWithPost(
                    this.$kit.api.export.exportBuyDetail,
                    {
                        ids:ids,
                        history:this.query.history
                    }
                );
                // axios.request({
                //   url: _this.$kit.api.export.exportHistSave + "?type=导出采购详情",
                //   method: 'post',
                //   data: _this.datas.map(e => e.code).join(","),
                //   headers: {
                //     "Content-Type": "application/json; charset=utf-8",
                //     "X-Requested-With": "XMLHttpRequest",
                //   },
                //   withCredentials: true,
                // }).then((result) => {
                // })
            }else if(command == 'm'){//导出转寄成本
                this.openWindowWithPost(
                    this.$kit.api.export.exportResendCost,
                    {
                        ids:ids,
                        history:this.query.history
                    }
                );
                // axios.request({
                //   url: _this.$kit.api.export.exportHistSave + "?type=导出转寄成本",
                //   method: 'post',
                //   data: _this.datas.map(e => e.code).join(","),
                //   headers: {
                //     "Content-Type": "application/json; charset=utf-8",
                //     "X-Requested-With": "XMLHttpRequest",
                //   },
                //   withCredentials: true,
                // }).then((result) => {
                // })
            }else if(command == 'n'){//导出面单图片
                this.doPagePicExport();
            }
        },
        doExportJDList(){//导出建单资料，后台导出方法


        },
        exportDg(data){
            var _this = this;
            this.exportVisible = false;
            if(this.exportType == 'b'){//导出清关资料
              _this.$kit.strongAuth(function () {
                _this.openWindowWithPost(
                    _this.$kit.api.export.exportExp,
                    {
                      ids: _this.exportIds,
                      isMatch: data.isMatch,
                      expBatchs: data.expBatchs
                    }
                );
              }, _this);
                //  window.open(this.$kit.api.export.exportExp+"?data="+encodeURIComponent(JSON.stringify(this.exportParams))+"&ids="+this.exportIds+"&isMatch="+(data.isMatch?1:0),"_blank")
            }else if(this.exportType == 'c'){//导出航空资料
              _this.$kit.strongAuth(function () {
                _this.openWindowWithPost(
                    _this.$kit.api.export.exportHangkong,
                    {
                        ids:_this.exportIds,
                        isMatch:data.isMatch,
                        expBatchs:data.expBatchs
                    }
                );
              }, _this);
                // window.open(this.$kit.api.export.exportHangkong+"?data="+encodeURIComponent(JSON.stringify(this.exportParams))+"&ids="+this.exportIds+"&isMatch="+(data.isMatch?1:0),"_blank")
            }else if(this.exportType == 'd'){//导出黑猫派件资料
              _this.$kit.strongAuth(function () {
                _this.openWindowWithPost(
                    _this.$kit.api.export.exportHeimao,
                    {
                      ids: _this.exportIds,
                      isMatch: data.isMatch,
                      expBatchs: data.expBatchs,
                      expCompany: data.expCompany
                    }
                );
              }, _this);
                // window.open(this.$kit.api.export.exportHeimao+"?data="+encodeURIComponent(JSON.stringify(this.exportParams))+"&ids="+this.exportIds+"&isMatch="+(data.isMatch?1:0),"_blank")
            }
        },
        openWindowWithPost(url, data) {
            var form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = url;
            form.style.display = "none";

            for (var key in data) {
                var input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = data[key];
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        },
        openExportDg(){
            this.$nextTick(() => {
                this.$refs.exportDg.init();
            });
        },
        openedExportTypeSort(){
            this.$nextTick(() => {
                this.$refs.exportRef.init();
            });
        },
        closeTihuoDg(data){
            this.exportVisiable = false;
            this.tihuoPkgCodes = '';
            let query = {
                codes:data.codes
            }
            var params = this.$kit.rebuildQuery(query);
            // window.location.href= this.$kit.api.export.exportTihuo+"?data="+encodeURIComponent(JSON.stringify(params));
            window.open(this.$kit.api.export.exportTihuo+"?data="+encodeURIComponent(JSON.stringify(params)), "_blank");
        },
        closePrintPkgDg(){
            this.printPkgVisiable = false;
        },
        closePrintPkgDgAuto(){
            this.printPkgVisiableAuto = false;
        },
        closePrintPkgDgAutoP(){
            this.printPkgVisiableAutoP = false;
        },
        closePrintRealDg(){
            this.printRealVisiable = false;
        },
        closePrintKuTagDg(){
            this.printKuTagVisible = false;
        },
        transitTypeDg(){
            this.transitTypeVisible = false;
        },
        closePkgTypeDg(){
            this.pkgTypeVisible = false;
            this.list(false);
        },
        handleFindTicket(command){//快速找货
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(command == 'a'){
                //转寄找货单
                if(selectes.length==0){
                    this.$message(this.msg('请选中一条数据进行操作'));
                    return;
                }
                this.printFindVisible = true;
            }else if(command == 'b'){
                // this.printPkgCodeVisible = true;//旧版--打印
                let ids = '';
                if(selectes.length > 0){
                    ids = selectes.map(e => e.id).join(",")
                }
                this.openWindowWithPost(//转运找货单--新版--导出
                    this.$kit.api.export.zhaohuoTicket,
                    {
                        ids:ids,
                    }
                );
            }else if(command == 'c'){
                this.printPkgPageVisible = true;//按贴单包裹
            }
        },
        openedPrintPkgPage(){
            this.$nextTick(() => {
                this.$refs.printPkgPageDg.init();
            });
        },
        handleAllowUpload(command){//通知上传面单
            if(command == 'a'){
                this.allowUpload(0);
            }else if(command == 'b'){
                this.allowUpload(1);
            }
        },
        handleCancel(command){//取消订单
            if(command == 'a'){
                this.cancel(1);
            }else if(command == 'b'){
                this.cancel(0);
            }
        },
        kuaidi(){
            this.kuaidiVisible = true;
        },
        updateWeightTime(){//出货时间
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = selectes.map(e => e.id);
            this.weightTimeIds = ids;
            this.weightTimeVisible = true;
        },
        handleAdd(command){
            if(command == 'a'){
                this.doAdd(4);
            }else if(command == 'b'){
                this.doAdd(1);
            }else if(command == 'c'){
                this.doAdd(2);
            }else if(command == 'd'){
                // this.doAdd(3);
                this.$router.push({ name: 'stock-add-store'});
            }
        },
        handleGoodType(command){
            if(command == 'a'){
                this.updateGoodType(1);
            }else if(command == 'b'){
                this.updateGoodType(0);
            }
        },
        handlePkgType(command){
            if(command == 'a'){
                this.updatePkgType();
            }else if(command == 'b'){
                this.packVisible = true;
            }else if(command == 'c'){
                this.updatePkgTypeNew();
            }
        },
        zhuanyunCommon(command){
            if(command == 'a'){
                this.zhuanyunPkg(0);
            }else if(command == 'b'){
                this.zhuanyunPkg(1);
            }else if(command == 'c'){
                this.qijianHandle();
            }else if(command == 'd'){
                this.updateRemark1();
            }else if(command == 'e'){
                var selectes = this.$refs.dataTable.getCheckboxRecords();
                if(selectes.length==0){
                    this.$message(this.msg('请选中一条数据进行操作'));
                    return;
                }
                this.$axios.post(this.$kit.api.stock.getStockByIds,selectes.map(e => e.id).join(","))
                    .then((result) => {
                        let data = result.data;
                        for(let i=0;i<data.length;i++){
                            let e = data[i];
                            if(e.status != 30){
                                this.$message(e.code+this.msg('非待出仓订单，不能通知出货'));
                                return;
                            }
                        }
                        this.$axios.post(this.$kit.api.stock.noticeSend,selectes.map(e => e.id).join(","))
                        .then((res) => {
                            if(res.data){
                                this.$message(res.msg || this.msg('更新成功'));
                            }else{
                                this.$message(res.msg || this.msg('更新失败'));
                            }
                        })
                    })
            }
        },
        updateRemark1(){//集运收费
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能选择一条数据'));
                return;
            }
            this.chargeId = selectes[0].id;
            this.zhuanyunChargeVisible = true;
        },
        handleOfflineCreate(command){//建单操作
            if(command == 'a'){
                this.doOfflineCreate();//快速建单
            }else if(command == 'b'){
                this.finishOfflineCreate();//完成建单
            }else if(command == 'c'){
                // this.$message("正在开发，敬请期待")//重新建单
                this.getOfflineExpCode();
            }else if(command == 'd'){
                this.getOfflinePage();//同步线下面单
            }else if(command == 'e'){
                this.expCodeVisible = true;//录查询码
            }
        },
        //同步订单
        doSyncOrder(){
            this.syncOrderVisible = true;
        },
        doTrace(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能查询一条数据'));
                return;
            }
            this.traceVisible = true;
        },
        doTrack(){//货态同步
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>500){
                this.$message(this.msg('快递同步每次只能同步500条数据'));
                return;
            }
            this.$confirm(this.msg('货态同步耗时较长，请勿短时间多次重复操作，是否进行同步操作?'), this.msg('提示'), {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$kit.api.stock.doTrack, selectes.map(e => e.id).join(","))
                    .then((result) => {
                        if(result.data){
                            this.$message(result.msg || this.msg('正在同步'));
                        }else{
                            this.$message(this.msg('同步失败'));
                        }
                    })
            }).catch((err) => {
                // console.log(err);
            })
        },
        doTrackExpress(){//快递同步
            var _this = this;
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>100){
                this.$message(this.msg('快递同步每次只能同步100条数据'));
                return;
            }
            this.$axios.post(this.$kit.api.stock.doTrackExpress, selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        let orders = [];
                        data.forEach(e => {
                            let order = {
                                id:e.purchaseCode,
                                type:e.purchasePlatform
                            }
                            orders.push(order);
                        })
                        if(orders.length > 0){
                            window.OKK_Orders.queryTrackingNumber(orders, function(result){
                                // console.log(result);
                                if(result && result.length > 0){
                                    let param = [];
                                    for (let i = 0; i < data.length; i++) {
                                        const e = data[i];
                                        let expCode = null;
                                        for (let j = 0; j < result.length; j++) {
                                            if(result[j] && result[j][0]){
                                                const f = result[j][0];
                                                let purCode = f.id;
                                                if(e.purchaseCode == purCode){
                                                    expCode = f.mailNo;
                                                }
                                            }
                                        }
                                        if(expCode){
                                            let para = {
                                                id:e.id,
                                                innerExpCode:expCode
                                            };
                                            param.push(para);
                                        }
                                    }
                                    if(param.length > 0){
                                        _this.$axios.post(_this.$kit.api.stock.doSaveInnerExpCode, param)
                                            .then((result) => {
                                                if(result.status){
                                                    _this.$message(_this.msg('同步完成'));
                                                }
                                            })
                                    }else{
                                        _this.$message(_this.msg('同步完成'));
                                    }
                                }
                            })
                        }
                    }else{
                        this.$message(this.msg('不需要同步'));
                    }
                })
        },
        openTrace(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                let row = selectes[0];
                this.$refs.traceRef.showTracks(row.id, row.queryHistory);
            });
        },
        doOfflineCreate(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>100){
                this.$message(this.msg('单次最多选择100条数据'));
                return;
            }

            this.$confirm(this.msg('确定要执行建单操作?'), this.msg('提示'), {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$kit.api.stock.offlineCreate, selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        
                        let html = "<div style='max-height:500px; overflow:auto;'>";

                        result.data.forEach(e => {
                            html += "<p>单号："+e.code+" ("+e.id+"): "+e.errMsg+"</p>";
                        })

                        html+="</div>";

                        this.$message({
                            dangerouslyUseHTMLString: true,
                            message: html
                        });

                        // this.$message(result.msg || this.msg('执行成功'));
                        this.list();

                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            })

        },
        getOfflineExpCode(){//重新建单，把已建单但是未同步到查询码的订单重新同步建单信息
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>100){
                this.$message(this.msg('每次最多选择100条数据'));
                return;
            }
            this.$axios.post(this.$kit.api.stock.getOfflineExpCode, selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        this.$message(result.msg);
                    }else{
                        this.$message(result.msg || this.msg('单号不存在'));
                }
            });
        },
        getOfflinePage(){//同步线下面单
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>100){
                this.$message(this.msg('每次最多选择100条数据'));
                return;
            }
            this.$axios.post(this.$kit.api.stock.getOfflinePage, selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        this.$message(result.msg);
                    }else{
                        this.$message(result.msg || this.msg('单号不存在'));
                }
            });
        },
        openPkgCode(){//包号录入
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var _this = this;
            this.$axios.post(_this.$kit.api.stock.getStockByIds,selectes.map(e => e.id).join(","))
                .then((result) => {
                    for(let i=0;i<result.data.length;i++){
                        let select = result.data[i];
                        if(select.isCancel == 1){
                            _this.$message(select.code + this.msg('已取消订单不能打包，请移除！'));
                            return;
                        }
                    }
                    this.pkgCodeVisible = true;
                })
        },
        autoPkgCode(){//扫码打包
            this.packageVisible = true;
        },
        finishOfflineCreate(){//完成建单
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var _this = this;
            this.$confirm(this.msg("确定要将选中的数据改为线下已建单吗？"), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.updateColumn.updateOutTypeFinish+'?ids='+selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        _this.$message(result.msg || _this.msg('更新成功'));
                        _this.list(false);
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        showAdvSearch(){
            this.showQuery = !this.showQuery;
        },
        callMethod(name, param){
            if(param){
                this[name](param)
            }else{
                this[name]();
            }
        },
        //预览详情
        stockDetail(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            // if(selectes.length > 10){
            //     this.$message({
            //         message:this.msg('抱歉，请控制选择10个以内的订单打开详情！打开订单详情，需要自身电脑有足够的内存支持，如果你电脑内存不支持打开过多订单详情时会导致你的网页崩溃；我们在持续优化中，给你带来的不便深感抱歉！大批量打开订单详情你还可使用货物采购功能打开！'),
            //         type:'error'
            //     })
            //     return;
            // }

            let ids = selectes.map(e => e.id).join(",");
            window.localStorage.setItem("viewDetailIds", ids);
            this.$tabs.open("/admin/stock/stock-detail");
        },
        openDetail(id){
            window.localStorage.setItem("viewDetailIds", id);
            this.$tabs.open("/admin/stock/stock-detail");
        },
        stockSplit(){//详情分拆
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let ids = selectes.map(e => e.id).join(",");
            window.localStorage.setItem("splitStockIds", ids);
            this.$tabs.open("/admin/stock/stock-split");
        },
        stockDetailEdit(){//详情修改
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let ids = selectes.map(e => e.id).join(",");
            window.localStorage.setItem("detailEditIds", ids);
            this.$tabs.open("/admin/stock/detail-edit");
            // this.$router.push("/admin/stock/detail-edit?ids="+ids);
        },
        stockDetailBuy(){//货物采购
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let ids = selectes.map(e => e.id).join(",");
            window.localStorage.setItem("viewDetailIdsNew", ids);
            this.$tabs.open("/admin/stock/stock-buy");
        },
        stockQtyEdit(){//入库出库
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let ids = selectes.map(e => e.id).join(",");
            window.localStorage.setItem("qtyEditIds", ids);
            this.$tabs.open("/admin/stock/qty-edit");
            // this.$router.push("/admin/stock/qty-edit?ids="+ids);
        },
        finance(command){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(command == 'a'){//更新收费方式
                this.transitIds = selectes.map(e => e.id).join(",");
                this.transitTypeVisible = true;
            }else if(command == 'b'){//运费核算
                let ids = selectes.map(e => e.id).join(",");
                window.localStorage.setItem("stockIds1", ids);
                // this.$tabs.open("/admin/freight");
                this.$router.push("/admin/freight?len="+selectes.length);
            }else if(command == 'c'){//回款核算
                let ids = selectes.map(e => e.id).join(",");
                window.localStorage.setItem("proxyStockIds", ids);
                this.$router.push("/admin/proxy?len="+selectes.length);
            }else if(command == 'd'){//理赔核算
                let ids = selectes.map(e => e.id).join(",");
                window.localStorage.setItem("lipeiStockIds", ids);
                this.$router.push("/admin/lipei?len="+selectes.length);
            }else if(command == 'e'){//客诉件结算
                let ids = selectes.map(e => e.id).join(",");
                window.localStorage.setItem("kesuStockIds", ids);
                this.$router.push("/admin/kesu?len="+selectes.length);
            }
        },
        doBatchSendOrderNew(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length > 500){
                this.$message(this.msg('单次一键发货的数量不能超过五百'));
                return;
            }
            this.$confirm(this.msg('确定要将选中的订单全部发货吗'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$kit.api.stock.batchSendOrderNew, selectes.map(e => e.id).join(","))
                    .then((result) => {
                        if(result.status){
                            this.$message(result.msg || this.msg('更新成功'));
                        }else{
                            this.$message(result.msg || this.msg('更新失败'));
                        }
                    });
            }).catch((err) => {
                // console.log(err);
            });
        },
        doBatchSendOrder(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length > 500){
                this.$message(this.msg('单次一键发货的数量不能超过五百'));
                return;
            }

            this.$confirm(this.msg('确定要将选中的订单全部发货吗'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                // this.$axios.post(this.$kit.api.stock.batchSendOrder, selectes.map(e => e.id).join(","))
                //     .then((result) => {
                //         if(result.status){
                //             this.$message(result.msg || this.msg('更新成功'));
                //         }else{
                //             this.$message(result.msg || this.msg('更新失败'));
                //         }
                //     });
                axios.request({
                    url: this.$kit.api.stock.batchSendOrder, 
                    method: 'post',
                    data: selectes.map(e => e.id).join(","),
                    headers: {"Content-Type":"application/json; charset=utf-8",
                        "X-Requested-With":"XMLHttpRequest",
                    },
                    withCredentials: true,
                }).then((result) => {
                    if(result.status){
                        this.$message(result.msg || this.msg('更新成功'));
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        doBatchSendOrder1(){//空白面单
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$confirm(this.msg('本操作将无条件删除面单，是否确认删除？'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                axios.request({
                    url: this.$kit.api.stock.batchSendOrder1,
                    method: 'post',
                    data: selectes.map(e => e.id).join(","),
                    headers: {"Content-Type":"application/json; charset=utf-8",
                        "X-Requested-With":"XMLHttpRequest",
                    },
                    withCredentials: true,
                }).then((result) => {
                    if(result.status){
                        this.$message(result.msg || this.msg('更新成功'));
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        zhineng(command){//智能理货
            if(command == 'a'){
                this.expSortVisible = true;
            }else if(command == 'b'){
                this.expStockVisible = true;
                // this.$message("正在开发，敬请期待")
            }
        },
        stockCodeCheck(){//条码校对
            this.$tabs.open("/admin/code-check");
        },
        openedExpSort(){
            this.$nextTick(() => {
                this.$refs.expSortRef.init();
            });
        },
        openedExpCode(){
            this.$nextTick(() => {
                this.$refs.expCodeRef.init();
            });
        },
        openedActualCode(){
            this.$nextTick(() => {
                this.$refs.actualCodeRef.init();
            });
        },
        openedJiaojie(){
            this.$nextTick(() => {
                this.$refs.jiaojieRef.init();
            });
        },
        openedPack(){
            this.$nextTick(() => {
                this.$refs.packRef.init();
            });
        },
        openedKuaidi(){
            this.$nextTick(() => {
                this.$refs.kuaidiRef.init();
            });
        },
        openedCharge(){
            this.$nextTick(() => {
                this.$refs.chargeRef.init();
            });
        },
        openedExpStockSort(){
            this.$nextTick(() => {
                this.$refs.expStockRef.init();
            });
        },
        expSortDg(){
            this.expSortVisible = false;
        },
        expCodeDg(){
            this.expCodeVisible = false;
        },
        actualCodeDg(){
            this.actualCodeVisible = false;
        },
        jiaojieDg(){
            this.jiaojieVisible = false;
        },
        packDg(){
            this.packVisible = false;
        },
        kuaidiDg(){
            this.kuaidiVisible = false;
        },
        chargeDg(){
            this.zhuanyunChargeVisible = false;
        },
        expStockDg(){
            this.expStockVisible = false;
        },
        openedExpTjSort(){
            this.$nextTick(() => {
                this.$refs.exportTjRef.init();
            });
        },
        openedWeightTime(){
            this.$nextTick(() => {
                this.$refs.weightTimeRef.init();
            });
        },
        openPrintPkgDg(){
            this.$nextTick(() => {
                this.$refs.printPkgDg.init();
            });
        },
        openPrintPkgDgAuto(){
            this.$nextTick(() => {
                this.$refs.printPkgDgAuto.init();
            });
        },
        openPrintPkgDgAutoP(){
            this.$nextTick(() => {
                this.$refs.printPkgDgAutoP.init();
            });
        },
        openPrintRealDg(){
            this.$nextTick(() => {
                this.$refs.printRealDg.init(this.printRealType);
            });
        },
        openPrintKuTagDg(){
            this.$nextTick(() => {
                this.$refs.printKuTagDg.init();
            });
        },
        openPrintTagDg2(){
            this.$nextTick(() => {
                this.$refs.printTagDg2.init();
            });
        },
        openedTransitType(){
            this.$nextTick(() => {
                this.$refs.transitTypeRef.init();
            });
        },
        exportTjDg(){
            this.exportTjVisible = false;
        },
        weightTimeDg(){
            this.weightTimeIds = null;
            this.weightTimeVisible = false;
        },
        refreshCols(visible, col){
            this.$refs.dataTable.refreshColumn()
            // console.log(visible, col);
        },
        ctrlBoxHeightChanged(height){
            this.emptyFixedBoxHeight = height;
        },
        closeWorkOrderAddDialog(type){  //添加弹窗关闭后的回调事件
          this.createWorkOrderVisible = false;
          // if (type) { //提交成功后清除选中行
          //   var table = this.$refs.dataTable;
          //   table.clearCheckboxRow();
          // }
        },
        syncAllShoplineOrders() {
          var _this = this;
          _this.$axios.get(_this.$kit.api.shopline.syncAllOrders + "?password=009mgipgant")
              .then((result) => {
                if (result.status) {
                  _this.$message.success(_this.msg(result.msg));
                  _this.list();
                } else {
                  _this.$message.error(result.msg);
                }
              });
        }
    }
    
}
</script>

<style scoped lang="scss">
.view-group h5{margin-top:8px; margin-bottom:8px; border-bottom: 1px solid #dcdfe6;}
.view-group >>> .el-link{display:inline-block; padding: 5px 15px;}
.view-cell{cursor:pointer; display:inline-block; margin-right:5px;}
.detail-cell{cursor:pointer; display:inline-block; margin-right:5px;}
.status-cell{cursor:pointer; display:inline-block; margin-right:5px;}
.remove-cell{cursor:pointer; display:inline-block; color:red;}
.page-box{overflow-y:auto;}
.ctrl-box{position: absolute;z-index:99;width:100%;}
.empty-fixed-box{height:57.5px;}
</style>
