<template>
    <div>
    <div v-if="imgId" style="text-align:center;">
        <el-image :src="imgView+imgId"></el-image>
    </div>
    <div style="text-align:center; margin-top: 15px;">
        <el-button type="success" size="mini" @click="downloadPdf">
            <i class="iconfont icon-PDFtubiao" style="font-size:14px; margin-right:4px;"></i>
            <span>{{msg('下载PDF格式的面单')}}</span>
        </el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
    </div>
</template>

<script>
export default {
    name: 'StockPageView',
    data(){
        return {
            imgView : this.$kit.api.imgView
        }
    },
    props: {
        imgId : Number
    }, 
    methods: {
        downloadPdf(){
            window.location.href= this.$kit.api.imgView+this.imgId+"&type=1";
        }
    }
}
</script>

<style lang="scss" scoped>


</style>