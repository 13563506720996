<!-- 录查询码 -->
<template>
<el-form label-width="120px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('订单编号或包号')">
                <el-input v-model="code" ref="codeRef" placeholder="请输入订单编号或包号" size="small" @keyup.native="autoSelectCode">
                </el-input>
            </el-form-item>
            <el-form-item :label="msg('出货查询码')">
                <el-input v-model="expCode" ref="expCodeRef" placeholder="请输入出货查询码" size="small" @keyup.native="autoSelectExpCode">
                </el-input>
            </el-form-item>
        </el-col>
    </el-row>
  
    <div style="text-align:center;margin-top:10px;">
        <el-button  size="mini" type="danger" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>

    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>

</el-form> 
</template>

<script>
import didi from "../../assets/media/warning.wav";//滴滴音
import noCode from "../../assets/media/NoRecord.mp3";//记录不存在
import ok from "../../assets/media/ok.mp3";//成功
import error from "../../assets/media/error.mp3";//错误
export default {
  name: 'ExpCodeInput',
  data(){
    return{
        code:null,
        expCode:null,
        inputing: false,
        audioUrl:null,
    }
    
  }, 
  props: {
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
          this.code = null;
          this.expCode = null,
          this.$refs['codeRef'].focus();
      },
      autoSelectCode(e){

        if(e.keyCode == 13){
            this.expCodeSend();
        }
    },
    expCodeSend(){
        if(!this.code){
            this.$message(this.msg('请录入订单编号或包号'));
            return;
        }
        this.$axios.get(this.$kit.api.stock.checkCode+"?code="+this.code,)
            .then((result) =>{
                if(result.status){
                    this.audioUrl = didi;
                    this.playAudio();
                    this.$refs['expCodeRef'].focus();
                }else{
                    this.audioUrl = noCode;
                    this.playAudio();
                    this.code = null;
                    this.$refs['codeRef'].focus();
                }
               
            });
    },
    autoSelectExpCode(e){
        if(e.keyCode == 13){
            this.expCodeSave();
        }
    },
    expCodeSave(){
        if(!this.code){
            this.$message(this.msg('请录入订单编号'));
            return;
        }
        if(!this.expCode){
            this.$message(this.msg('请录入出货查询码'));
            return;
        }
        if(this.code == this.expCode){
            this.audioUrl = error;
            this.playAudio();
            this.code = null;
            this.expCode = null;
            this.$refs['codeRef'].focus();
            return;
        }
        this.$axios.get(this.$kit.api.stock.expCodeSave+"?code="+this.code+"&expCode="+this.expCode,)
            .then((result) =>{
                if(result.status){
                    this.audioUrl = ok;
                    this.playAudio();
                    this.code = null;
                    this.expCode = null;
                    this.$refs['codeRef'].focus();
                }else{
                    this.audioUrl = error;
                    this.playAudio();
                    this.code = null;
                    this.expCode = null;
                    this.$refs['codeRef'].focus();
                }
               
            });
    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = this.audioUrl;
        audio.play();
    },
  }
}
</script>

<style class="scss" scoped>


</style>
