<!-- 出货统计 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('选择批次日期')" label-width="100px">
                <el-date-picker  v-model="pcDate"  type="daterange"  :range-separator="msg('至')" 
                    value-format="yyyy-MM-dd"
                    end-placeholder=""
                    size="small"
                >
                </el-date-picker>
            </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('输入批次')" label-width="100px">
                <el-input 
                    v-model="pici" style="margin-top:0px;" size="small">
                </el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-table :data="batchs" style="width: 100%" size="small" ref="dataTable" max-height="400" stripe>
          <el-table-column label="批次信息">
            <el-table-column type="selection" min-width="55"></el-table-column>
            <el-table-column type="index" :label="msg('序号')" min-width="60" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="expBatch" :label="msg('批次')" min-width="120" sortable :show-overflow-tooltip="true"></el-table-column>
          </el-table-column>
          <el-table-column align="center">
                <template slot="header">
                    <el-button type="primary" size="mini"  @click="getPici"><i class="el-icon-check"></i><span>{{msg('搜索批次')}}</span></el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-row> 

    <el-row style="margin-top:20px;">
        <el-col :xs="24" :sm="12">
            <el-button type="primary" size="mini"  @click="getTotal"><i class="el-icon-check"></i><span>{{msg('统计')}}</span></el-button>
        </el-col>
    </el-row> 
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('总票数')">
                <el-tag effect="dark" type='warning'>{{total1}}</el-tag>{{msg('票')}}
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('总袋数')">
                <el-tag effect="dark" type='warning'>{{total2}}</el-tag>{{msg('袋')}}
            </el-form-item>
        </el-col>
    </el-row> 

    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('总袋重')">
                <el-tag effect="dark" type='warning'>{{weight1}}</el-tag>{{msg('公斤')}}
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('总票重')">
                <el-tag effect="dark" type='warning'>{{weight2}}</el-tag>{{msg('公斤')}}
            </el-form-item>
        </el-col> 
    </el-row> 

    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('总托重')">
                <el-tag effect="dark" type='warning'>{{weight3}}</el-tag>{{msg('公斤')}}
            </el-form-item>
        </el-col> 
    </el-row> 

    <div style="text-align:center; margin-top: 15px;">
        <el-button  size="mini" type="danger" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>

</el-form> 
</template>

<script>
export default {
  name: 'ExpressSorting',
  data(){
    return{
        batchs:[],
        pcDate:[],
        total1:0,
        total2:0,
        weight1:0,
        weight2:0,
        weight3:0,
        pici:null,
    }
    
  }, 
  props: {
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
        this.batchs = [];
        this.pcDate = [];
        this.total1 = 0;
        this.total2 = 0;
        this.weight1 = 0;
        this.weight2 = 0;
        this.weight3 = 0;
        this.pici = null
      },
    getPici(){
        if(this.pcDate.length <= 0){
            this.$message(this.msg('请选择批次日期'));
            return;
        }
        let starDate = this.pcDate[0];
        let endDate = this.pcDate[1];

        this.batchs.splice(0,this.batchs.length);
        this.$axios.get(this.$kit.api.stock.getPiciByDate+"?starDate="+starDate+"&endDate="+endDate+"&pici="+this.pici)
            .then((result) => {
                let data = result.data;
                if(data.length > 0){
                    data.forEach(e => {
                        this.batchs.push(
                            {
                                expBatch:e
                            }
                        )
                    });
                }else{
                    this.$message(this.msg('无批次信息'));
                }
            });

    },
    getTotal(){
        var selectes = this.$refs.dataTable.selection;
        if(selectes.length <= 0){
            this.$message(this.msg('请选择批次'));
            return;
        }
        let pc = selectes.map(s=>s.expBatch).join(",");

        this.$axios.post(this.$kit.api.export.tongji+"?pici="+pc)
            .then((result) => {
                let data = result.data;
                this.total1 = data.total1;
                this.total2 = data.total2;
                this.weight1 = (data.weight1*0.001).toFixed(3);
                this.weight2 = (data.weight2*0.001).toFixed(3);
                this.weight3 = (data.weight3*0.001).toFixed(3);
            });
    },
    onSubmit(){
        if(!this.isMatch){
            this.$message(this.msg('请选择品名导出方式'));
            return;
        }
        var selectes = this.$refs.dataTable.selection;

        let pc = '';
        if(selectes.length > 0){
            pc = selectes.map(s=>s.expBatch).join(",");
        }

        this.$emit("close-dg", {isMatch: this.isMatch,expBatchs:pc})
    },
  }
}
</script>

<style scoped lang="scss">


</style>
