<!-- 通知收件选择仓库 -->
<template>
<el-row class="wrap-panel">
    <span class="plabel">{{msg('仓库')}}</span>
    <el-select v-model="storeId"  placeholder="请选择" size="mini"
        clearable
    >
        <el-option
            v-for="item in stores"
                :key="item.id"
                :label="item.name"
                :value="item.id">
        </el-option>
    </el-select>
    <div style="text-align:center; margin-top:20px;">
        <el-button type="primary" size="mini"  @click="doSubmint"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-row>  
</template>

<script>
export default {
    name: 'StockReceiver',
    mounted: function(){
    },
    data(){
        return {
            storeId: null,
            stores: null,
        }
    },
    props:{
        ids:String
    },
    methods:{
        init(){
            this.storeId = null;
            this.stores = null;
            this.getStores();
        },
        getStores(){
            this.$axios.post(this.$kit.api.store.listUsed)
                .then((result)=> {
                    if(result.status){
                        this.stores = result.data;
                    }
                });
        },
        doSubmint(){
            if(!this.storeId){
                this.$message(this.msg('请选择仓库'));
                return;
            }

            this.$axios.post(this.$kit.api.updateColumn.updateReceive+'?ids='+this.ids+'&storeId='+this.storeId)
                .then((result) => {
                    if(result.status){
                        this.$message(result.msg || this.msg('更新成功'));
                        this.$emit("close-dg", true);
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                });

            // this.$axios.post(this.$kit.api.updateColumn.updatePkgType+'?ids='+this.ids+'&storeId='+this.storeId)
            //     .then((result) =>{
            //         if(result.status){
            //             this.$message(result.msg || this.msg('更新成功'));
            //             this.$emit("close-dg", true);
            //         }else{
            //             this.$message(result.msg || this.msg('更新失败'));
            //         }
            //     });
        }
    }
}
</script>
<style lang="scss" scoped>
.plabel{display:inline-block; margin-left: 15px; margin-right:10px;}
.pintpu{width:300px; max-width:300px;}
</style>