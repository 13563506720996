<!--打印模板-->
<template>
    <div class="print-wrap">

      <div class="tag-wrap">
        <h2>包裹單號</h2>
        <img class="tag-barcode" :src="'data:image/png;base64,'+data.barcodeAsBase64" />
        <div class="tag-code">{{data.pkgCode.substring(0, data.pkgCode.length-4)}}
          <span style="font-size: 22px; font-weight:bold;">{{data.pkgCode.substring(data.pkgCode.length-4)}}</span>
        </div>
        <div class="tag-total" style="display:flex; align-items:center; justify-content:center;">
          <span style="display:inline-block; margin-right:20px; font-size:22px; font-weight:bold;">{{data.total}}</span>
          {{data.created}}
        </div>
      </div>
      
    </div>
</template>

<script>
export default {
  name: "Ticket",
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
.print-wrap {
  margin: 0px auto;
  width: 400px;
}
.print-wrap h3 {
  margin: 6px 0px;
  text-align: center;
  font-size: 16px;
}
.tag-code{
  font-size: 13px;
  color: #333;
  margin:5px 0;
}
.tag-total{
  font-size: 16px;
  color: #333;
  margin:15px 0;
}
.tag-name{
  font-size: 20px;
  color: #333;
  margin:10px 0;
}

.tag-wrap {
  text-align: center;
  line-height: 1;
  font-size: 13px;
  color: #333;
}
.product-img{
  width:100%;
}
</style>
