<!--打印包号-->
<template>
    <div class="xform">
        <div style="display:flex;margin-top:20px;margin-bottom:10px;align-items:center;justify-content:center;">
            <el-button type="primary" size="mini"  @click="doPrint"><i class="el-icon-printer"></i><span>{{msg('确定打印')}}</span></el-button>
        </div>

        <div style="text-align:center;" v-if="datas && datas.length>0">
            <vue-easy-print ref="easyPrint" tableShow>
                <template>
                    <div class="tag-wrap" v-for="(data, idx) in datas" :key="idx">
                        <div style="page-break-before: always;" v-if="idx>0"></div>
                        <h2>包裹單號</h2>
                        <img class="tag-barcode" :src="'data:image/png;base64,'+data.barcodeAsBase64" />
                        <div class="tag-code">{{data.pkgCode.substring(0, data.pkgCode.length-4)}}
                        <span style="font-size: 22px; font-weight:bold;">{{data.pkgCode.substring(data.pkgCode.length-4)}}</span>
                        </div>
                        <div class="tag-total" style="display:flex; align-items:center; justify-content:center;">
                        <span style="display:inline-block; margin-right:20px; font-size:22px; font-weight:bold;">{{data.total}}</span>
                        {{data.created}}
                        </div>
                    </div>
                </template>
            </vue-easy-print>
        </div>

    </div>
</template>

<script>
import vueEasyPrint from "vue-easy-print";
export default {
    name: "StockPrintTag",
    components: {
        vueEasyPrint: vueEasyPrint,
    },
    mounted(){
        this.getData();
    },
    data(){
        return {
            datas: null,
        }
    },
    props:{
        ids: String
    },
    methods:{
        getData(){  //初始化数据
            this.$axios.get(this.$kit.api.stock.printBagCodeAsBarcode+"?ids="+this.ids)
                .then((result) => {
                    if(result.status){
                        this.datas = result.data;
                    }
                });
        },
        doPrint(){
            this.$nextTick(() => {
                setTimeout(()=>{
                    this.$refs.easyPrint.print();
                }, 200);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.next-page {
  page-break-before: always;
}
.tag-code{
  font-size: 13px;
  color: #333;
  margin:5px 0;
}
.tag-total{
  font-size: 16px;
  color: #333;
  margin:15px 0;
}
.tag-name{
  font-size: 20px;
  color: #333;
  margin:10px 0;
}

.tag-wrap {
  text-align: center;
  line-height: 1;
  font-size: 13px;
  color: #333;
}

</style>