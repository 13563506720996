<!-- 库存分拣 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('单号')">
                <el-input v-model="code" ref="codeRef" placeholder="请输入货单号、面单码、查询码" size="small" @keyup.native="autoSelectCode">
                </el-input>
            </el-form-item>
            <input style="display:none"/>
        </el-col>
    </el-row>
  
    <div style="text-align:center;margin-top:10px;">
        <el-button  size="mini" type="danger" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>

    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>

</el-form> 
</template>

<script>
import noCode from "../../assets/media/noInCode.mp3";//单号不存在
import save from "../../assets/media/save.mp3";//保留
import qijian from "../../assets/media/qijian.mp3";//弃件
import zhuanji from "../../assets/media/zhuanji.mp3";//转寄件
import jihui from "../../assets/media/jihui.mp3";//寄回件
import notUpper from "../../assets/media/notUpper.mp3";//未上架
export default {
  name: 'ExpressSorting',
  data(){
    return{
        code:null,
        audioUrl:null,
        urls:[noCode,save,qijian,zhuanji,jihui,notUpper]
    }
  }, 
  props: {
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
          this.code = null;
      },
      autoSelectCode(e){

        if(e.keyCode == 13){
            this.expSort();
        }

    },
    expSort(){
        if(!this.code){
            this.$message(this.msg('请输入货单号、面单码、查询码'));
            return;
        }
        this.$axios.get(this.$kit.api.stock.stockSorting+"?code="+this.code,)
            .then((result) =>{
                if(result.status){
                    this.playAudio(result.data);
                    this.code = null;
                    this.$refs['codeRef'].focus();
                }else{
                    this.$message(result.msg);
                }
               
            });
    },
    playAudio(index){
        let audio = this.$refs.audioRef;
        audio.src = this.urls[index-1];
        audio.play();
    },
  }
}
</script>

<style scoped lang="scss">


</style>
