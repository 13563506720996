<!-- 同步店铺订单 -->
<template>
   <div>
        <div style="margin-bottom: 15px; ">
           <el-radio-group v-model="days" size="medium" @change=change()>
                <el-radio-button :label="3" >{{msg('近3天')}}</el-radio-button>
                <el-radio-button :label="7" >{{msg('近7天')}}</el-radio-button>
                <el-radio-button :label="14" >{{msg('近14天')}}</el-radio-button>
            </el-radio-group>
        </div>
        <div style="margin-bottom: 15px; ">
           <el-date-picker size="medium"
                v-model="startDate"
                type="date"
                placeholder="选择开始日期">
            </el-date-picker>
        </div>
        <div>
            <p style="text-indent:2em">温馨提示：如多次同步店铺订单后，订单仍未同步过来，请按以下方式处理；
                第一步：请检查店铺是否有绑定成功，如确认绑定成功；
                第二步：再确认店铺在绑定该账号前是否有用该系统的其它账号绑定过，
                如有，请先登录此账号，将需要同步的订单先在此账号中删除，删除后才能同步到新绑定的这个账号中来；
                注意：在虾皮已发货的订单不会再同步；详细指引可参考使用指南文档教程中的《订单同步不了的处理方式》</p>
        </div>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="enter"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "StockSyncOrder",
    data(){
        return{
            days: 0,
            startDate: null,
        }
    },
    methods: {
        change(){
            let dayL = this.days * 24 * 3600 * 1000;
            dayL = new Date().getTime() - dayL;
            this.startDate = new Date(dayL);
        },
        enter(){
            this.$axios.post(this.$kit.api.shop.syncOrder+"?st="+this.startDate.getTime())
                .then((result) => {
                    if(result.status){
                        this.$message(result.msg || this.msg('同步失败'));
                        this.$emit("close-dg");
                    }else{
                        this.$message(this.msg('同步失败'));
                    }
                });
        }
    }
}
</script>
<style lang="scss" scoped>

</style>