<!-- 出货时间 -->
<template>
<el-form label-width="150px">
    <el-row>
        <el-col :xs="24" :sm="24">
            <el-form-item :label="msg('选择日期时间')" label-width="120px">
                <el-date-picker
                    v-model="pcDate"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center; margin-top: 15px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

</el-form> 
</template>

<script>
export default {
  name: 'ExpressSorting',
  data(){
    return{
        pcDate:null,
    }
    
  }, 
  props: {
      ids:Array
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
        this.pcDate = null;
      },
    onSubmit(){
        if(!this.pcDate){
            this.$message(this.msg('请选择日期'));
            return;
        }
        let _this = this;
        _this.$axios.post(_this.$kit.api.updateColumn.updateWeightTime+'?ids='+this.ids.join(',')+'&date='+this.pcDate)
                .then((result) => {
                    if(result.status){
                        _this.$message(result.msg || _this.msg('更新成功'));
                        _this.$emit("close-dg", true);
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                });
    },
  }
}
</script>

<style scoped lang="scss">


</style>
