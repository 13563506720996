<!-- 导出资料 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('品名导出方式:')">
                <template>
                    <el-radio v-model="isMatch" label="2">{{msg('使用原始品名')}}</el-radio>
                    <el-radio v-model="isMatch" label="1">{{msg('匹配品名库')}}</el-radio>
                </template>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="20" v-if="expcom.indexOf('d')>-1">
            <el-form-item :label="msg('配送方式:')">
                <template>
                    <el-select v-model="expCompany" placeholder="请选择">
                        <el-option
                        v-for="item in expCompanys"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </el-form-item>
        </el-col>
    </el-row>
    
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('选择录重时间')" label-width="100px">
                <el-date-picker  v-model="pcDate"  type="datetimerange"  :range-separator="msg('至')" 
                    value-format="yyyy-MM-dd HH:mm:ss"
                    end-placeholder=""
                    size="small"
                >
                </el-date-picker>
            </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('输入批次')" label-width="100px">
                <el-input 
                    v-model="pici" style="margin-top:0px;" size="small">
                </el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-table :data="batchs" style="width: 100%" size="small" ref="dataTable" max-height="400" stripe>
          <el-table-column label="批次信息">
            <el-table-column type="selection" min-width="55"></el-table-column>
            <el-table-column type="index" :label="msg('序号')" min-width="60" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="expBatch" :label="msg('批次')" min-width="120" sortable :show-overflow-tooltip="true"></el-table-column>
          </el-table-column>
          <el-table-column align="center">
                <template slot="header">
                    <el-button type="primary" size="mini"  @click="getPici"><i class="el-icon-check"></i><span>{{msg('搜索批次')}}</span></el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-row> 

    <div style="text-align:center; margin-top: 15px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

</el-form> 
</template>

<script>
export default {
  name: 'ExpressSorting',
  data(){
    return{
        isMatch:null,
        batchs:[],
        pcDate:[],
        expCompany:null,
        expCompanys:[],
        pici:null,
    }
  }, 
  props: {
      expcom:String
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
            this.getCompanys();
            this.isMatch = null;
            this.batchs= [];
            this.pcDate = [];
            this.expCompany = null;
            this.pici = null
      },
      getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
    getPici(){
        if(this.pcDate.length <= 0){
            this.$message(this.msg('请选择批次日期'));
            return;
        }
        let starDate = this.pcDate[0];
        let endDate = this.pcDate[1];

        this.batchs.splice(0,this.batchs.length);
        this.$axios.get(this.$kit.api.stock.getPiciByDate+"?starDate="+starDate+"&endDate="+endDate+"&pici="+this.pici)
            .then((result) => {
                let data = result.data;
                if(data.length > 0){
                    data.forEach(e => {
                        this.batchs.push(
                            {
                                expBatch:e
                            }
                        )
                    });
                }else{
                    this.$message(this.msg('无批次信息'));
                }
            });

    },
    onSubmit(){
        if(!this.isMatch){
            this.$message(this.msg('请选择品名导出方式'));
            return;
        }
        if(this.expcom.indexOf('d')>-1){
            if(!this.expCompany){
                this.$message(this.msg('请选择配送方式'));
                return;
            }
        }
        var selectes = this.$refs.dataTable.selection;

        let pc = '';
        if(selectes.length > 0){
            pc = selectes.map(s=>s.expBatch).join(",");
        }

        this.$emit("close-dg", 
            {
                isMatch: this.isMatch,
                expBatchs:pc,
                expCompany:this.expCompany
            }
        )
    },
  }
}
</script>

<style scoped lang="scss">

</style>
