<!--打印模板-->
<template>
  <div class="print-box" 
    :style="{display: ticketDtos.length>1?'block':'inline-block'}"
  >
    <div class="print-wrap" v-for="(ticketDto, index) in ticketDtos" :key="index">

      <div class="invoice-box">
        <!-- <div class="dotted-line"></div> -->
        <h3>INVOICE</h3>
        <h3 v-if="printRealType=='o'">聖捷—網購貨物</h3>
        <div
          class="line-row"
        >
          <div class="inline">
            <div class="line"><span style="font-weight:700;font-size:18px;">No：</span></div>
            <div class="txt">{{ticketDto.code?ticketDto.code:''}}</div>
          </div>
          <div style="font-weight:700;font-size:20px;">{{ticketDto.remark}}</div>
        </div>
        <div class="xtable-wrap">
          <table class="invoices">
            <thead>
              <tr>
                <th>物品描述</th>
                <th>數量</th>
                <th>單價</th>
                <th>總金額</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ticketDto.productName}}</td>
                <td>{{ticketDto.qty}}</td>
                <td>{{ticketDto.price}}</td>
                <td>{{ticketDto.amount}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="line-row" 
        >
          <div class="inline"
          >
            <div class="line">姓&nbsp;&nbsp;名：</div>
            <div class="txt">{{ticketDto.receiver}}</div>
          </div>
        </div>
        <div class="xline" 
        >
          <div class="line">電&nbsp;&nbsp;話：</div>
          <div class="txt">{{ticketDto.receiverTel}}</div>
        </div>
        <div class="xline" 
          >
          <div class="line">地&nbsp;&nbsp;址：</div>
          <div class="txt">{{ticketDto.receiverAddr}}</div>
        </div>
        <div class="invoice-header">
          <div
            class="barcode-warp"
          >
            <img :src="'data:image/png;base64,'+ticketDto.barcodeAsBase64" />
            <div>{{ticketDto.code?ticketDto.code:''}}</div>
          </div>
        </div>
        <!--end orders-->
      <!--end invoice-box-->
      <div class="next-page" v-if="ticketDtos && ticketDtos.length > 1"></div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StockPrintPkgTicketTpl",
  props: {
    ticketDtos: Array,
    printRealType:String,
  },
  computed: {
  },
  methods:{
  }
};
</script>

<style lang="scss" scoped>

table{
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}

table td, table th{
  border-left: 1px solid #999;
  border-top: 1px solid #999;
}

@media print{
  table{
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  table td, table th{
    border-left: 1px solid #000;
    border-top: 1px solid #000;
  }
}

.print-box {
  width: 100%;
  margin-top: 10px;
}
.invoice-box{
  -webkit-print-color-adjust: exact;
}
.print-wrap {
  margin: 0px auto;
  width: 400px;
}
.invoice-header {
  position: relative;
  text-align: center;
  line-height: 1;
  padding-bottom: 10px;
}
.invoice-header .goodtype1 {
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  left: 1px;
  top: 7px;
  line-height: 1;
  padding: 1px;
  border: 2px solid;
}
.invoice-header .goodtype0 {
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  left: 1px;
  top: 7px;
  line-height: 1;
  border: 2px solid;
  border-radius: 50%;
}
.invoice-header .offline1 {
  color: white;
  background: black;
}

.invoice-header .company {
  font-size: 30px;
  position: absolute;
  left: 40px;
  top: 10px;
  line-height: 1;
}
.dotted-line {
  border-bottom: 2px dashed #000;
}
.print-wrap h3 {
  margin: 6px 0px;
  text-align: center;
  font-size: 16px;
}
.line-row {
  display: flex;
  font-size: 13px;
  color: #333;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.line-row .inline {
  flex: 0 0 50%;
}

.xline {
  font-size: 13px;
  display: flex;
  color: #333;
  flex-wrap: nowrap;
}

.xtable-wrap th {
  font-size: 14px;
  color: #333;
}
.xtable-wrap table {
  width: 100%;
  border-spacing: 0px;
  margin: 10px 0px;
}
.xtable-wrap td {
  font-size: 13px;
  padding-left: 3px;
  padding-right: 3px;
  color: #333;
}
.next-page {
  page-break-after: always;
}
</style>
