<!-- 重量分摊 -->
<template>
<el-form label-width="100px">
    <el-row>
        <h3>本次托运</h3>
        <el-col :xs="24" :sm="8">
            <el-form-item :label="msg('总袋数')">
                <el-tag effect="dark" type='success'>{{pkgTotal}}</el-tag>{{msg('袋')}}
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
            <el-form-item :label="msg('总重量')">
                <el-tag effect="dark" type='success'>{{weightTotal}}</el-tag>{{msg('公斤')}}
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
            <el-form-item :label="msg('总票数')">
                <el-tag effect="dark" type='success'>{{piaoTotal}}</el-tag>{{msg('票')}}
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <h3>实际托运</h3>
        <el-col :xs="24" :sm="14">
            <el-form-item :label="msg('实际托运重量')">
                <!-- <el-input v-model="actualWeight" type="number" :placeholder="msg('请输入实际重量')">
                    <template slot="prepend">公斤</template>
                    <el-button slot="append" type="primary" icon="el-icon-s-promotion" @click="budget">执行预算</el-button>
                </el-input> -->
                <el-input-number v-model="actualWeight" :precision="2" :step="0.1" size="mini"></el-input-number>
                <el-button type="primary" icon="el-icon-s-promotion" @click="budget" size="mini">执行预算</el-button>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('预算结果')">
                <div style="padding:10px 10px;border:1px solid #EBEEF5;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
                    <p style="font-size:16px Base">经计算本次托运加强包装的包装材料重量为<el-tag effect="dark" type='success'>{{totalCha}}</el-tag>公斤</p>
                    <p style="font-size:16px Base">按票数分摊，每票需分摊<el-tag effect="dark" type='success'>{{weightCha}}</el-tag>公斤重量</p>
                    <p style="font-size:16px Base">按每票重量百分比分摊，每票需分摊<el-tag effect="dark" type='success'>{{piaoCha}}</el-tag>%重量</p>
                </div>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <h3>选择分摊方式</h3>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('分摊方式')">
                <el-select v-model="shareType" placeholder="请选择" @change="selectChange" size="mini">
                    <el-option
                        v-for="item in shareTypes"
                        :key="item.value" 
                        :label="item.label"
                        :value="item.value"> 
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="10">
            <el-form-item :label="msg('每票分摊')">
                <el-input v-model="actualShare" type="number" @input="inputChange" size="small">
                    <template slot="append">{{unitMsg}}</template>
                </el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="7">
            <el-form-item :label="msg('总重量为:')">
                <el-tag effect="dark" type="success">{{actualTotal}}</el-tag>{{msg('公斤')}}
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="7">
            <el-form-item :label="msg('重量差为:')">
                <el-tag effect="dark" type="success">{{actualTotalCha}}</el-tag>{{msg('公斤')}}
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('执行分摊')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
        shareType:null,
        shareTypes:[
            {
                label:this.msg('按票数分摊'),
                value:0
            },
            {
                label:this.msg('按每票重量百分比分摊'),
                value:1
            },
        ],
        unitMsg:'公斤',

        //本次托运数据
        pkgTotal:0,
        weightTotal:0,
        piaoTotal:0,

        actualWeight:null,//实际托运重量

        //预算结果
        totalCha:0,
        weightCha:0,
        piaoCha:0,

        //实际分摊结果
        actualShare:0,//每票实际分摊
        actualTotal:0,//总重量
        actualTotalCha:0,//重量差

    }
    
  }, 
  computed:{
  },
  props: {
        stocks : Array
    },
  mounted(){
  },
  methods:{
    init(){
        //初始化界面
        this.shareType = null,
        this.unitMsg = '公斤',
        //本次托运数据
        this.pkgTotal = 0,
        this.weightTotal = 0,
        this.piaoTotal = 0,
        this.actualWeight = null,//实际托运重量
        //预算结果
        this.totalCha = 0,
        this.weightCha = 0,
        this.piaoCha = 0,
        //实际分摊结果
        this.actualShare = 0,//每票实际分摊
        this.actualTotal = 0,//总重量
        this.actualTotalCha = 0,//重量差
        
        this.stocks.forEach(stock => {
            //总袋数
            if(stock.expPkgCode){
                this.pkgTotal ++;
            }
            //总重量（单票重量）
            if(stock.weightExp > 0){
                this.weightTotal += (stock.weightExp);
            }
        });
        //总票数
        this.piaoTotal = this.stocks.length;
        this.weightTotal = this.weightTotal/1000;
    },
    budget(){
        //填充预算结果
        if(!this.actualWeight){
            this.$message({message:this.msg('请输入实际托运重量'),type:'warning'});
            return;
        }
        if(this.weightTotal == 0){
            this.$message({message:this.msg('总重量为0，无法执行预算'),type:'warning'});
            return;
        }
        this.totalCha = ((this.actualWeight*1000 - this.weightTotal*1000)/1000).toFixed(3);
        this.weightCha = (this.totalCha / this.piaoTotal).toFixed(3);
        this.piaoCha = ((this.totalCha / this.weightTotal).toFixed(4)*100).toFixed(2);
    },
    selectChange(item){
         //实际分摊结果
        //actualShare:0,//每票实际分摊
        //actualTotal:0,//总重量
        //actualTotalCha:0,//重量差
        if(!this.actualWeight){
            this.$message({message:this.msg('请输入实际托运重量'),type:'warning'});
            return;
        }
        if(this.weightTotal == 0){
            this.$message({message:this.msg('总重量为0，无法执行分摊'),type:'warning'});
            return;
        }
        if(item == 0){
            this.unitMsg = '公斤';
            this.actualShare = this.weightCha;
            this.actualTotal = (this.weightCha*this.piaoTotal+this.weightTotal).toFixed(3);//实际总重量= 总票数*每票分摊重量+托运总重量
        }else if(item == 1){
            this.unitMsg = '%';
            this.actualShare = this.piaoCha;
            this.actualTotal = (this.weightTotal*(this.piaoCha/100)+this.weightTotal).toFixed(3);//实际总重量=托运总重量*（1+百分比）
        }
        this.actualTotalCha = (this.actualTotal-this.actualWeight).toFixed(3);//重量差=实际总重量-托运总重量
    },
    inputChange(item){
        if(this.shareType == 0){//按重量分摊
            this.actualShare = item;
            this.actualTotal = (this.piaoTotal*item+this.weightTotal).toFixed(3);//实际总重量= 总票数*每票分摊重量+托运总重量
            this.actualTotalCha = (this.actualTotal-this.weightTotal).toFixed(3);//重量差=实际总重量-托运总重量
        }else if(this.shareType == 1){//按百分比分摊
            this.actualShare = item;
            this.actualTotal = (this.weightTotal*(item/100)+this.weightTotal).toFixed(3);//实际总重量=托运总重量*（1+百分比）
            this.actualTotalCha = (this.actualTotal-this.weightTotal).toFixed(3);//重量差=实际总重量-托运总重量
        }
    },
    onSubmit(){
        var _this = this;

        if(!this.actualWeight){
            this.$message({message:this.msg('请输入实际托运重量'),type:'warning'});
            return;
        }
        if(this.weightTotal == 0){
            this.$message({message:this.msg('总重量为0，无法执行分摊'),type:'warning'});
            return;
        }
        if(this.shareType == null || this.shareType<0){
            this.$message({message:this.msg('请选择分摊方式'),type:'warning'});
            return;
        }
        if(!this.actualShare){
            this.$message({message:this.msg('每票分摊的数量不能为空'),type:'warning'});
            return;
        }

        this.$axios.post(this.$kit.api.stock.weightShare+'?type='+this.shareType+'&weight='+this.actualShare,this.stocks)
            .then((result) =>{
                _this.$message(result.msg || _this.msg('更新成功'));
                if(result.status){
                    _this.$emit("close-dg",true);
                }
            });
    },
  }
}
</script>

<style scoped lang="scss">


</style>
