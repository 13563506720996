<!-- 扫码打包 -->
<template>
<el-form ref="form" label-width="100px">
    <el-form-item :label="msg('包号')" @keyup.native="nextFocus">
        <el-input v-model="pkgCode" ref="pkgCodeRef"></el-input>
    </el-form-item>
    <el-form-item :label="msg('单号')" @keyup.native="autoSave">
        <el-input v-model="code" ref="codeRef" :placeholder="msg('可扫描订单编号、查询码、面单码')"></el-input>
    </el-form-item>
    <el-form-item :label="msg('')">
        <el-checkbox v-model="checked">{{msg('打包完成')}}</el-checkbox>
    </el-form-item>
    <div style="text-align:center;">
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>

    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>

</el-form> 
</template>

<script>
import ok from "../../assets/media/ok.mp3";//成功
import NoRecord from "../../assets/media/NoRecord.mp3"; //记录不存在
import PkgNo from "../../assets/media/PkgNo.mp3"; //袋号
import Warning from "../../assets/media/warning.wav";
export default {
  name: 'autoPkgCode',
  data(){
    return{
       code: null,
       pkgCode: null,
       prevPkgCode: null,
       inputing: false,
       checked:false,
       audioUrl:null,
    }
    
  }, 
  computed:{
      scanPageCode:{
          get(){
              return this.code;
          },
          set(val){
              this.prevPkgCode = this.code;
              this.code = val;
          }
      }
  },
  props:{
  },
  mounted: function(){
  },
  methods:{
    init(){
        this.pkgCode = null;
        this.code = null;
        this.$refs['pkgCodeRef'].focus();
        this.checked = false;
    },
    nextFocus(e){
        if(e.keyCode == 13){
            if(!this.pkgCode){
                this.$message("包号不能为空");
            }else{
                this.audioUrl = PkgNo;
                this.playAudio();
                this.$refs['codeRef'].focus();
            }
        }
    },
    autoSave(e){
        if(e.keyCode == 13){
            this.doAutoSave();
        }
    },
    doAutoSave(){
        var _this = this;
        if(!this.pkgCode){
            this.$message("包号不能为空");
        }

        if(!this.code){
            this.$message("单号不能为空");
        }

        var stock = {
            code: this.code,
            orderBagCode: this.pkgCode
        }

        this.$axios.post(this.$kit.api.stock.autoSavePkgCode+"?checked="+this.checked, stock)
            .then((result)=>{
                if(result.msg.indexOf('不存在') > -1){
                    this.audioUrl = NoRecord;
                    this.playAudio();
                }else if(result.msg.indexOf('成功') > -1){
                    this.audioUrl = ok;
                    this.playAudio();
                }else{
                    this.$message(result.msg);
                    this.audioUrl = Warning;
                    this.playAudio();
                }
                this.code = null;
                _this.$refs['codeRef'].focus();
            }).catch(function (error) {
                console.error(error);
                this.code = null;
            });


    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = this.audioUrl;
        audio.play();
    },
  },
  
}
</script>

<style class="scss" scoped>


</style>
