<!-- 代打包登记 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('订单编号')">
                <el-input v-model="code" ref="codeRef" placeholder="请输入订单编号" size="small" @keyup.native="autoSelectCode">
                </el-input>
            </el-form-item>
            <el-form-item :label="msg('打包服务')">
                <el-radio v-model="packType" :label="0">{{msg('普通')}}</el-radio>
                <el-radio v-model="packType" :label="1">{{msg('精细')}}</el-radio>
            </el-form-item>
            <input style="display:none"/>
        </el-col>
    </el-row>
  
    <div style="text-align:center;margin-top:10px;">
        <el-button  size="mini" type="danger" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>

    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>

</el-form> 
</template>

<script>
import ok from "../../assets/media/ok.mp3";//成功
import error from "../../assets/media/error.mp3";//错误
export default {
  name: 'PackInput',
  data(){
    return{
        code:null,
        inputing: false,
        audioUrl:null,
        packType:0,
    }
    
  }, 
  props: {
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
          this.code = null;
          this.packType = 0,
          this.$refs['codeRef'].focus();
      },
      autoSelectCode(e){

        if(e.keyCode == 13){
            this.packSend();
        }
    },
    packSend(){
        if(!this.packType && this.packType != 0){
            this.$message(this.msg('请选择打包服务'));
            return;
        }
        if(!this.code){
            this.$message(this.msg('请录入订单编号'));
            return;
        }
        this.$axios.get(this.$kit.api.stock.packSave+"?code="+this.code+"&packType="+this.packType,)
            .then((result) =>{
                if(result.status){
                    this.audioUrl = ok;
                    this.playAudio();
                    this.code = null;
                    this.$refs['codeRef'].focus();
                }else{
                    this.audioUrl = error;
                    this.playAudio();
                    this.code = null;
                    this.$refs['codeRef'].focus();
                }
               
            });
    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = this.audioUrl;
        audio.play();
    },
  }
}
</script>

<style scoped lang="scss">


</style>
