<!-- 扫码找货 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('待找单号')">
                <el-input v-model="code" ref="codeRef" placeholder="请输入待找单号" size="small" @keyup.native="autoSelectCode">
                </el-input>
            </el-form-item>
            <el-form-item :label="msg('扫码单号')">
                <el-input v-model="actualCode" ref="actualCodeRef" placeholder="请输入扫码单号" size="small" @keyup.native="autoSelectActualCode">
                </el-input>
            </el-form-item>
        </el-col>
    </el-row>
  
    <div style="text-align:center;margin-top:10px;">
        <el-button  size="mini" type="danger" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>

    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>

</el-form> 
</template>

<script>
import didi from "../../assets/media/warning.wav";//滴滴音
import ok from "../../assets/media/ok.mp3";//成功
export default {
  name: 'ExpCodeInput',
  data(){
    return{
        code:null,
        actualCode:null,
        audioUrl:null,
    }
    
  }, 
  props: {
      ordercode:String,
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
          if(this.ordercode){
              this.code = this.ordercode;
              this.$refs['actualCodeRef'].focus();
          }else{
              this.code = null;
              this.$refs['codeRef'].focus();
          }
          this.actualCode = null
      },
      autoSelectCode(e){

        if(e.keyCode == 13){
            this.$refs['actualCodeRef'].focus();
        }
    },
    autoSelectActualCode(e){
        if(e.keyCode == 13){
            this.expCodeSave();
        }
    },
    expCodeSave(){
        if(!this.code){
            this.$message(this.msg('请录入待找单号'));
            return;
        }
        if(!this.actualCode){
            this.$message(this.msg('请录入扫码单号'));
            return;
        }
        if(this.code == this.actualCode){
            this.audioUrl = ok;
            this.playAudio();
            this.actualCode = null;
            this.$refs['actualCodeRef'].focus();
            return;
        }else{
            this.audioUrl = didi;
            this.playAudio();
            this.actualCode = null;
            this.$refs['actualCodeRef'].focus();
        }
    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = this.audioUrl;
        audio.play();
    },
  }
}
</script>

<style class="scss" scoped>


</style>
