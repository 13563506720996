<!-- 弃件处理 -->
<template>
    <div class="content-panel">
        <el-form label-width="100px" :model="stock">
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('货单号')">
                        <el-input v-model.trim="stock.code" size="mini" readonly="readonly" :placeholder="msg('货单号由系统生成')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('票数')">
                        <el-input-number v-model="stock.detailQty" size="small" disabled></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('货单备注')">
                        <el-input v-model="stock.remark2" size="mini" :placeholder="msg('请输入货单备注')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form> 

        <div style="text-align:center; margin-top: 20px;margin-bottom: 20px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('提交')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>

    </div>
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
        stock:{
            code:null,
            detailQty:0,
            storeId:0,
            remark2:null,
            stockDetails:[]
        },
        ids:null,
    }
    
  }, 
  computed:{
  },
  props:{
  },
  mounted(){
  },
  methods:{
    init(ids){
        this.ids = ids.join(',');
        let qty = ids.length;
        this.$axios.get(this.$kit.api.stock.getQijianData+"?ids="+ids.join(','))
            .then((result) =>{
                if(result.status){
                    let data = result.data;
                    this.stock = {
                        code:data.code,
                        detailQty:data.detailQty,
                        storeId:data.storeId?data.storeId:0,
                        remark2:'',
                        stockDetails:data.stockDetails
                    }
                }
            })
    },
    onSubmit(){
        var _this = this;

        this.$axios.post(this.$kit.api.stock.doQijian+"?ids="+this.ids,this.stock)
            .then((result) =>{
                if(result.data){
                    _this.$message(result.msg || _this.msg('操作成功'));
                    _this.$emit('close-dg',true);
                }else{
                    this.$message(result.msg || _this.msg('操作失败'));
                }
            })
    },
  }
}
</script>

<style scoped lang="scss">

</style>
