<!--批量更新stock的指定信息-->
<template>
    <div class="xform">
        <el-row :gutter="10" type="flex" justify="space-between" align="center" >
            <el-col :span="9">
                <el-select v-model="type" size="mini" clearable :placeholder="msg('请选择')" @change="getSelectes">
                    <template v-for="(t, idx) in types">
                        <el-option v-if="hasPermission(t.auth)" :label="t.label" :value="idx" :key="idx"></el-option>
                    </template>
                </el-select>
            </el-col>
            <el-col :xs="15">
                <div style="display:flex;">
                    <div style="white-space:nowrap;">{{msg('批量填充')}}： </div>
                    <el-autocomplete v-if="type == 5" size="mini"
                        v-model="iptLabel"
                        :fetch-suggestions="querySearch"
                        placeholder="输入要填充的值"
                        :trigger-on-focus="false"
                        @select="handleSelect"
                    ></el-autocomplete>
                    <el-select v-else-if="[9,48].indexOf(type)>-1" placeholder="请选择仓位" v-model="iptValue" clearable  size="small">
                            <el-option
                            v-for="item in stores"
                            :key="item.id" 
                            :label="item.name"
                            :value="item.id"> 
                        </el-option>
                    </el-select>
                    <el-select v-else-if="[15].indexOf(type)>-1" placeholder="请选择配送方式" v-model="iptValue" clearable  size="small">
                            <el-option
                            v-for="item in expCompanys"
                            :key="item.value" 
                            :label="item.label"
                            :value="item.value"> 
                        </el-option>
                    </el-select>
                    <el-select v-else-if="[16,17,18,19,21,22,23,24,30,31,35,39,40,41,47].indexOf(type)>-1" 
                        size="mini" :placeholder="msg('请选择')" v-model="iptValue">
                        <el-option v-for="item in selectes" :label="item.label" :value="item.value" :key="item.value"></el-option>
                    </el-select>
                    <el-select v-else-if="type == 49" 
                        size="mini" :placeholder="msg('供参考，实际以出货为准')" v-model="iptValue">
                        <el-option v-for="item in selectes" :label="item.label" :value="item.value" :key="item.value"></el-option>
                    </el-select>
                    <el-date-picker v-else-if="[20,28,42].indexOf(type)>-1" v-model="iptValue" size="mini"
                        type="date" value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <el-input v-else style="max-width:200px;"
                        size="mini" :placeholder="msg('输入要填充的值')" v-model="iptValue" >
                    </el-input>
                    <!-- <el-button size="mini" icon="el-icon-check" @click="fillValue"></el-button> -->
                </div>
            </el-col>
        </el-row>
        <div style="display:flex;margin-top:20px;margin-bottom:10px;align-items:center;justify-content:center;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定更新')}}</span></el-button>
            <el-checkbox style="margin-left:10px;" v-model="isShowTable">查看列表</el-checkbox>
        </div>
        <div class="table-wrap" v-if="isShowTable">
            <vxe-table :data="datas"
                    export-config stripe
                    resizable border="full"
                    highlight-current-row highlight-hover-column highlight-current-column
                    show-overflow show-header-overflow 
                    :height="docHeight" 
                    :scroll-y="{gt: 50}"
                    style="width: 100%" ref="dataTable" >
                <vxe-table-column field="id" :title="msg('ID')" width="80" sortable></vxe-table-column>
                <vxe-table-column field="key" :title="msg('订单编号')" min-width="120" :show-overflow-tooltip="true"></vxe-table-column>
                <vxe-table-column field="value" :title="msg('数据')" min-width="150" >
                    <template v-slot="{ row }">
                        <el-autocomplete v-if="type == 5" size="mini"
                            v-model="row.label"
                            :fetch-suggestions="querySearch"
                            placeholder="输入要填充的值"
                            :trigger-on-focus="false"
                            @select="(item) => handleSelect(item, row)"
                        ></el-autocomplete>
                        <el-select v-else-if="[9,48].indexOf(type)>-1" placeholder="请选择仓位" v-model="row.value" clearable  size="small">
                                <el-option
                                v-for="item in stores"
                                :key="item.id" 
                                :label="item.name"
                                :value="item.id"> 
                            </el-option>
                        </el-select>
                        <el-select v-else-if="[15].indexOf(type)>-1" placeholder="请选择配送方式" v-model="iptValue" clearable  size="small">
                            <el-option
                                v-for="item in expCompanys"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                        <el-select v-else-if="[16,17,18,19,21,22,23,24,30,31,35,39,40,41,47].indexOf(type)>-1" 
                            size="mini" :placeholder="msg('请选择')" v-model="row.value">
                            <el-option v-for="item in selectes" :label="item.label" :value="item.value" :key="item.value"></el-option>
                        </el-select>
                        <el-select v-else-if="type == 49" 
                            size="mini" :placeholder="msg('供参考，实际以出货为准')" v-model="iptValue">
                            <el-option v-for="item in selectes" :label="item.label" :value="item.value" :key="item.value"></el-option>
                        </el-select>
                        <el-date-picker v-else-if="[20,28,42].indexOf(type)>-1" v-model="row.value" size="mini"
                            type="date" value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                        <el-input v-else
                            size="mini" :placeholder="msg('输入要填充的值')" v-model="row.value"  >
                        </el-input>
                    </template>
                </vxe-table-column>
                <vxe-table-column field="result"  min-width="200" header-align="right">
                    <!-- <template slot="header">
                        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定更新')}}</span></el-button>
                    </template> -->
                </vxe-table-column>
            </vxe-table>
        </div>
    </div>
</template>

<script>
export default {
    name: "StockBatchUpdate",
    components: {
    },
    computed: {
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    mounted(){
        
        let temps = this.dicts.stockStatus;

        //转运中；待验中；已清中；已提货；转配送；已入仓；待备货；待打包；已揽收；已发货；已弃件；已寄回；已寄出；
        let needs = [55,60,65,70,75,10,13,20,35,40,45,50,135,120,100];
        this.statuses = [];
        temps.forEach(s => {
            if(needs.indexOf(s.value)>-1){
                this.statuses.push(s);
            }
        });

    },
    data(){
        return {
            type: null,
            types: [
                {label: this.msg('班次'), auth: 'stock:batchFieldBanci'},       //0
                {label: this.msg('转运'), auth: 'stock:batchFieldZhuanyun'},    //1
                {label: this.msg('待验'), auth: 'stock:batchFieldDaiyan'},  //2
                {label: this.msg('清关'), auth: 'stock:batchFieldQingguan'},    //3
                {label: this.msg('提货'), auth: 'stock:batchFieldTihuo'},   //4
                {label: this.msg('客户'), auth: 'stock:batchFieldUser'},    //5
                {label: this.msg('包号'), auth: 'stock:batchFieldBag'}, //6
                {label: this.msg('袋号'), auth: 'stock:batchFieldPkg'}, //7
                {label: this.msg('批次'), auth: 'stock:batchFieldBatch'},   //8
                {label: this.msg('仓库'), auth: 'stock:batchFieldStore'},   //9
                {label: this.msg('架位号'), auth: 'stock:batchFieldShelfCode'}, //10
                {label: this.msg('店铺名'), auth: 'stock:batchFieldShopName'},  //11
                {label: this.msg('流程备注'), auth: 'stock:batchFieldRemark3'}, //12
                {label: this.msg('货单备注'), auth: 'stock:batchFieldRemark2'}, //13
                {label: this.msg('报单备注'), auth: 'stock:batchFieldRemark1'}, //14
                {label: this.msg('配送方式'), auth: 'stock:batchFieldCompany'}, //15
                {label: this.msg('面单状态'), auth: 'stock:batchFieldPageOut'}, //16
                {label: this.msg('配送类别'), auth: 'stock:batchFieldGoodType'},    //17
                {label: this.msg('订单来源'), auth: 'stock:batchFieldOrigin'},  //18
                {label: this.msg('打包类型'), auth: 'stock:batchFieldPkgType'}, //19
                {label: this.msg('保管期限'), auth: 'stock:batchFieldSaveDate'},    //20
                {label: this.msg('仓储类型'), auth: 'stock:batchFieldStoreStatus'}, //21
                {label: this.msg('出货方式'), auth: 'stock:batchFieldOuttype'}, //22
                {label: this.msg('货态修正'), auth: 'stock:batchFieldStatus'},  //23
                {label: this.msg('订单卖场'), auth: 'stock:batchFieldPlatform'}, //24
                {label: this.msg('托运备注'), auth: 'stock:batchFieldRemark4'}, //25
                {label: this.msg('资料备注'), auth: 'stock:batchFieldRemark5'}, //26
                {label: this.msg('客服备注'), auth: 'stock:batchFieldRemark6'}, //27
                {label: this.msg('备注说明1'), auth: 'stock:batchFieldRemark7'},    //28
                {label: this.msg('备注说明2'), auth: 'stock:batchFieldRemark8'},    //29
                {label: this.msg('物流状态'), auth: 'stock:batchFieldTrackStatus'}, //30
                {label: this.msg('理赔状态'), auth: 'stock:batchFieldPayStatus'}, //31
                {label: this.msg('航空结算'), auth: 'stock:batchFieldRemark11'}, //32
                {label: this.msg('清关结算'), auth: 'stock:batchFieldRemark10'}, //32
                {label: this.msg('派件结算'), auth: 'stock:batchFieldRemark12'}, //32
                {label: this.msg('异常处理'), auth: 'stock:batchFieldErrStatus'}, //35
                {label: this.msg('财务备注1'), auth: 'importExcel:finance1'},//36
                {label: this.msg('财务备注2'), auth: 'importExcel:finance2'},//37
                {label: this.msg('财务备注3'), auth: 'importExcel:finance3'},//38
                {label: this.msg('打包服务类型'), auth: 'stock:batchFieldPackType'},//39
                {label: this.msg('收费方式'), auth: 'stock:batchFieldTransitType'},//40
                {label: this.msg('结算状态'), auth: 'stock:batchFieldTransitStatus'},//41
                {label: this.msg('提货日期'), auth: 'stock:batchFieldTihuoTime'},//42
                {label: this.msg('交接信息'), auth: 'stock:batchFieldHandover'},//43
                {label: this.msg('资料备注1'), auth: 'stock:batchFieldMessages1'},//44
                {label: this.msg('资料备注2'), auth: 'stock:batchFieldMessages2'},//45
                {label: this.msg('资料备注3'), auth: 'stock:batchFieldMessages3'},//46
                {label: this.msg('等级'), auth: 'stock:batchFieldLevel'},//47
                {label: this.msg('库存仓库'), auth: 'stock:batchFieldKucun'},//48
                {label: this.msg('运输方式'), auth: 'stock:batchFieldRemark2'},//49
                {label: this.msg('货物品名'), auth: 'stock:batchFieldRemark2'},//50
                {label: this.msg('取件地址'), auth: 'stock:batchFieldRemark2'},//51
            ],
            iptValue: null,
            iptLabel: null,
            selectes: [],
            valueSelectes:[],
            stores:[],
            isShowTable:false,
            statuses: [],
            expCompanys:[],
        }
    },
    props:{
        datas: Array
    },
    methods:{
        getStores(){
            //获取仓库列表
            this.$axios.get(this.$kit.api.store.listUsed)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        data.splice(0, 0, {id: 0, name: '空'});
                        this.stores.splice(0,this.stores.length);
                        this.stores = data;
                    }
                });
        },
        getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
        fillValue(){
            this.datas.forEach(d => {
                d.label = this.iptLabel;
                d.value = this.iptValue;
            })
        },
        getSelectes(idx){
            this.iptValue = null;
            this.iptLabel = null;

            this.datas.forEach(d => {
                d.value = null;
                d.label = null;
                d.result = null;
            });

            if(this.type == 15){//配送方式
                this.getCompanys();
            }else if(this.type == 18){//订单来源
                this.selectes = this.dicts.orderOrigin;
            }else if(this.type == 17){//配送类别
                this.selectes = this.dicts.stockExpType;
            }else if(this.type == 16){//面单过期
                this.selectes = this.dicts.stockExpPageStatus;
            }else if(this.type == 19){//打包类型
                this.selectes = this.dicts.stockExpPkgType;
            }else if(this.type == 21){//仓储类型
                this.selectes = this.dicts.stockStoreType;
            }else if(this.type == 9){
                this.getStores();
            }else if(this.type == 22){//出货方式
                this.selectes = this.dicts.expoutType;
            }else if(this.type == 23){//货态修正
                this.selectes = this.statuses;
            }else if(this.type == 24){//订单卖场
                this.selectes = this.dicts.orderPlatforms;
            }else if(this.type == 30){//物流状态
                this.selectes = this.dicts.trackStatus;
            }else if(this.type == 31){//理赔状态
                this.selectes = this.dicts.payStatus;
            }else if(this.type == 35){
                this.selectes = [
                    {label: this.msg("待处理"), value: 5},
                    {label: this.msg("已处理"), value: 2},
                ]
            }else if(this.type == 39){//打包类型
                this.selectes = this.dicts.userPkgTypes;
            }else if(this.type == 40){//收费方式
                this.selectes = this.dicts.feeTypes;
            }else if(this.type == 41){//结算状态
                this.selectes = this.dicts.transitStatuses;
            }else if(this.type == 47){//等级
                this.selectes = this.dicts.levelStatuses;
            }else if(this.type == 48){//库存仓库
                this.getStores();
            }else if(this.type == 49){//库存仓库
                this.selectes = this.dicts.transportType;
            }
            return [];
        },
        querySearch(queryString, cbFn){  //搜索用户
            this.$axios.get(this.$kit.api.user.search+"?name="+encodeURIComponent(queryString)+"&type=1")
                .then((result) => {
                    if(result.status){
                        let users =[];
                        result.data.forEach(e => {
                            users.push({
                                id: e.id,
                                value: e.value
                            });
                        });
                        cbFn(users);
                    }
                });
        },
        handleSelect(item, row) { //获取选中值
            if(row){
                row.value = item.id,
                row.label = item.value
            }else{
                this.iptValue = item.id;
                this.iptLable = item.value;
            }
        },
        onSubmit(){

            this.fillValue();

            let ds = [];
            this.datas.forEach(d => {
                ds.push({
                    id: d.id,
                    value: d.value
                });
            });
            var _this = this;
            this.$axios.post(this.$kit.api.stock.batchUpdate+"?t="+this.type, ds)
                .then((result) => {
                    this.$message(result.msg || _this.msg('更新失败'));

                    if(result.status){
                        // _this.$message(result.msg || _this.msg('更新成功'));
                        for(let i = 0; i<result.data.length; i++){
                            this.datas[i].result = result.data[i];
                        }
                    }
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.xform{
    box-sizing: border-box;
}
.xctrl{
    text-align:center;
    padding:15px;
    width:100%;
}

</style>