<!-- 分包加单 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('订单编号')">
                <el-input v-model="code" placeholder="请输入订单编号" size="small"></el-input>
            </el-form-item>
            <el-form-item :label="msg('加单数')">
                <el-input v-model="count" type="number" placeholder="请输入加单数" size="small"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
        code:null,
        count:0,
    }
    
  }, 
  computed:{
  },
  mounted: function(){
  },
  methods:{
    onSubmit(){
        var _this = this;

        if(!this.code || this.count <= 0){
            this.$message(this.msg('请输入订单编号和数量'));
            return;
        }

        this.$axios.post(this.$kit.api.stock.stockAddPkg+"?code="+this.code+"&count="+this.count,)
            .then((result) =>{
                _this.$message(result.msg);
                if(result.status){
                    _this.$emit("close-dg",true);
                    _this.code = null;
                    _this.count = 0;
                }
            });
    },
  }
}
</script>

<style scoped lang="scss">


</style>
