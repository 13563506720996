<!-- 打印实名单 -->
<template>
<div>
    <!-- <div style="display:flex; align-items:center;">
        <div style="flex: 0 0 60px; text-align:right; margin-right:10px;">{{msg('单号')}}</div>
        <el-input v-model="code" ref="codeRef" style="width:300px;" @keyup.native="doInputCode" :placeholder="msg('请扫描单号')">
        </el-input>
        <div style="flex: 0 0 60px; text-align:right;">{{msg('后缀： ')}}</div>
        <el-checkbox v-model="addSuffixCode" style="margin-right:10px;"></el-checkbox>
        <el-input v-if="addSuffixCode" style="width:100px; "  v-model="suffixCode"></el-input>
    </div> -->
    <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
        <el-button  size="mini" type="primary" style="margin-right:20px;" v-on:click="perparePrint" icon="el-icon-printer">{{msg('打印')}}</el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>
    <span>
        <vue-easy-print tableShow ref="easyPrint">
            <template>
                <print-tpl :ticketDtos="pkgTicketDtos" :printRealType="printRealType" ></print-tpl>
            </template>
        </vue-easy-print>
    </span>
    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>
</div>
</template>

<script>
import vueEasyPrint from "vue-easy-print";
import PrintTpl from "./StockPrintRealNameTpl.vue";
import NoRecord from "../../assets/media/NoRecord.mp3"; //记录不存在
export default {
  name: 'ExpQuery',
  components:{
        PrintTpl : PrintTpl,
        vueEasyPrint: vueEasyPrint,
    },
  data(){
    return{
        pkgTicketDtos:[],
        suffixCode: null,
        addSuffixCode: false,
        code: null,
        printRealType:null,
    }
    
  }, 
  mounted: function(){
  },
  props:{
      ids:String
  },
  methods:{
    init(type){
        this.printRealType = type;
        this.suffixCode = null;
        this.addSuffixCode = false;
        this.code = null;
        if(this.ids){
            this.doGetPrintData();
        }
    },
    doInputCode(e){
        if(e.keyCode == 13){
            this.doGetPrintData();
        }
    },
    doGetPrintData(){
        var _this = this;

        if(this.code && this.addSuffixCode && !this.suffixCode){
            this.$message("后缀字符不能为空");
            return;
        }

        if(!this.ids && !this.code){
            this.$message("单号不能为空");
            return;
        }

        this.$axios.post(this.$kit.api.stock.printRealTicket+"?ids="+this.ids+"&code="+this.code+"&suffixCode="+this.suffixCode+"&printRealType="+this.printRealType)
            .then((result)=>{
                this.code = null;
                if(result.data){
                    this.pkgTicketDtos = result.data;
                    this.doPrint();
                }else{
                    this.playAudio();
                }
                // this.$refs['codeRef'].focus();
            }).catch(function (error) {
                console.log(error);
            });
    },
    perparePrint(){
        if(this.code){
            this.doGetPrintData();
        }else if(this.pkgTicketDtos && this.pkgTicketDtos.length > 0){
            this.doPrint();
        }
    },
    doPrint(){
        let _this = this;
        _this.$nextTick(()=>{
            _this.$refs.easyPrint.$nextTick(() => {
                setTimeout(()=>{
                     _this.$refs.easyPrint.print();
                }, 1000);
            });
        });
    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = NoRecord;
        audio.play(); 
    },
  }
}
</script>

<style scoped lang="scss">


</style>
