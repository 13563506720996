<template>
    <div class="row-wrap">
        
        <div style="text-align:center; margin-top:10px;" v-if="printDtos">
            <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                @click="doPrint()" 
            >{{msg('打印')}}</el-button>
        </div>
       
        <el-alert
            :title="msg('货单打印提示')"
            type="error">
        </el-alert>
        <template v-if="printDtos && printDtos.length>0" >
            <div style="max-height:250px; overflow:scroll;">
            <table class="msg-table" >
                <thead>
                    <tr>
                        <th>{{msg('单号')}}</th>
                        <th>{{msg('消息')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(dto, idx) in printDtos" :key="idx">
                        <td>{{dto.code}}</td>
                        <td>{{dto.msg}}</td>
                    </tr>
                </tbody>

            </table>
            </div>
             <vue-easy-print tableShow ref="easyPrint">
                <template>
                    <print-tpl :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="printDtos" :print-type="printType" :user="loginUser"></print-tpl>
                </template>
            </vue-easy-print>
        </template>
       
        <div style="text-align:center; margin-top:10px;" >
            <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                @click="doPrint()" 
            >{{msg('打印')}}</el-button>
            <el-button type="danger" size="mini" icon="iconfont icon-ziyuan157" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
        </div>
    </div>
</template>

<script>
import vueEasyPrint from "vue-easy-print";
import PrintTpl from "./StockPrintTicketTpl2";
export default {
    
    name: "StockPrint",
    components:{
        PrintTpl : PrintTpl,
        vueEasyPrint: vueEasyPrint,
    },
    data(){
        return{
            ticketSetting: null,
            printDtos:[],
            printWarn:0,
            printType:false,
            loginUser:null,
        }
    },
    mounted(){
        this.loginUser = JSON.parse(localStorage.getItem("user"));
        this.getTpConfigs();
        this.getPrintWarn();
    },
    props:{
        printCtrls: Array,
        printPkgCode:String,
    },
    methods:{
        getTpConfigs(){//获取货单打印配置
            var _this = this;
            this.$axios.get(this.$kit.api.user.tpConfig)
                .then( (result) => {
                    if(result.status){
                        if(result.data && result.data.length > 0){
                            result.data.forEach(e=>{
                                if(e.xvalue){
                                    if(e.disabled == 1){
                                        _this.ticketSetting = JSON.parse(e.xvalue);
                                    }
                                }
                            })
                            if(!this.ticketSetting){
                                _this.ticketSetting = {
                                    invoice: true,
                                    invoiceHead: true,
                                    invoiceCompany: true,
                                    invoiceBarCode: true,
                                    invoiceInfo: true,
                                    invoiceCode: true,
                                    invoiceSender: true,
                                    invoiceReceiver: true,
                                    invoiceTel: true,
                                    invoiceAddr: true,
                                    pkgType: false,

                                    invoiceTable: true,
                                    invoiceTableName: true,
                                    invoiceTableQty: true,
                                    invoiceTablePrice: true,
                                    invoiceTableAmount: true,

                                    detail: true,
                                    detailCode: true,
                                    detailShelfCode: true,
                                    detailName: true,
                                    detailTitle: true,
                                    detailSpec: true,
                                    detailStoreType:false,
                                    detailPkg: true,
                                    detailDate: true,
                                    detailRemark1: true,
                                    detailRemark2: true,
                                    shopSellerMsg: true,
                                    shopName:false,
                                    ownerName:false,
                                    receiver:false,
                                    expGoodtype:false,
                                    orderPlatform:false,
                                    detailSku:false,
                                }
                            }
                        }else{
                            _this.ticketSetting = {
                                invoice: true,
                                invoiceHead: true,
                                invoiceCompany: true,
                                invoiceBarCode: true,
                                invoiceInfo: true,
                                invoiceCode: true,
                                invoiceSender: true,
                                invoiceReceiver: true,
                                invoiceTel: true,
                                invoiceAddr: true,
                                pkgType: false,

                                invoiceTable: true,
                                invoiceTableName: true,
                                invoiceTableQty: true,
                                invoiceTablePrice: true,
                                invoiceTableAmount: true,

                                detail: true,
                                detailCode: true,
                                detailShelfCode: true,
                                detailName: true,
                                detailTitle: true,
                                detailSpec: true,
                                detailStoreType:false,
                                detailPkg: true,
                                detailDate: true,
                                detailRemark1: true,
                                detailRemark2: true,
                                shopSellerMsg: true,
                                shopName:false,
                                    ownerName:false,
                                    receiver:false,
                                    expGoodtype:false,
                                    orderPlatform:false,
                                    detailSku:false,
                            }
                        }
                    }
                })
        },
        getPrintWarn(){//获取打印提醒设置
            this.$axios.get(this.$kit.api.user.pwConfig)
                .then( (result) => {
                    if(result.status){
                        if(result.data && result.data.length > 0){
                            result.data.forEach(e=>{
                                if(e.xvalue){
                                    let printSet = JSON.parse(e.xvalue);
                                    this.printWarn = printSet.printWarn?printSet.printWarn:0;
                                    this.printType = printSet.printType?printSet.printType:false;
                                }
                            })
                        }
                    }
                })
        },
        preparePrint(ids, ctrls, flag){
            var _this = this;
            this.printDtos = [];
            this.$axios.get(this.$kit.api.stock.getPrintData+"?flag="+(flag?1:0)+"&ctrls="+ctrls.join(",")+"&ids="+ids.join(",")+"&pkgCode="+this.printPkgCode+"&printSetting="+this.printWarn)
                .then((result) => {
                    if(result.status){
                        this.printDtos = result.data;
                        let is = false;
                        if(result.data.length > 0){
                            result.data.forEach(e => {
                                if(e.pageDto || e.tagDto || (e.thumbs && e.thumbs.length>0) || e.ticketDto || e.shelfCodeDto){
                                    is = true;
                                }
                            })
                        }
                        if(is){
                            _this.doPrint();
                        }else{
                            _this.$message(result.msg);
                        }
                    }else{
                        if(result.code == '-11'){
                            this.$confirm(result.msg+":"+this.msg('货物未到齐，是否继续打印？'), this.msg('提示'), {
                                confirmButtonText: this.msg('是'),
                                cancelButtonText: this.msg('否'),
                                type: 'warning'
                            }).then(() => {
                                this.preparePrint(ids, ctrls, 1)
                            }).catch((err) => {
                                this.$message(this.msg('取消打印'));
                            });
                        }else{
                            this.$message(result.msg || this.msg('get-err'));
                            this.$emit("close-dg");
                        }
                    }
                });
        },
        doPrint(){
            let _this = this;

            // _this.$refs.easyPrint.print();

            let len = this.printDtos.length || 0;

            _this.$nextTick(()=>{
                _this.$refs.easyPrint.$nextTick(() => {
                    setTimeout(()=>{
                        _this.$refs.easyPrint.print();
                    }, (2+(len>100? (len - 100)/100 : 0))*1000);
                });
            });
           
            // setTimeout(()=>{
            //     this.$refs.easyPrint.print();
            // }, 2000);
        }
    }
}
</script>

<style lang="scss" scoped>
.msg-table{margin: 15px 0px; color: #606266; font-size:14px; width:100%;}
.msg-table td {
    border-bottom: 1px solid #EBEEF5;
}
.msg-table th {
    text-align:left;
    border-bottom: 1px solid #EBEEF5;
}
.msg-table{
    border-color:EBEEF5;
}
</style>