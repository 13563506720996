<!-- 转寄找货单 -->
<template>
    <div class="row-wrap">
        <span v-if="printDtos && printDtos.length>0">
            <vue-easy-print tableShow ref="easyPrint">
                <template>
                    <print-tpl :datas="printDtos" :headName="headName" ></print-tpl>
                </template>
            </vue-easy-print>
        </span>
        <div style="text-align:center; margin-top:10px;" >
            <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                @click="doPrint()" 
            >{{msg('打印')}}</el-button>
        </div>
    </div>
</template>

<script>
import vueEasyPrint from "vue-easy-print";
import PrintTpl from "./StockPrintFindTicketTpl";
export default {
    
    name: "StockPrint",
    components:{
        PrintTpl : PrintTpl,
        vueEasyPrint: vueEasyPrint,
    },
    data(){
        return{
            printDtos:[],
            headName:'转寄找货单',
        }
    },
    mounted(){
    },
    props:{
    },
    methods:{
        init(ids){
            this.printDtos=[];
            //获取打印数据
            var _this = this;
            this.$axios.get(this.$kit.api.stock.getPrintFindData+"?ids="+ids)
                .then((result) => {
                    if(result && result.data.length > 0){
                        _this.printDtos = result.data;
                        _this.doPrint();
                    }else{
                        _this.$message(_this.msg('未找到货单数据'));
                    }
                })
        },
        doPrint(){
            // setTimeout(()=>{
            //     this.$refs.easyPrint.print();
            // }, 500);
            let _this = this;
            _this.$nextTick(()=>{
                _this.$refs.easyPrint.$nextTick(() => {
                        setTimeout(()=>{
                            _this.$refs.easyPrint.print();
                        }, 1500);
                    });
                });
            }
    }
}
</script>

<style lang="scss" scoped>

</style>