<!--打印库签-->
<template>
    <div class="xform">
        <el-row :gutter="10" type="flex" justify="space-between" align="center" >
           
            <el-col :xs="15">
                <div style="display:flex;">
                    <div style="white-space:nowrap;">{{msg('批量填充')}}： </div>
                    <el-input style="max-width:200px;" size="mini" :placeholder="msg('输入要填充的值')" v-model="iptValue" >
                    </el-input>
                    <el-button size="mini" icon="el-icon-check" @click="fillValue"></el-button>
                </div>
            </el-col>
        </el-row>
        <div style="display:flex;margin-top:20px;margin-bottom:10px;align-items:center;justify-content:center;">
            <el-button type="primary" size="mini"  @click="doPrint"><i class="el-icon-printer"></i><span>{{msg('确定打印')}}</span></el-button>
        </div>
        <div class="table-wrap">
            <vxe-table :data="datas"
                    export-config stripe
                    resizable border="full"
                    highlight-current-row highlight-hover-column highlight-current-column
                    show-overflow show-header-overflow 
                    :max-height="500" 
                    :edit-config="{trigger: 'click', mode: 'cell'}"
                    :scroll-y="{gt: 50}"
                    style="width: 100%" ref="dataTable" >
                <vxe-table-column field="code" :title="msg('订单编号')" min-width="120"></vxe-table-column>
                <vxe-table-column field="total" :title="msg('打印票数')" min-width="120"
                    :edit-render="{name: 'input', attrs: {type: 'number'}}"
                ></vxe-table-column>
            </vxe-table>
        </div>

        <div style="width:300px; text-align:center;" v-if="total>0">
            <vue-easy-print ref="easyPrint" tableShow>
                <template>
                    <div >
                        <template v-for="(d, index) in datas" >
                            <div v-for="n in (d.total*1)" :key="index+'-'+n" style="page-break-after: always; width:100%; text-align:center;">
                                <img style="height: 120px;" :src="'data:image/png;base64,'+d.barcodeAs64"/>
                            </div>
                        </template>
                    </div>
                </template>
            </vue-easy-print>
        </div>

    </div>
</template>

<script>
import vueEasyPrint from "vue-easy-print";
export default {
    name: "StockPrintTag",
    components: {
        vueEasyPrint: vueEasyPrint,
    },
    mounted(){
        this.getData();
    },
    data(){
        return {
            datas: null,
            iptValue: null,
            total: 0
        }
    },
    props:{
        ids: String
    },
    methods:{
        init(){
            this.getData();
        },
        qtyChange ({ column }) {
            this.total = 0;
            setTimeout(()=>{
                this.datas.forEach((d)=>{
                    this.total +=  d.total*1 || 0;
                });
            }, 100)
        },
        fillValue(){
            this.datas.forEach(d => {
                d.total = this.iptValue;
            })
        },
        getData(){  //初始化数据
            this.$axios.get(this.$kit.api.stock.printShopNameAsBarCode+"?ids="+this.ids)
                .then((result) => {
                    if(result.status){
                        result.data.forEach((d)=>{
                            this.total +=  d.total || 0;
                        });
                        this.datas = result.data;
                    }
                });
        },
        doPrint(){
            this.$nextTick(() => {
                setTimeout(()=>{
                    this.$refs.easyPrint.print();
                }, 200);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.next-page {
  page-break-before: always;
}

</style>