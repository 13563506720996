<template>
<div>
    <h2>{{msg('请输入袋号')}}</h2>
    <el-input type="textarea"  :rows="10" :placeholder="msg('please input codes')" 
        v-model="codes" style="margin-top:0px;">
    </el-input>

    <div style="text-align:center; margin-top:20px;">
        <el-button type="primary" size="mini" @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('导出')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</div> 
</template>

<script>
export default {
  name: 'ExpQuery',
  data(){
    return{
        codes: null,
    }
    
  }, 
  mounted: function(){
      this.init();
  },
  props:{
      tihuoPkgCodes:String
  },
  methods:{
    init(){
        this.codes = this.tihuoPkgCodes;
    },
    onSubmit(){
        if(!this.codes){
            this.$message(this.msg('请输入袋号'));
            return;            
        }

        let cs = this.codes.split(/\s+/);
        
        let css = [];
        for(let i=0; i<cs.length; i++){
            if(cs[i]) css.push(cs[i]);
        }
        
        if(css.length==0){
            this.$message(this.msg('请输入有效数据'));
            return;
        }

        this.$emit("close-dg", {codes:  css.join(",")})

        // window.location.href= this.$kit.api.export.exportTihuo+"?data="+encodeURIComponent(JSON.stringify(css.join(",")));
    },

  }
}
</script>

<style scoped lang="scss">


</style>
