<!-- 集运转运 -->
<template>
    <div class="content-panel">
        <el-form label-width="100px" :model="stock">
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('货单号')">
                        <el-input v-model.trim="stock.code" size="mini" readonly="readonly" :placeholder="msg('货单号由系统生成')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('票数')">
                        <el-input-number v-model="stock.detailQty" size="small" disabled></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('毛重(千克)')">
                        <el-input-number v-model="stock.weightGross" size="small" disabled></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('配送方式')">
                        <el-select v-model="stock.expCompany" placeholder="请选择配送方式" size="small">
                            <el-option
                                v-for="item in expCompanys"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('收件人')">
                        <el-input v-model="stock.receiver" size="mini" :placeholder="msg('请输入收件人姓名')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('电话')">
                        <el-input v-model.trim="stock.receiverTel" size="mini" :placeholder="msg('请输入收件人电话')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('门店名称')">
                        <el-input v-model="stock.receiverShopName" size="mini" :placeholder="msg('请输入门店名称')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('门/店/区号')">
                        <el-input v-model.trim="stock.receiverShopCode" size="mini" :placeholder="msg('请输入门/店/区号')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="20">
                    <el-form-item :label="msg('地址')">
                        <el-input v-model="stock.receiverAddr" size="mini" :placeholder="msg('请输入收件人地址')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('品名')">
                        <el-input v-model="stock.productName" size="mini" :placeholder="msg('请输入品名')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('货物类型')">
                        <el-radio v-model="stock.expGoodType" :label="0">普货</el-radio>
                        <el-radio v-model="stock.expGoodType" :label="1">特货</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('货单备注')">
                        <el-input v-model="stock.remark2" size="mini" :placeholder="msg('请输入货单备注')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form> 

        <div style="text-align:center; margin-top: 20px;margin-bottom: 20px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('提交')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>

    </div>
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
        stock:{
            code:null,
            detailQty:0,
            weightGross:0,
            expCompany:null,
            receiver:null,
            receiverTel:null,
            receiverAddr:null,
            receiverShopName:null,
            receiverShopCode:null,
            productName:null,
            expGoodType:0,
            storeId:0,
            owner:0,
            remark2:null,
            stockDetails:[]
        },
        ids:null,
        type:0,
        expCompanys:[],
    }
    
  }, 
  computed:{
  },
  props:{
  },
  mounted(){
  },
  methods:{
    init(ids,type){
        this.getCompanys();
        this.ids = ids.join(',');
        this.type = type;
        let qty = ids.length;
        this.$axios.get(this.$kit.api.stock.getZhuanyunData+"?ids="+ids.join(','))
            .then((result) =>{
                if(result.status){
                    let data = result.data;
                    this.stock = {
                        code:data.code,
                        detailQty:data.detailQty,
                        weightGross:(data.weightGross*0.001).toFixed(3),
                        expCompany:data.expCompany?data.expCompany:'',
                        receiver:data.receiver?data.receiver:'',
                        receiverTel:data.receiverTel?data.receiverTel:'',
                        receiverAddr:data.receiverAddr?data.receiverAddr:'',
                        receiverShopName:data.receiverShopName?data.receiverShopName:'',
                        receiverShopCode:data.receiverShopCode?data.receiverShopCode:'',
                        productName:data.productName?data.productName:'',
                        storeId:data.storeId?data.storeId:0,
                        owner:data.owner?data.owner:0,
                        expGoodType:0,
                        remark2:'',
                        stockDetails:data.stockDetails
                    }
                }
            })
    },
    getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
    onSubmit(){
        var _this = this;
        if(this.stock.weightGross){
            this.stock.weightGross = (this.stock.weightGross*1000).toFixed(0);
        }
        this.$axios.post(this.$kit.api.stock.doZhuanYun+"?ids="+this.ids+"&type="+this.type,this.stock)
            .then((result) =>{
                if(result.data){
                    _this.$message(result.msg || _this.msg('操作成功'));
                    _this.$emit('close-dg',true);
                }else{
                    this.$message(result.msg || _this.msg('操作失败'));
                }
            })
    },
  }
}
</script>

<style scoped lang="scss">

</style>
