<!-- 更新收费方式 -->
<template>
  <div>
      <el-form ref="form" label-width="100px" style="margin-top:-20px" @submit.native.prevent>
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('收费方式')">
                        <el-select v-model="tranType" size="small" placeholder="请选择">
                            <el-option
                                v-for="item in transitTypes"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="14">
                    <div style="text-align:center;margin-top: 7px;">
                        <el-button type="primary" size="mini"  @click="batchSelect"><i class="el-icon-check"></i><span>{{msg('批量更新')}}</span></el-button>
                        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('提交')}}</span></el-button>
                        <el-button type="danger"  size="mini" v-on:click="$emit('close-dg')"><i class="el-icon-close"></i><span>{{msg('取消')}}</span></el-button>
                    </div>
                </el-col>
            </el-row>
        </el-form>

        <div class="table-wrap">
            <vxe-table :data="stocks"
                    export-config stripe
                    resizable border="full"
                    highlight-current-row highlight-hover-column highlight-current-column
                    show-overflow show-header-overflow 
                    max-height="500"
                    :scroll-y="{gt: 50}"
                    style="width: 100%" ref="dataTable" >
                <vxe-table-column type="checkbox" width="80"></vxe-table-column>
                <vxe-table-column field="id" :title="msg('ID')" width="140"></vxe-table-column>
                <vxe-table-column field="code" :title="msg('订单编号')" min-width="180" :show-overflow-tooltip="true"></vxe-table-column>
                <vxe-table-column field="transitType" :title="msg('收费方式')" min-width="160" >
                    <template v-slot="{ row }">
                        <el-select v-model="row.transitType" size="mini" placeholder="请选择">
                            <el-option
                                v-for="item in transitTypes"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </template>
                </vxe-table-column>
            </vxe-table>

        </div>

    </div>  
</template>
<script>

export default {
    name: 'StockTransitDialog',
    components:{
    },
    mounted:function(){
        this.transitTypes = this.dicts.feeTypes;
    },
    data(){
        return {
            stocks:[],
            transitTypes:[],
            tranType:null,
        }
    },
    props:{
        ids: String
    },
    computed: {
    },
    methods: {
        init(){
            this.list();
        },
        list(){
            this.$axios.post(this.$kit.api.finance.transitTypeList, this.ids)
                .then((result) => {
                    if(result.status){
                        this.stocks = result.data;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        batchSelect(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            selectes.forEach(e => {
                e.transitType = this.tranType;
            })
        },
        onSubmit(){
            let ok = true;
            this.stocks.forEach(e => {
                if(!e.transitType && e.transitType != 0){
                    ok = false;
                }
            })
            if(!ok){
                this.$message(this.msg('请选择收费方式！'));
                return;
            }
            this.$axios.post(this.$kit.api.finance.updateTransitType, this.stocks)
                .then((result) => {
                    if(result.status){
                        this.$message(result.msg || this.msg('更新成功'));
                        this.$emit("close-dg");
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.view-cell{cursor:pointer; display:inline-block; margin-right:5px;}
.view-edit{cursor:pointer; display:inline-block;}
.view-delete{cursor:pointer; display:inline-block; color:red;}
</style>
