<!-- 快递货物 -->
<template>
<div>
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-input :placeholder="msg('请输入单号搜索')" 
                v-model="code" style="margin-top:0px;">
                <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
        </el-col>
    </el-row>
    
    <h3>快递单号：{{showCode}}还剩<el-input-number v-model="count" size="mini" :min="0"></el-input-number>件货物没有寄出</h3>

    <div style="text-align:center; margin-top:20px;">
        <el-button type="primary" size="mini" @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('更新数量')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</div> 
</template>

<script>
export default {
  name: 'ExpQuery',
  data(){
    return{
        code: null,
        count:null,
        stockId:null,
        detailId:null,
        showCode:null,
    }
    
  }, 
  mounted: function(){
      this.init();
  },
  props:{
  },
  methods:{
    init(){
        this.code = null;
        this.stockId = null;
        this.detailId = null;
        this.showCode = null;
        this.count = null;
    },
    search(){
        if(!this.code){
            this.$message(this.msg('请输入单号'));
            return;            
        }
        this.$axios.get(this.$kit.api.stock.searchKuaidiCode+"?code="+this.code)
            .then((result) => {
                if(result.status){
                    let data = result.data;
                    this.count = data.count;
                    this.stockId = data.stockId;
                    this.detailId = data.detailId;
                    this.showCode = this.code;
                }else{
                    this.$message(result.msg || this.msg("该单号不是快递单号"));
                }
            });
    },
    onSubmit(){
        if(!this.stockId && !this.detailId){
            this.$message(this.msg('请搜索正确单号后更新'));
            return;            
        }
        this.$axios.get(this.$kit.api.stock.updateKuaidi+"?stockId="+this.stockId+"&detailId="+this.detailId+"&count="+this.count)
            .then((result) => {
                if(result.status){
                    this.count = null;
                    this.stockId = null;
                    this.detailId = null;
                    this.showCode = null;
                    this.$message(result.msg || this.msg("更新成功"));
                    this.$emit("close-dg");
                }else{
                    this.$message(result.msg || this.msg("更新失败"));
                }
            });
    },

  }
}
</script>

<style scoped lang="scss">


</style>
