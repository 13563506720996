<!-- 货物交接 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('单号')">
                <el-input v-model="code" ref="codeRef" placeholder="请输入或扫描单号" size="small" @keyup.native="autoSelectCode">
                </el-input>
            </el-form-item>
            <el-form-item :label="msg('交接信息')">
                <el-input v-model="handover" placeholder="请输入交接信息" size="small">
                </el-input>
            </el-form-item>
        </el-col>
    </el-row>
  
    <div style="text-align:center;margin-top:10px;">
        <el-button  size="mini" type="danger" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>

    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>

</el-form> 
</template>

<script>
import NoRecord from "../../assets/media/NoRecord.mp3";//记录不存在
import ok from "../../assets/media/ok.mp3";//成功
export default {
  name: 'Handover',
  data(){
    return{
        code:null,
        handover:null,
        audioUrl:null,
    }
    
  }, 
  props: {
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
            this.code = null;
            this.$refs['codeRef'].focus();
      },
      autoSelectCode(e){
        if(e.keyCode == 13){
            this.onSubmit();
        }
    },
    onSubmit(){
        if(!this.code){
            this.$message(this.msg('请录入单号'));
            return;
        }

        this.$axios.post(this.$kit.api.stock.stockHandover+'?code='+this.code+'&handover='+this.handover)
            .then((result) =>{
                this.$message(result.msg);
                if(result.status){
                    this.audioUrl = ok;
                    this.playAudio();
                }else{
                    this.audioUrl = NoRecord;
                    this.playAudio();
                }
                this.code = null;
                this.$refs['codeRef'].focus();
            });
        
    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = this.audioUrl;
        audio.play();
    },
  }
}
</script>

<style class="scss" scoped>


</style>
