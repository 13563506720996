<!--编辑库存-->
<template>
<el-form ref="form" v-if="stock" :model="stock" label-width="80px" size="mini">
    <div style="text-align:center; margin-top: 15px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
    <h3>{{msg('库存信息')}} <i style="cursor:pointer;" @click="stockVisible = !stockVisible"
        :class="[stockVisible?'el-icon-arrow-down':'el-icon-arrow-up']"></i></h3>
    <transition enter-active-class="animate__animated animate__fadeInRight animate__faster" 
            leave-active-class="animate__animated animate__fadeOutRight animate__faster">
        <el-row v-show="stockVisible">
            <el-col :xs="24">
                <el-form-item label="ID">
                    <span>{{stock.id}}</span>
                </el-form-item>
            </el-col>
            <el-col :xs="24"  v-for="(detail, idx) in viewSetting.details" :key="idx">
                <el-form-item v-if="hasSelected(detail)" 
                        :label="detail.nlabel || detail.label" :label-width="getLabelWidth(detail.nlabel || detail.label)">
                    <el-select v-model="stock[detail.fieldName]" size="mini" clearable :placeholder="msg('请选择')" :disabled="isDisable(detail,detailEditable,expCodeEdit,antOrderEdit)">
                        <el-option v-for="d in getSelectes(detail)" 
                            :key="d.value" :label="d.label" :value="d.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-else :label="detail.nlabel || detail.label" :label-width="getLabelWidth(detail.nlabel || detail.label)">
                    <el-input v-model="stock[detail.fieldName]" :disabled="isDisable(detail,detailEditable,expCodeEdit,antOrderEdit)"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </transition>
    <h3>{{msg('库存详情')}} <i style="cursor:pointer;" @click="detailVisible = !detailVisible"
        :class="[detailVisible?'el-icon-arrow-down':'el-icon-arrow-up']"></i></h3>
    <transition enter-active-class="animate__animated animate__fadeInRight animate__faster" 
            leave-active-class="animate__animated animate__fadeOutRight animate__faster">
        <span v-show="detailVisible">
            <div v-for="(detail, idx) in stock.details" :key="idx">
                <el-row >
                    <el-col :xs="24" >
                        <el-form-item label="ID">
                            <span>{{detail.id}} </span>
                            <el-link :underline="false" 
                                style="font-size:16px;"
                                :icon="detail.visible?'el-icon-arrow-down':'el-icon-arrow-up'"
                                @click="detail.visible = !detail.visible"> 
                            </el-link>
                            <el-link :underline="false" icon="el-icon-delete" type="danger"
                                    style="padding: 0px 15px; font-size:16px;"
                                    @click="removeDetail(detail, idx)"> 
                                {{msg('删除')}}
                            </el-link>
                            <el-link :underline="false" icon="el-icon-circle-check"
                                    style="font-size:16px;"
                                    @click="setMain(detail)"> 
                                {{msg('设置为主要')}}
                            </el-link>
                        </el-form-item>
                    </el-col>
                </el-row>
                <transition enter-active-class="animate__animated animate__fadeInRight animate__faster" 
                        leave-active-class="animate__animated animate__fadeOutRight animate__faster">
                    <el-row v-show="detail.visible">
                        <el-col :xs="24" >
                            <el-form-item :label="msg('品名')">
                                <el-input v-model="detail.productName" :disabled="isDisable1(detailEditable)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" >
                            <el-form-item :label="msg('标题')">
                                <el-input v-model="detail.productTitle" :disabled="isDisable1(detailEditable)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" >
                            <el-form-item :label="msg('规格')">
                                <el-input v-model="detail.productSpec" :disabled="isDisable1(detailEditable)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" >
                            <el-form-item :label="msg('价格')">
                                <el-input v-model="detail.productPrice"
                                    @keyup.native="setOrderPrice" :disabled="isDisable1(detailEditable)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" >
                            <el-form-item :label="msg('数量')">
                                <el-input v-model="detail.productQty" type="number" 
                                    @keyup.native="setOrderQty" :disabled="isDisable1(detailEditable)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" >
                            <el-form-item :label="msg('转寄单号')" label-width="120px">
                                <el-input v-model="detail.innerExpCode" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" >
                            <el-form-item :label="msg('货架号')">
                                <el-input v-model="detail.shelfCode" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" >
                            <el-form-item v-if="detail['upperDate']" :label="msg('上架时间')">
                                <span>{{detail.upperDate}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" >
                            <el-form-item :label="msg('图片')">
                                <el-upload class="xm-upload"
                                    :action="upload"
                                    :file-list="detail.thumbs"
                                    :with-credentials="true"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview"
                                    :on-success="(res, file, fileList) => handUploadSuccess(res, file, fileList, detail)"
                                    :limit="1"
                                    :on-remove="handleRemove">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </transition>
            </div>
            <div class="cell-tool" style="text-align:center;">
                <el-button type="primary" @click="addDetail()" plain size="mini" >添加更多</el-button>
            </div>
        </span>
    </transition>

    <div style="text-align:center; margin-top: 15px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
    <!--查看好友头像-->
    <el-image-viewer v-if="showViewer" :on-close="()=>{this.showViewer = false}" :url-list="[thumbUrl]" />
    
</el-form> 
</template>

<script>
import 'animate.css';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
    name: 'stockEdit',
    components: { 
        ElImageViewer: ElImageViewer,
    },
    data(){
        return{
            stores:[],
            stockVisible: true,
            detailVisible: true,
            upload: this.$kit.api.upload+"?type=stock/thumb&auth=1",
            thumbUrl: null,
            imageView: null,
            showViewer: false,
            removedImgIds: [],
            expCompanys:[],
            preReceiver:null,
            preReceiverTel:null,
            preReceiverAddr:null,
        }
    },
    mounted: function(){
        this.initForm();
        this.getCompanys();
    },
    computed: {
    },
    props:{
      viewSetting: Object,
      stock: Object,
      detailEditable: Boolean,
      expCodeEdit:Boolean,
      antOrderEdit:Boolean
    },
    methods:{
        initForm(){ //初始化数据
            this.is = false;
            this.preReceiver = this.stock.receiver,
            this.preReceiverTel = this.stock.receiverTel,
            this.preReceiverAddr = this.stock.receiverAddr,
            //这里单独获取收件人、收件人电话、收件人地址
            this.getReceives();
        },
        getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
        getReceives(){
            //判断是否有收件人这三个字段，如果有就去查询一次
            if(this.stock.receiver){
                this.$axios.get(this.$kit.api.export.getStockReceives+"?rs="+this.stock.id)
                    .then((result)=> {
                        if(result.status){
                            let date = result.data;
                            this.stock.receiver = date.receiver;
                            this.stock.receiverTel = date.tel;
                            this.stock.receiverAddr = date.addr;
                            this.stock.receiverShopCode = date.shopCode;
                            this.stock.receiverShopName = date.shopName;
                        }
                    });
            }
        },
        getLabelWidth(label){  //计算label的宽度

            let nlabel = label.replace(/[^\u0000-\u00ff]/g,"aa")
            // console.log(label, nlabel.length);
            let width = 80;
            let length = nlabel.length - 8;
            if(length>0){
                if(length>=14){
                    width = 180;
                }else{
                    width += length * 10;
                }
            }
            return width+"px";
        },
        isDisable(detail,allowEdit,expCodeEdit,antOrderEdit){  //判断是不是需要不可编辑
            let dis = ['exp_cod','order_origin'];
            if(dis.indexOf(detail.colName)>-1){
                    return true;
                }
            if(allowEdit){
                let arr = ['receiver_shop_name', 'receiver_shop_code','product_volume_str','exp_company','receiver','receiver_tel','receiver_addr',
                        'product_name','product_title','product_spec','shop_name'];
                if(arr.indexOf(detail.colName)>-1){// == 'status' || detail.colName == 'status' || detail.colName == 'status'){
                    return false;
                }else{
                    if(detail.colName == "code"){
                        return !this.hasPermission("stock:updateCode");
                    }else if(detail.colName == 'exp_code' || detail.colName == 'exp_page_code'){
                        if(this.hasPermission("stock:updateExpCode")){
                            return false;
                        }else{
                            if(expCodeEdit && this.hasPermission("stock:updateExpCodeBefore")){
                                return false;
                            }else{
                                return true;
                            }
                        }
                    }
                    else if(detail.colName == 'amt_order'){
                        if(antOrderEdit){
                            return false;
                        }else{
                            if(this.hasPermission("stock:updateReceiver")){
                                return false;
                            }else{
                                return true;
                            }
                        }
                    }
                    else if(detail.colName == 'exp_company'){
                        return !this.hasPermission("stock:updateExpCompany");
                    }else if(detail.colName == 'track_status'){
                        return !this.hasPermission("stock:trackStatus");
                    }else if(detail.colName.indexOf("receiver")>-1){
                        return !this.hasPermission("stock:updateReceiver");
                    }else if(detail.colName == 'status'){
                        return true;
                    }else if(detail.colName == 'exp_batch' || detail.colName == 'exp_pkg_code' || detail.colName == 'product_name'){
                        return !this.hasPermission("stock:updateExpBatch");
                    }else if(detail.colName == 'weight_consign'){
                        return !this.hasPermission("stock:updateWeightConsign");
                    }else if(detail.colName == 'remark3' || detail.colName == 'remark4' || detail.colName == 'remark5' || 
                            detail.colName == 'remark6' || detail.colName == 'remark7' || detail.colName == 'remark8' || detail.colName == 'err_msg'){
                        return !this.hasPermission("stock:updateRemark5");
                    }else{
                        return true;
                    }
                }
                
            }else{
                if(detail.colName == "code"){
                   return !this.hasPermission("stock:updateCode");
               }else if(detail.colName == 'exp_code' || detail.colName == 'exp_page_code'){
                    if(this.hasPermission("stock:updateExpCode")){
                        return false;
                    }else{
                        if(expCodeEdit && this.hasPermission("stock:updateExpCodeBefore")){
                            return false;
                        }else{
                            return true;
                        }
                    }
                }else if(detail.colName == 'exp_company'){
                   return !this.hasPermission("stock:updateExpCompany");
               }else if(detail.colName == 'track_status'){
                   return !this.hasPermission("stock:trackStatus");
               }
               else if(detail.colName == 'amt_order'){
                        if(antOrderEdit){
                            return false;
                        }else{
                            if(this.hasPermission("stock:updateReceiver")){
                                return false;
                            }else{
                                return true;
                            }
                        }
                    }
               else if(detail.colName.indexOf("receiver")==0){
                   return !this.hasPermission("stock:updateReceiver");
               }else if(detail.colName == 'status'){
                   return true;
               }else if(detail.colName == 'exp_batch' || detail.colName == 'exp_pkg_code' || detail.colName == 'product_name'){
                   return !this.hasPermission("stock:updateExpBatch");
               }else if(detail.colName == 'weight_consign'){
                   return !this.hasPermission("stock:updateWeightConsign");
               }else if(detail.colName == 'remark3' || detail.colName == 'remark4' || detail.colName == 'remark5' || 
                    detail.colName == 'remark6' || detail.colName == 'remark7' || detail.colName == 'remark8' || detail.colName == 'err_msg'){
                   return !this.hasPermission("stock:updateRemark5");
               }else{
                   return !allowEdit;
               }
            }

        },
        isDisable1(allowEdit){
            if(!allowEdit){
                return true;
            }else{
                return false;
            }
        },
        //判断是不是下拉框
        hasSelected(detail){ //获取需要下拉框的
            if(detail.tableName == 't_stock'){
                if(detail.colName == 'status' 
                    || detail.colName == 'exp_page_status'
                    || detail.colName == 'exp_good_type'
                    || detail.colName == 'exp_pkg_type'
                    || detail.colName == 'store_type'
                    || detail.colName == 'in_store_status'
                    || detail.colName == 'exp_allow_print'
                    || detail.colName == 'has_tax'
                    || detail.colName == 'is_cancel'
                    || detail.colName == 'order_origin'
                    || detail.colName == 'order_platform'
                    || detail.colName == 'exp_cod'
                    || detail.colName == 'err_status'
                    || detail.colName == 'exp_out_type'
                    || detail.colName == 'exp_company'){
                        return true;
                }

            }
            return false;
        },
        getSelectes(detail){ //获取需要下拉框的，下拉框带待选值
            if(detail.tableName == 't_stock'){
                if(detail.colName == 'status'){
                    return this.dicts.stockStatus;
                }else if(detail.colName == 'exp_page_status'){
                    return this.dicts.stockExpPageStatus;
                }else if(detail.colName == 'exp_cod'){
                    return this.dicts.stockExpType;
                }else if(detail.colName == 'exp_good_type'){
                    return this.dicts.stockExpGoodType;
                }else if(detail.colName == 'exp_pkg_type'){
                    return this.dicts.stockExpPkgType;
                }else if(detail.colName == 'store_type'){
                    return this.dicts.stockStoreType;
                }else if(detail.colName == 'in_store_status'){
                    return this.dicts.stockInStoreStatus;
                }else if(detail.colName == 'exp_allow_print'){
                    return this.dicts.stockExpAllowPrint;
                }else if(detail.colName == 'order_origin'){
                    return this.dicts.orderOrigin;
                }else if(detail.colName == 'is_cancel'){
                    return this.dicts.cances;
                }else if(detail.colName == 'order_platform'){
                    return this.dicts.orderPlatforms;
                }else if(detail.colName == 'has_tax'){
                    return this.dicts.stockHasTax;
                }else if(detail.colName == 'exp_company'){
                    return this.expCompanys;
                }

            }
            return [];
        },
        addDetail(){  //添加一条详情
            this.$axios.post(this.$kit.api.stock.selectStatusById+"?stockId="+this.stock.id).
                then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data){
                            if(data.status >= 13 && data.status <= 23 && data.status != 20){
                                this.stock.details.push({
                                    id: null,
                                    productName: null,
                                    productTitle: null,
                                    productSpec: null,
                                    productQty: 1,
                                    productThumb: 0,
                                    productPrice: 0,
                                    stockId: this.stock.id,
                                    innerExpCode: null,
                                    shelfCode: null,
                                    thumbs:[],
                                    visible: true,
                                });
                            }else{
                                if(this.hasPermission("stock:detailAdd")){
                                    this.stock.details.push({
                                        id: null,
                                        productName: null,
                                        productTitle: null,
                                        productSpec: null,
                                        productQty: 1,
                                        productThumb: 0,
                                        productPrice: 0,
                                        stockId: this.stock.id,
                                        innerExpCode: null,
                                        shelfCode: null,
                                        thumbs:[],
                                        visible: true,
                                    });
                                }else{
                                    this.$message(this.msg('该订单不能新增'));
                                }
                            }
                        }else{
                            this.$message(this.msg('无效数据'));
                        }
                    }
                });
        },
        removeDetail(detail, idx){ //删除一条详情
            this.$axios.post(this.$kit.api.stock.selectStatusById+"?stockId="+this.stock.id).
                then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data){
                            if(data.status >= 13 && data.status <= 23){
                                this.stock.details.splice(idx,1);
                                if(detail.productThumb){
                                    this.removedImgIds.push(detail.productThumb);
                                }
                            }else{
                                if(this.hasPermission("stock:detailAddDelete")){
                                    this.stock.details.splice(idx,1);
                                    if(detail.productThumb){
                                        this.removedImgIds.push(detail.productThumb);
                                    }
                                }else{
                                    this.$message(this.msg('该订单不能删除'));
                                }
                            }
                        }else{
                            this.$message(this.msg('无效数据'));
                        }
                    }
            });
        },
        setMain(detail){  //设置为主要显示的详情
            this.stock['productName'] = detail.productName;
            this.stock['productTitle'] = detail.productTitle;
            this.stock['productSpec'] = detail.productSpec;
            this.stock['productThumb'] = detail.productThumb;
            this.stock['productQty'] = detail.productQty;
            this.stock['productPrice'] = detail.productPrice;

            this.$message(this.msg("设置成功"));
        },
        setOrderQty(){
            let qty = 0;
            this.stock.details.forEach(d => {
                if(d.productQty){
                    qty += d.productQty*1;
                }
            });
            this.stock['orderQty'] = qty;
        },
        setOrderPrice(){
            let price = 0;
            this.stock.details.forEach(d => {
                if(d.productPrice){
                    price += d.productPrice*1
                }
            });
            this.stock['amtOrder'] = price;
        },
        setProductThumb(){
            if(!this.stock['productThumb']){
                let thumb = 0;
                for(let i=0; i<this.stock.details.length; i++){
                    let d = this.stock.details[i];
                    if(d.productThumb){
                        thumb = d.productThumb;
                        break;
                    }
                }
                if(thumb){
                    this.stock['productThumb'] = thumb;
                }
            }
         
        },
        handlePictureCardPreview(file){
            this.thumbUrl = file.url;
            this.showViewer = true;
        },
        handleRemove(file, fileList){
            this.removedImgIds.push(file.name);
        },
        handUploadSuccess(res, file, fileList, detail){
            if(res.status){
                detail.productThumb = res.data;
                file.name = res.data;
                this.$message("上传成功");
            }else{
                this.$message("上传失败");
            }
        },
        onSubmit(){
          
            var _this = this;

            if(this.stock.details.length == 0 ){
                this.$message(this.msg("至少需要一条详情"));
                return;
            }
            this.setProductThumb();
            // this.setOrderQty();
            // this.setOrderPrice();

            //计算总数量
            // let qty = 0;
            // this.stock.details.forEach(d => {
            //     if(d.productQty){
            //         qty += d.productQty;
            //     }
            // });
            // this.stock['orderQty'] = qty;

            let s = {};
            for(let key in this.stock){
                let val = this.stock[key];
                if(key == 'details'){
                    let ds = this.stock['details'];

                    s['stockDetails'] = [];
                    ds.forEach(d => {

                        let dd = {};

                        for(let k1 in d){
                            if(k1 != 'thumbs'){
                                let dv = d[k1];
                                if(dv && k1 == 'productPrice'){
                                    dd[k1] = dv*100;
                                }else{
                                    dd[k1] = dv;
                                }
                            }
                        }
                        s['stockDetails'].push(dd);
                    })

                }else{
                    if(val){
                        if((key.indexOf("amt")==0 && key!="amtRate" && key!="amtCurrency" )
                                || key=="productPrice"){
                            val = val*100;
                        }else if(key.indexOf("weight")==0 && key!="weightInputer"){
                            val = val * 1000;
                        }else if(key == 'productVolume'){
                            val = val * 1000;
                        }
                    }
                    s[key] = val;
                }
            }
            if(this.removedImgIds.length>0){
                s['deleteAttachmentIds'] = this.removedImgIds;
            }
            delete s['created'];
            delete s['expExpireDate'];
            delete s['trackDate'];
            delete s['trackLastDate'];
            delete s['shopPayTime'];
            delete s['shopDayToShip'];
            delete s['uploadTime'];
            delete s['printTime'];
            this.$axios.post(this.$kit.api.stock.update, s)
                .then(function(result){
                    if(result.status){
                        _this.stock.details = result.data;
                        _this.stock.receiver = _this.preReceiver;
                        _this.stock.receiverTel = _this.preReceiverTel
                        _this.stock.receiverAddr = _this.preReceiverAddr
                        _this.$message(result.msg || _this.msg('更新成功'));
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                    _this.$emit("close-dg", true);
                });
        },
    }
}
</script>

<style scoped lang="scss">


.xm-upload >>> .el-upload-list--picture-card .el-upload-list__item,
.xm-upload >>> .el-upload--picture-card
{
    width:100px; height:100px;
}

.xm-upload >>> .el-upload--picture-card
{
    line-height: 105px;
}

</style>
